export const VALIDGUESSES = [
  "aaron",
  "abbém",
  "abbéů",
  "abbéy",
  "abeba",
  "abuja",
  "abych",
  "accra",
  "adamů",
  "adana",
  "addis",
  "adéla",
  "adept",
  "adice",
  "adige",
  "adiže",
  "adobe",
  "adrie",
  "afekt",
  "aféra",
  "agama",
  "agapé",
  "agáta",
  "agáve",
  "agens",
  "agent",
  "agios",
  "agnes",
  "agora",
  "ahold",
  "achát",
  "aires",
  "akant",
  "akcie",
  "akční",
  "akkra",
  "akord",
  "akryl",
  "aktér",
  "aktiv",
  "aktor",
  "akuma",
  "alarm",
  "albeř",
  "albín",
  "albit",
  "album",
  "alcor",
  "alela",
  "alena",
  "alfie",
  "algol",
  "alias",
  "alibi",
  "alice",
  "alkan",
  "alken",
  "alkin",
  "alkyl",
  "alšem",
  "alšům",
  "altaj",
  "altán",
  "alter",
  "altun",
  "aluta",
  "aluze",
  "alžír",
  "amant",
  "amara",
  "ambit",
  "ambra",
  "améba",
  "amiga",
  "ammán",
  "ampér",
  "anais",
  "ančím",
  "anebo",
  "aneta",
  "anexe",
  "angie",
  "anime",
  "anion",
  "anita",
  "anjou",
  "annie",
  "anoda",
  "anžto",
  "aorta",
  "aplit",
  "apnoe",
  "aport",
  "aranž",
  "arbat",
  "areál",
  "aréna",
  "arest",
  "areté",
  "argon",
  "argot",
  "argus",
  "arhus",
  "archa",
  "arché",
  "arita",
  "arkýř",
  "arles",
  "armén",
  "armin",
  "aroma",
  "arový",
  "arras",
  "arsan",
  "arsen",
  "arsin",
  "aršík",
  "aruba",
  "arzen",
  "arzén",
  "ascii",
  "askja",
  "aspik",
  "astat",
  "astma",
  "astra",
  "asuán",
  "ášram",
  "ašsko",
  "ašský",
  "ataka",
  "atapi",
  "atašé",
  "atény",
  "atest",
  "atika",
  "atlas",
  "atlet",
  "audit",
  "augit",
  "augur",
  "aukce",
  "aušus",
  "autor",
  "avast",
  "avila",
  "avion",
  "aviso",
  "avízo",
  "avšak",
  "axiom",
  "axios",
  "azory",
  "azték",
  "ažura",
  "babec",
  "babka",
  "bacat",
  "bacau",
  "bacil",
  "bacit",
  "báčka",
  "bádat",
  "baden",
  "bafat",
  "bagáž",
  "bagel",
  "bahno",
  "bacha",
  "bájit",
  "bajka",
  "bájný",
  "bakar",
  "balet",
  "balit",
  "balon",
  "balsa",
  "balza",
  "banán",
  "banda",
  "banín",
  "banjo",
  "banka",
  "banks",
  "barák",
  "baráž",
  "barel",
  "baret",
  "barev",
  "barka",
  "bárka",
  "barma",
  "barok",
  "baron",
  "barré",
  "bartů",
  "bártů",
  "barva",
  "baryt",
  "basák",
  "basic",
  "basra",
  "basta",
  "baška",
  "bašta",
  "batát",
  "batoh",
  "bavič",
  "bavit",
  "bazar",
  "bazén",
  "bažit",
  "bdělý",
  "bdění",
  "bdící",
  "beach",
  "beata",
  "beáta",
  "bečet",
  "bečka",
  "béčko",
  "bečva",
  "beder",
  "bedla",
  "bedna",
  "bědný",
  "bedra",
  "bedry",
  "běhák",
  "běhat",
  "během",
  "běhna",
  "bekem",
  "bekot",
  "bekům",
  "bělák",
  "bělat",
  "belcy",
  "belec",
  "bělec",
  "běleč",
  "belém",
  "bělet",
  "bělit",
  "bělka",
  "bělmo",
  "benát",
  "benin",
  "benue",
  "beran",
  "bérec",
  "berec",
  "berla",
  "berle",
  "berní",
  "berný",
  "berta",
  "beryl",
  "běsno",
  "běsný",
  "betel",
  "bětka",
  "beton",
  "bezem",
  "bezům",
  "bezva",
  "běžec",
  "běžet",
  "běžka",
  "běžky",
  "běžný",
  "biala",
  "bible",
  "bičík",
  "bídák",
  "bidet",
  "bidlo",
  "bídný",
  "bihač",
  "bihár",
  "biják",
  "bijec",
  "biker",
  "bilec",
  "bílek",
  "bílit",
  "binec",
  "bingo",
  "binom",
  "bioko",
  "biosu",
  "biota",
  "biret",
  "bíteš",
  "bitka",
  "bitva",
  "bivak",
  "bixin",
  "bizam",
  "bizon",
  "blaho",
  "blahý",
  "blána",
  "blato",
  "bláto",
  "bláža",
  "blbec",
  "bledý",
  "blesk",
  "blití",
  "blitý",
  "blois",
  "blond",
  "bloud",
  "blues",
  "bluma",
  "blůza",
  "bobek",
  "bobše",
  "bobši",
  "bobšů",
  "boční",
  "bočný",
  "bodák",
  "bodam",
  "bodat",
  "bodec",
  "bodík",
  "bodlo",
  "bodný",
  "bodrý",
  "boháč",
  "bohat",
  "bohdá",
  "bohem",
  "bohům",
  "bojar",
  "bojga",
  "bokem",
  "bolák",
  "bolec",
  "bolen",
  "bolet",
  "bolid",
  "bomba",
  "bonus",
  "boole",
  "bootp",
  "borač",
  "boran",
  "borax",
  "borec",
  "boreč",
  "borid",
  "borka",
  "boršč",
  "bořit",
  "bosák",
  "bosáž",
  "bosky",
  "bosna",
  "bosně",
  "bosni",
  "bosní",
  "boson",
  "bošín",
  "botan",
  "botas",
  "botel",
  "botič",
  "botka",
  "botox",
  "bouda",
  "boule",
  "bouře",
  "boxer",
  "božec",
  "brada",
  "braga",
  "brach",
  "brána",
  "braní",
  "braný",
  "bratr",
  "braun",
  "brava",
  "brave",
  "bravo",
  "bravu",
  "brčál",
  "brčko",
  "brejk",
  "brémy",
  "bridž",
  "briga",
  "bríza",
  "brkat",
  "brloh",
  "brnět",
  "brník",
  "brnky",
  "bronz",
  "brouk",
  "brtec",
  "bruce",
  "bryce",
  "brýle",
  "brzda",
  "břehy",
  "břest",
  "březí",
  "břink",
  "břiše",
  "bříza",
  "bubák",
  "buben",
  "bůček",
  "bučet",
  "budeč",
  "budič",
  "budit",
  "budka",
  "bufet",
  "bůhví",
  "bujet",
  "bujný",
  "bujon",
  "bukač",
  "buket",
  "buklé",
  "bulík",
  "bulit",
  "bulka",
  "bully",
  "bulva",
  "bunda",
  "bunkr",
  "burák",
  "buran",
  "burka",
  "burza",
  "buřič",
  "busta",
  "bušín",
  "bušit",
  "butan",
  "buten",
  "butik",
  "buton",
  "butyl",
  "buvol",
  "buxus",
  "buzau",
  "bůžek",
  "býček",
  "bydlo",
  "bycha",
  "býkev",
  "bykoš",
  "bylas",
  "bylos",
  "bysta",
  "byste",
  "bytař",
  "bytča",
  "bytex",
  "bytný",
  "bytom",
  "bývat",
  "byvší",
  "cádiz",
  "cáchy",
  "cákat",
  "canon",
  "capat",
  "capri",
  "capua",
  "causa",
  "cdrom",
  "cebit",
  "cebiv",
  "cecek",
  "cecík",
  "céčko",
  "cedit",
  "cefta",
  "cejch",
  "cejle",
  "celek",
  "celer",
  "celje",
  "cello",
  "celní",
  "celta",
  "ceník",
  "cenit",
  "cenný",
  "centr",
  "cepín",
  "cetie",
  "cetka",
  "ceuta",
  "cévka",
  "cévní",
  "cídič",
  "cídit",
  "cifra",
  "cihla",
  "cícha",
  "cílit",
  "cilka",
  "cimbr",
  "cimra",
  "cínař",
  "cindy",
  "cínie",
  "cinto",
  "cípek",
  "cirát",
  "circa",
  "cirka",
  "citát",
  "cítěn",
  "cítit",
  "civět",
  "cívka",
  "cizák",
  "clara",
  "clare",
  "clení",
  "clený",
  "clive",
  "clona",
  "cloud",
  "clyde",
  "colin",
  "copak",
  "corel",
  "cosec",
  "costa",
  "coudé",
  "coura",
  "covid",
  "cpaní",
  "cpání",
  "cpaný",
  "crack",
  "crčet",
  "cresc",
  "crkot",
  "ctech",
  "ctění",
  "ctěný",
  "ctící",
  "cucat",
  "cucek",
  "cudný",
  "cukat",
  "culík",
  "culit",
  "cumel",
  "cupat",
  "cupky",
  "cupot",
  "curie",
  "cuzco",
  "cvaky",
  "cvikr",
  "cvrnk",
  "cykas",
  "cynik",
  "cysta",
  "čacký",
  "čadca",
  "čadit",
  "čádor",
  "čachr",
  "čajík",
  "čajka",
  "čakan",
  "čakma",
  "čakra",
  "čalma",
  "čapka",
  "čarám",
  "čárat",
  "čárka",
  "čarou",
  "čásek",
  "časem",
  "časný",
  "často",
  "čatní",
  "čebín",
  "čečna",
  "čedar",
  "čedič",
  "čedok",
  "čehos",
  "čehož",
  "čechy",
  "čejka",
  "čekat",
  "čelba",
  "čelit",
  "čeliv",
  "čelní",
  "čelný",
  "čemsi",
  "čemus",
  "čemuž",
  "čenec",
  "čepec",
  "čepec",
  "čepel",
  "čepka",
  "černo",
  "čeřen",
  "čeřit",
  "česač",
  "česáč",
  "česko",
  "česku",
  "český",
  "česle",
  "česno",
  "češka",
  "čéška",
  "četař",
  "četba",
  "četka",
  "četný",
  "čibuk",
  "čička",
  "čidlo",
  "číhat",
  "číhos",
  "číchs",
  "čilec",
  "čílit",
  "číman",
  "čímas",
  "číměř",
  "čímis",
  "čímsi",
  "čímus",
  "činel",
  "činit",
  "činka",
  "činný",
  "činže",
  "čípec",
  "čípek",
  "čírka",
  "čirok",
  "čiřit",
  "číslo",
  "čišet",
  "číška",
  "čítač",
  "čítat",
  "čížek",
  "čižma",
  "čkyně",
  "čmkos",
  "čmoud",
  "čnění",
  "čnící",
  "čočka",
  "čolek",
  "čpavý",
  "čpění",
  "čpící",
  "črtat",
  "čskos",
  "čsvts",
  "čtecí",
  "čtení",
  "čtený",
  "čtivo",
  "čtivý",
  "čtvrt",
  "čtyři",
  "čubka",
  "čučet",
  "čumák",
  "čumět",
  "čundr",
  "čuník",
  "čurat",
  "čůrat",
  "čůrek",
  "čutat",
  "dabér",
  "dácie",
  "dadat",
  "dahná",
  "daisy",
  "dakar",
  "dalek",
  "dálka",
  "dálný",
  "daman",
  "damec",
  "dampr",
  "daněk",
  "daník",
  "danit",
  "danka",
  "dánka",
  "darda",
  "dárek",
  "darja",
  "darmo",
  "dařit",
  "dářko",
  "dašan",
  "datel",
  "dativ",
  "datka",
  "datla",
  "datle",
  "datli",
  "datlu",
  "datlů",
  "datly",
  "datum",
  "dauhá",
  "dávat",
  "dávit",
  "dávka",
  "davle",
  "davos",
  "dbalý",
  "dbaní",
  "dbání",
  "dcera",
  "dcero",
  "dceru",
  "dcery",
  "dceři",
  "debut",
  "děcák",
  "decil",
  "děcko",
  "děčín",
  "dečka",
  "déčko",
  "dědek",
  "dědit",
  "dehet",
  "děják",
  "děkan",
  "dekor",
  "dělat",
  "dělba",
  "delfy",
  "dělič",
  "dělit",
  "délka",
  "dělný",
  "délos",
  "delta",
  "demel",
  "démon",
  "démos",
  "denár",
  "deník",
  "denní",
  "denta",
  "depka",
  "děrám",
  "derby",
  "děrný",
  "desén",
  "děsit",
  "deska",
  "desna",
  "děsný",
  "deště",
  "dešti",
  "dětem",
  "dětmi",
  "detox",
  "děvče",
  "devět",
  "děvka",
  "devon",
  "dezén",
  "dhabi",
  "dhabí",
  "dháka",
  "diana",
  "diaré",
  "dieta",
  "dijon",
  "dikce",
  "díkem",
  "díkům",
  "dildo",
  "dílec",
  "dílek",
  "dílem",
  "dílko",
  "dillí",
  "dílna",
  "dimer",
  "dinár",
  "dioda",
  "diovi",
  "dírka",
  "disko",
  "dítko",
  "divák",
  "divan",
  "dívat",
  "dívčí",
  "divec",
  "divit",
  "dívka",
  "divný",
  "divže",
  "dížka",
  "dlaha",
  "dlask",
  "dláto",
  "dlení",
  "dlící",
  "dmout",
  "dmutí",
  "dmutý",
  "dnech",
  "dnepr",
  "dněpr",
  "doběh",
  "dobev",
  "dobít",
  "dobit",
  "dobro",
  "dobrý",
  "dobyl",
  "dobýt",
  "dobyt",
  "dodat",
  "dogma",
  "dohad",
  "dohra",
  "doják",
  "dojal",
  "dojat",
  "dojdu",
  "dojel",
  "dojem",
  "dojit",
  "dojít",
  "dojmu",
  "dojný",
  "dokap",
  "dokař",
  "dokdy",
  "dokud",
  "dolák",
  "dolar",
  "dolce",
  "dolem",
  "dolet",
  "dolík",
  "dolít",
  "dolit",
  "dolní",
  "dolům",
  "dolžu",
  "domek",
  "domem",
  "domům",
  "domýt",
  "donec",
  "donín",
  "donor",
  "donos",
  "donýt",
  "dopad",
  "dopal",
  "dopěl",
  "dopět",
  "dopis",
  "dopít",
  "dopit",
  "doraz",
  "dorýt",
  "dořez",
  "dosah",
  "dosel",
  "doset",
  "dosil",
  "dosít",
  "dosti",
  "dosud",
  "došek",
  "došel",
  "došít",
  "dotah",
  "dotát",
  "dotaz",
  "dotek",
  "dotít",
  "dotnu",
  "dotud",
  "dotyk",
  "dotýt",
  "doubí",
  "doupě",
  "douro",
  "dover",
  "dovít",
  "dovit",
  "dovoz",
  "dovýt",
  "dozer",
  "dozor",
  "dožal",
  "dožat",
  "dožnu",
  "dpmhk",
  "draft",
  "draga",
  "dráha",
  "drama",
  "draní",
  "draný",
  "drapé",
  "dráva",
  "dravý",
  "dražé",
  "drbna",
  "drcat",
  "drdol",
  "dredy",
  "drhat",
  "drift",
  "drina",
  "drink",
  "drkat",
  "drnec",
  "droba",
  "droga",
  "droid",
  "drozd",
  "drsek",
  "drsný",
  "drška",
  "drtit",
  "druhé",
  "druid",
  "druzí",
  "dryák",
  "držák",
  "držba",
  "držet",
  "držka",
  "dření",
  "dřený",
  "dřevo",
  "dřina",
  "dříví",
  "dštít",
  "dubák",
  "dubče",
  "dubči",
  "dubčí",
  "dubec",
  "dubeč",
  "dublé",
  "ducat",
  "dudák",
  "dudat",
  "dudek",
  "dudín",
  "duero",
  "dueto",
  "duchu",
  "dukát",
  "důkaz",
  "dukla",
  "důlek",
  "důlní",
  "dumat",
  "dumka",
  "dunět",
  "dupat",
  "dupky",
  "dupot",
  "dural",
  "důraz",
  "durec",
  "durch",
  "durit",
  "dusat",
  "dusík",
  "dusit",
  "dusno",
  "dusný",
  "dusot",
  "dušný",
  "dutat",
  "důtka",
  "důtky",
  "důvod",
  "dvěma",
  "dveře",
  "dvina",
  "dvoje",
  "dvora",
  "dvore",
  "dvoru",
  "dvorů",
  "dvory",
  "dvory",
  "dvoře",
  "dýčka",
  "dýmat",
  "dýměj",
  "dýmka",
  "dynín",
  "dýnko",
  "dýško",
  "dzbel",
  "džajv",
  "džbán",
  "džber",
  "džíny",
  "džudo",
  "earom",
  "ebola",
  "eddie",
  "edice",
  "edikt",
  "edita",
  "edith",
  "efekt",
  "efuze",
  "eicon",
  "eidam",
  "eidam",
  "eiger",
  "ejhle",
  "ekzém",
  "elace",
  "elena",
  "elita",
  "eliza",
  "ellen",
  "ellis",
  "eluce",
  "email",
  "emden",
  "emise",
  "emoce",
  "empír",
  "enzym",
  "eocén",
  "eosin",
  "eozin",
  "epika",
  "eprom",
  "erika",
  "erlan",
  "erlec",
  "erose",
  "eroze",
  "erwin",
  "esový",
  "espoo",
  "essen",
  "essex",
  "ester",
  "ester",
  "estét",
  "etapa",
  "ethan",
  "ethel",
  "ethen",
  "ether",
  "ethin",
  "ethos",
  "etika",
  "etuda",
  "eviný",
  "evita",
  "excel",
  "exces",
  "extra",
  "fabio",
  "fábor",
  "facie",
  "facka",
  "facto",
  "fádní",
  "fagot",
  "fakan",
  "fakír",
  "faleš",
  "falos",
  "falše",
  "falši",
  "falší",
  "falun",
  "falus",
  "fanka",
  "farad",
  "farao",
  "farář",
  "fárat",
  "farka",
  "farma",
  "fatka",
  "fatra",
  "fatva",
  "fauna",
  "fazol",
  "fázor",
  "fekál",
  "femur",
  "fenik",
  "fénix",
  "fénix",
  "fenka",
  "fenol",
  "férie",
  "ferit",
  "fešák",
  "fešný",
  "fetiš",
  "fiakr",
  "fiala",
  "fiála",
  "ficin",
  "ficka",
  "fičet",
  "fidži",
  "fikce",
  "fíkus",
  "filec",
  "filet",
  "filoz",
  "filtr",
  "finiš",
  "finka",
  "finta",
  "fiona",
  "firma",
  "fisyo",
  "fites",
  "fixní",
  "fjord",
  "fláje",
  "fláka",
  "flauš",
  "flexe",
  "flirt",
  "flitr",
  "flora",
  "fluor",
  "fobie",
  "fojtů",
  "fokus",
  "folie",
  "folio",
  "fondu",
  "foném",
  "fonon",
  "forma",
  "formě",
  "forte",
  "fošna",
  "fotel",
  "fotit",
  "fotka",
  "foton",
  "fouré",
  "foyer",
  "frank",
  "fráze",
  "frčet",
  "frčka",
  "freie",
  "freon",
  "fresk",
  "fréza",
  "frída",
  "frita",
  "frkat",
  "froté",
  "fučet",
  "fukar",
  "fukéř",
  "fukýř",
  "fulda",
  "funět",
  "fungl",
  "funus",
  "furan",
  "fůrka",
  "fusak",
  "fusit",
  "fušer",
  "fuška",
  "futon",
  "futro",
  "fuzzy",
  "fylit",
  "fyzik",
  "gabon",
  "gabro",
  "gagát",
  "galán",
  "galej",
  "galie",
  "galon",
  "gandž",
  "ganga",
  "garáž",
  "garda",
  "garde",
  "garec",
  "gazík",
  "gdyně",
  "géčko",
  "geist",
  "gejša",
  "gekon",
  "gelug",
  "genom",
  "gerta",
  "gesce",
  "gesto",
  "ghana",
  "gibon",
  "gizán",
  "glacé",
  "glazé",
  "glejt",
  "glenn",
  "glosa",
  "gluon",
  "gnose",
  "gnoze",
  "golec",
  "golié",
  "gomel",
  "gonio",
  "gorec",
  "gouda",
  "grace",
  "grada",
  "grant",
  "greta",
  "gréta",
  "grrrr",
  "gruna",
  "grunt",
  "grupa",
  "guáno",
  "guava",
  "gulag",
  "guláš",
  "gumák",
  "gusto",
  "gympl",
  "habán",
  "hábit",
  "habří",
  "hačat",
  "háčko",
  "hačky",
  "hadač",
  "hadař",
  "hádat",
  "hadec",
  "hádek",
  "hadís",
  "hádka",
  "hafan",
  "hafat",
  "haiku",
  "haiti",
  "hajat",
  "hajdů",
  "hajdy",
  "hájek",
  "hájit",
  "hajný",
  "halab",
  "halas",
  "halda",
  "haléř",
  "halič",
  "halid",
  "halíř",
  "halit",
  "halle",
  "halon",
  "haluz",
  "halže",
  "hamás",
  "hanba",
  "handl",
  "hanit",
  "hanka",
  "hanna",
  "hanoj",
  "hanza",
  "hapat",
  "hárat",
  "harém",
  "harfa",
  "hasák",
  "hasič",
  "hasit",
  "hašiš",
  "hatín",
  "hatit",
  "havaj",
  "haven",
  "havíř",
  "havlů",
  "havre",
  "havýš",
  "hazeč",
  "házeč",
  "hbitý",
  "hebký",
  "hečte",
  "hegel",
  "heidi",
  "hejsa",
  "hekat",
  "helga",
  "helix",
  "helma",
  "hemin",
  "henka",
  "hepčí",
  "herák",
  "herda",
  "herec",
  "herka",
  "herna",
  "herní",
  "herta",
  "hertz",
  "heslo",
  "hever",
  "hexan",
  "hindí",
  "hipík",
  "hlady",
  "hlava",
  "hlína",
  "hlíst",
  "hlíva",
  "hlíza",
  "hloub",
  "hloží",
  "hltan",
  "hltat",
  "hltný",
  "hmmmm",
  "hmota",
  "hmotn",
  "hmyze",
  "hmyzu",
  "hnací",
  "hnaní",
  "hnaný",
  "hnáta",
  "hnědo",
  "hnědý",
  "hnida",
  "hníst",
  "hnití",
  "hnoje",
  "hnoji",
  "hnojů",
  "hnout",
  "hnutí",
  "hnutý",
  "hobby",
  "hobit",
  "hobla",
  "hoboj",
  "hobra",
  "hobzí",
  "hodit",
  "hodně",
  "hodný",
  "hohol",
  "hojit",
  "hojný",
  "hokej",
  "hokus",
  "holba",
  "holec",
  "holič",
  "holím",
  "holit",
  "holka",
  "holků",
  "holon",
  "holub",
  "honák",
  "honba",
  "honců",
  "honda",
  "honeb",
  "honec",
  "honem",
  "honič",
  "honit",
  "honsů",
  "honšu",
  "honzů",
  "hopla",
  "hopsa",
  "horák",
  "horda",
  "horem",
  "horka",
  "horko",
  "horký",
  "horní",
  "horor",
  "hořák",
  "hořec",
  "hořet",
  "hořký",
  "hosín",
  "hostí",
  "hošík",
  "hotel",
  "hotov",
  "houba",
  "houně",
  "houpy",
  "house",
  "houst",
  "hovno",
  "hovor",
  "howgh",
  "hrábě",
  "hrabě",
  "hrací",
  "hrách",
  "hrana",
  "hraní",
  "hrání",
  "hraný",
  "hravý",
  "hrbáč",
  "hrbit",
  "hrbol",
  "hrčet",
  "hrčka",
  "hrdlo",
  "hrkač",
  "hrkat",
  "hrkot",
  "hrnec",
  "hrnek",
  "hroch",
  "hrome",
  "hrtan",
  "hrubo",
  "hrubý",
  "hrůza",
  "hrýzt",
  "hřání",
  "hřbet",
  "hříbě",
  "hřích",
  "hříva",
  "hřmět",
  "hřmít",
  "hřmot",
  "huang",
  "hubit",
  "hubka",
  "hubno",
  "hučet",
  "hučka",
  "hudba",
  "hudec",
  "hudec",
  "hukot",
  "hulán",
  "hulec",
  "hulit",
  "hůlka",
  "humer",
  "humno",
  "humor",
  "humus",
  "hůrka",
  "hůrky",
  "husák",
  "husar",
  "huška",
  "hutný",
  "hvizd",
  "hvozd",
  "hybaj",
  "hybný",
  "hydra",
  "hyena",
  "hýkat",
  "hymna",
  "hýřit",
  "hýsly",
  "hýždě",
  "chabý",
  "chaco",
  "chálí",
  "chang",
  "chaos",
  "chasa",
  "chata",
  "chile",
  "chios",
  "chips",
  "chlad",
  "chlap",
  "chléb",
  "chloe",
  "chloé",
  "chlor",
  "chlum",
  "chlup",
  "chorý",
  "chrám",
  "chrin",
  "chrom",
  "chrpa",
  "chrrr",
  "chrup",
  "chříč",
  "chtěl",
  "chtěn",
  "chtíč",
  "chtít",
  "chůda",
  "chudý",
  "chutě",
  "chůva",
  "chůze",
  "chval",
  "chvat",
  "chvěl",
  "chvět",
  "chvil",
  "chyba",
  "chýně",
  "chyše",
  "chýše",
  "chyší",
  "ibiza",
  "ideál",
  "ideje",
  "ideji",
  "idejí",
  "idiom",
  "idiot",
  "idyla",
  "ihned",
  "ijzer",
  "ikona",
  "iksko",
  "iktus",
  "ilace",
  "ílias",
  "iljič",
  "iller",
  "ilona",
  "iluze",
  "image",
  "imagi",
  "imagí",
  "imago",
  "imise",
  "index",
  "indie",
  "indka",
  "indol",
  "indus",
  "infix",
  "infra",
  "ingot",
  "intel",
  "invex",
  "irena",
  "irena",
  "irene",
  "irina",
  "irsko",
  "irský",
  "irtyš",
  "iskar",
  "islám",
  "ismus",
  "issyk",
  "ivana",
  "iveta",
  "ivona",
  "izmir",
  "jacís",
  "jader",
  "jaffa",
  "jahel",
  "jáhen",
  "jáhla",
  "jáhna",
  "jáhne",
  "jáhni",
  "jáhnu",
  "jáhnů",
  "jáhny",
  "jahve",
  "jacht",
  "jajaj",
  "jakás",
  "jakáž",
  "jakby",
  "jakés",
  "jakož",
  "jaksi",
  "jakše",
  "jakši",
  "jakšů",
  "jakýs",
  "jakže",
  "jalta",
  "jamal",
  "jamám",
  "jamie",
  "jamka",
  "janec",
  "janek",
  "janet",
  "jánka",
  "janků",
  "japan",
  "jarča",
  "jarec",
  "jarka",
  "jarka",
  "jarní",
  "jářku",
  "jařmo",
  "jasan",
  "jásat",
  "jasna",
  "jasný",
  "jásot",
  "jaspé",
  "jater",
  "jatka",
  "jatky",
  "jatti",
  "jeans",
  "ječet",
  "jéčko",
  "ječný",
  "jedle",
  "jedlý",
  "jehla",
  "jehně",
  "jehož",
  "jejda",
  "jejej",
  "jejíž",
  "jekor",
  "jekot",
  "jelec",
  "jelen",
  "jelmo",
  "jemen",
  "jemin",
  "jémin",
  "jemný",
  "jemuž",
  "jeneč",
  "jenky",
  "jenže",
  "jeřík",
  "jesle",
  "jesse",
  "ještě",
  "jetel",
  "jevit",
  "jezíd",
  "ježek",
  "ježík",
  "ježit",
  "ježto",
  "jiang",
  "jícen",
  "jičín",
  "jidáš",
  "jídat",
  "jidiš",
  "jídlo",
  "jícha",
  "jichž",
  "jíkev",
  "jikra",
  "jílec",
  "jílek",
  "jímač",
  "jímat",
  "jimiž",
  "jímka",
  "jináč",
  "jinam",
  "jinan",
  "jince",
  "jinců",
  "jinde",
  "jindy",
  "jinec",
  "jirec",
  "jirka",
  "jirků",
  "jiřík",
  "jiřka",
  "jistě",
  "jíška",
  "jitka",
  "jitro",
  "jívka",
  "jívka",
  "jizba",
  "jízda",
  "jizva",
  "jižní",
  "jmelí",
  "jmění",
  "jmout",
  "jmutí",
  "jodid",
  "jogín",
  "jolie",
  "jolka",
  "jonah",
  "jonák",
  "joule",
  "joule",
  "jožka",
  "judea",
  "juchá",
  "juché",
  "jucht",
  "juchu",
  "jujda",
  "jukat",
  "julie",
  "julka",
  "junák",
  "junda",
  "junec",
  "junta",
  "jupka",
  "jurec",
  "jurta",
  "juvie",
  "kabar",
  "kabát",
  "kabel",
  "kablo",
  "kábul",
  "kacíř",
  "kačák",
  "kačer",
  "kačin",
  "kačka",
  "kačka",
  "káčko",
  "kadeř",
  "kadet",
  "kahan",
  "kajak",
  "kajka",
  "kakao",
  "kakat",
  "kalců",
  "kalec",
  "kalek",
  "kálet",
  "kalič",
  "kalit",
  "kalný",
  "kalup",
  "kalus",
  "kaluž",
  "kamej",
  "kamen",
  "kámen",
  "kamiš",
  "kamna",
  "kamny",
  "kámoš",
  "kampa",
  "kamsi",
  "kanál",
  "kanár",
  "kanec",
  "kanic",
  "kanoe",
  "kánoe",
  "kanon",
  "kánon",
  "kanýr",
  "kapar",
  "kapat",
  "kapec",
  "kapes",
  "kapie",
  "kapka",
  "kaple",
  "kapok",
  "kapsa",
  "karas",
  "karát",
  "kárat",
  "karet",
  "kargo",
  "kárka",
  "karla",
  "karma",
  "kárný",
  "karta",
  "kařez",
  "kasař",
  "kasat",
  "kasie",
  "kasta",
  "kasus",
  "kašag",
  "kašel",
  "kašle",
  "kašli",
  "kašlů",
  "kašna",
  "katan",
  "katar",
  "katie",
  "katka",
  "kauce",
  "kauza",
  "kavče",
  "kavka",
  "kavyl",
  "kázán",
  "kázat",
  "kazit",
  "kbely",
  "kdesi",
  "kdeže",
  "kdosi",
  "kdoví",
  "kdyby",
  "kdyně",
  "kdysi",
  "kebab",
  "kecat",
  "kecka",
  "kečup",
  "kefír",
  "kejda",
  "kékes",
  "kelec",
  "kerný",
  "keřík",
  "keser",
  "keson",
  "keton",
  "kfely",
  "khaki",
  "kibuc",
  "kilka",
  "kimmy",
  "kiosk",
  "kláda",
  "kladí",
  "kládí",
  "klaka",
  "klání",
  "klapy",
  "klára",
  "klasa",
  "klást",
  "klatý",
  "klaun",
  "kleji",
  "klení",
  "klest",
  "kletí",
  "kletr",
  "kletý",
  "klích",
  "klika",
  "klima",
  "klipr",
  "klips",
  "klišé",
  "klopa",
  "kloub",
  "klovu",
  "klový",
  "klubu",
  "kmotr",
  "kněze",
  "knězi",
  "knězů",
  "kněží",
  "kniha",
  "knipl",
  "kníže",
  "kníže",
  "knuta",
  "kobka",
  "kobra",
  "kočár",
  "kočín",
  "kočka",
  "kodek",
  "kodex",
  "kofix",
  "kohos",
  "kohož",
  "koiné",
  "kojit",
  "kojot",
  "kokon",
  "kokos",
  "kokus",
  "koláč",
  "kolař",
  "kolář",
  "koláž",
  "kolba",
  "koleč",
  "kolej",
  "kolek",
  "kolem",
  "kolie",
  "kolik",
  "kolík",
  "kolmý",
  "kolna",
  "kolos",
  "komár",
  "kombi",
  "kombo",
  "komik",
  "komsi",
  "komus",
  "komuž",
  "konat",
  "konce",
  "konci",
  "konče",
  "konec",
  "konec",
  "koněm",
  "konev",
  "kongo",
  "koník",
  "koním",
  "konto",
  "kopáč",
  "kopec",
  "kopec",
  "kopie",
  "kopka",
  "kopný",
  "kopra",
  "korab",
  "koráb",
  "korán",
  "korba",
  "korea",
  "korec",
  "korec",
  "korek",
  "koreo",
  "koreu",
  "korey",
  "korou",
  "korýš",
  "korzo",
  "kořit",
  "kosák",
  "kosec",
  "kosík",
  "kosit",
  "kosme",
  "kosmu",
  "kosoř",
  "košec",
  "košer",
  "košík",
  "koště",
  "kotec",
  "kotel",
  "kotit",
  "kotle",
  "kotli",
  "kotlu",
  "kotlů",
  "kotly",
  "kotrč",
  "kotul",
  "kotva",
  "koule",
  "koupě",
  "koušu",
  "kouta",
  "koutě",
  "koutů",
  "kouty",
  "kouty",
  "kovák",
  "kovář",
  "kovno",
  "kozel",
  "kozka",
  "kozle",
  "kožím",
  "kožka",
  "kožlí",
  "kožní",
  "krach",
  "krása",
  "krást",
  "kraul",
  "kráva",
  "krbec",
  "krček",
  "krčit",
  "krčma",
  "krční",
  "krédo",
  "krekr",
  "krems",
  "kréta",
  "krill",
  "krimi",
  "krize",
  "krkat",
  "krmič",
  "krmit",
  "krmný",
  "krnap",
  "kromě",
  "krpec",
  "krský",
  "krtek",
  "kruci",
  "krůta",
  "krůtě",
  "krutý",
  "krvák",
  "krysa",
  "krytí",
  "krytý",
  "křest",
  "křída",
  "křivý",
  "křoví",
  "křtem",
  "křtít",
  "křtům",
  "kšeft",
  "kšely",
  "kšice",
  "kšilt",
  "kšíry",
  "kuala",
  "kubec",
  "kubík",
  "kubše",
  "kubši",
  "kubšů",
  "kučeř",
  "kudla",
  "kujný",
  "kukat",
  "kukla",
  "kukuč",
  "kulak",
  "kulér",
  "kulič",
  "kulík",
  "kulit",
  "kulka",
  "kulma",
  "kůlna",
  "kumán",
  "kumba",
  "kumšt",
  "kumys",
  "kunín",
  "kupáž",
  "kupec",
  "kupec",
  "kupit",
  "kupka",
  "kupní",
  "kupon",
  "kurát",
  "kuráž",
  "kurec",
  "kurie",
  "kůrka",
  "kurva",
  "kurýr",
  "kuřák",
  "kuřba",
  "kuřec",
  "kuřim",
  "kušna",
  "kutat",
  "kutit",
  "kutna",
  "kůzle",
  "kvádr",
  "kvaga",
  "kvark",
  "kvést",
  "květa",
  "kvisa",
  "kvítí",
  "kvůli",
  "kyčel",
  "kyčle",
  "kyčli",
  "kyčlí",
  "kyčlů",
  "kydat",
  "kylie",
  "kýmsi",
  "kyoga",
  "kypět",
  "kyprý",
  "kyrys",
  "kyška",
  "kytín",
  "kytka",
  "kývat",
  "kyvný",
  "label",
  "láčka",
  "lačný",
  "ladak",
  "ladem",
  "ladič",
  "ladit",
  "ladný",
  "ládví",
  "lagen",
  "lagos",
  "lahev",
  "láhev",
  "lahti",
  "lajna",
  "lákat",
  "lalok",
  "lamač",
  "lámat",
  "lampa",
  "lanař",
  "lánek",
  "lanka",
  "lanko",
  "lanýž",
  "lapač",
  "lapák",
  "lapat",
  "lapec",
  "lapis",
  "lápis",
  "lapit",
  "larev",
  "largo",
  "larva",
  "laser",
  "láska",
  "lasse",
  "látat",
  "latex",
  "latex",
  "látka",
  "laura",
  "lávka",
  "laxní",
  "lazar",
  "lazec",
  "lázek",
  "lbích",
  "lebka",
  "lebmi",
  "léčba",
  "léčit",
  "léčka",
  "ledec",
  "ledeč",
  "ledek",
  "ledví",
  "leeds",
  "legát",
  "legie",
  "lehat",
  "léhat",
  "lehko",
  "lehký",
  "lehlý",
  "lejno",
  "lékař",
  "lekat",
  "lekce",
  "leklý",
  "lelek",
  "lemma",
  "lemur",
  "leník",
  "lenit",
  "lenka",
  "lento",
  "leona",
  "leone",
  "lepek",
  "lepit",
  "lepra",
  "lesák",
  "lesba",
  "lesní",
  "lešno",
  "leták",
  "létat",
  "letec",
  "letem",
  "letět",
  "letka",
  "letmo",
  "letmý",
  "letní",
  "letos",
  "letům",
  "levák",
  "levec",
  "level",
  "levín",
  "levný",
  "lexém",
  "lexie",
  "lezba",
  "lezec",
  "ležák",
  "ležet",
  "ležky",
  "ležmo",
  "lgbti",
  "lgbtq",
  "lhaní",
  "lhasa",
  "lhavý",
  "lhůta",
  "liána",
  "líbat",
  "libeř",
  "libež",
  "libín",
  "líbit",
  "libka",
  "liboc",
  "liboš",
  "libra",
  "libuš",
  "libye",
  "lícit",
  "lícní",
  "líčím",
  "líčit",
  "lička",
  "líčko",
  "lideč",
  "lidem",
  "lidka",
  "lidmi",
  "liege",
  "lienz",
  "ligna",
  "lihem",
  "lihům",
  "lihýř",
  "lichý",
  "liják",
  "likér",
  "likov",
  "lilek",
  "lilie",
  "limba",
  "límec",
  "limit",
  "línat",
  "linda",
  "linec",
  "linie",
  "linka",
  "linux",
  "lipám",
  "lipan",
  "lipec",
  "lipid",
  "lipka",
  "lipno",
  "lipou",
  "lísat",
  "lisec",
  "líska",
  "listí",
  "lišaj",
  "lišák",
  "lišče",
  "lišej",
  "lišit",
  "liška",
  "líšno",
  "lišta",
  "lítač",
  "lítat",
  "litíč",
  "litva",
  "livie",
  "lízač",
  "lízák",
  "lizec",
  "lizka",
  "ljuba",
  "lkaní",
  "lkání",
  "lkavý",
  "llano",
  "lnáře",
  "lnářů",
  "lněný",
  "lnice",
  "lnout",
  "lnový",
  "lnutí",
  "lobby",
  "lobeč",
  "lobzy",
  "lodín",
  "lodní",
  "logik",
  "logos",
  "loira",
  "loire",
  "lojem",
  "lojům",
  "lokál",
  "lokat",
  "loket",
  "loket",
  "lokna",
  "lokte",
  "lokte",
  "loktě",
  "lokti",
  "lokti",
  "loktu",
  "loktů",
  "lokty",
  "lomař",
  "lomec",
  "lomit",
  "lomný",
  "lomoz",
  "lorka",
  "losar",
  "losos",
  "lošák",
  "lotka",
  "lotos",
  "loubí",
  "lough",
  "louis",
  "louka",
  "louky",
  "louže",
  "lovat",
  "lovec",
  "lovit",
  "lovný",
  "ložen",
  "ložní",
  "lpění",
  "lpící",
  "lstem",
  "lstím",
  "lstmi",
  "lstný",
  "lubek",
  "lubij",
  "lubín",
  "lubno",
  "lucie",
  "lucka",
  "lucký",
  "luisa",
  "lukšů",
  "lulea",
  "luleč",
  "lulka",
  "lumek",
  "lumen",
  "lumík",
  "lupat",
  "lupek",
  "lupen",
  "lupič",
  "lupom",
  "lupus",
  "luska",
  "lustr",
  "lutín",
  "luxol",
  "luxor",
  "luxus",
  "luzný",
  "lužec",
  "lůžko",
  "lužní",
  "lvech",
  "lvice",
  "lvíče",
  "lvoun",
  "lvovi",
  "lvový",
  "lýdie",
  "lymfa",
  "lynou",
  "lyrik",
  "lysat",
  "lysec",
  "lyska",
  "lyský",
  "lysol",
  "lýtko",
  "lyzin",
  "lyzol",
  "lžemi",
  "lžíce",
  "lžicí",
  "lžích",
  "lživý",
  "macao",
  "macek",
  "macků",
  "mačka",
  "máčka",
  "mačky",
  "madam",
  "madlo",
  "madre",
  "mafie",
  "magda",
  "magie",
  "magma",
  "magna",
  "magor",
  "magot",
  "machr",
  "machů",
  "maják",
  "majka",
  "májka",
  "majna",
  "major",
  "makat",
  "makro",
  "malba",
  "malec",
  "málem",
  "malér",
  "malíč",
  "malík",
  "malín",
  "malíř",
  "malpa",
  "malše",
  "malta",
  "malta",
  "malus",
  "mamba",
  "mámit",
  "mamka",
  "mamon",
  "mamut",
  "mandl",
  "manéž",
  "mango",
  "mánie",
  "maník",
  "manka",
  "manko",
  "manon",
  "manta",
  "manýr",
  "mapka",
  "mappá",
  "marco",
  "marec",
  "marie",
  "marka",
  "marke",
  "marne",
  "marný",
  "marod",
  "marta",
  "marže",
  "mařec",
  "mářím",
  "mařit",
  "mařka",
  "masaj",
  "masař",
  "masáž",
  "maser",
  "masér",
  "masiv",
  "masív",
  "maska",
  "máslo",
  "masna",
  "masný",
  "masty",
  "mašle",
  "maten",
  "mater",
  "mateř",
  "mates",
  "matka",
  "matný",
  "mátra",
  "mávat",
  "mazák",
  "mazat",
  "mazda",
  "mazec",
  "mazný",
  "mazut",
  "mcely",
  "mdlít",
  "mečet",
  "mečík",
  "měčín",
  "medan",
  "médea",
  "medik",
  "medný",
  "megan",
  "měkka",
  "mekka",
  "měkký",
  "mekot",
  "melír",
  "mělký",
  "melta",
  "menáž",
  "menec",
  "měnič",
  "měník",
  "měnín",
  "menza",
  "měrka",
  "mermo",
  "měrný",
  "měrou",
  "měřín",
  "měřit",
  "měsíc",
  "meson",
  "město",
  "měšec",
  "měšín",
  "metal",
  "metál",
  "metan",
  "metař",
  "metat",
  "metér",
  "metla",
  "metro",
  "meuse",
  "mezek",
  "mezin",
  "mezní",
  "mezný",
  "mezon",
  "mezzo",
  "miami",
  "micka",
  "micka",
  "míček",
  "míhat",
  "mícha",
  "mijet",
  "míjet",
  "mikšů",
  "mílař",
  "milec",
  "milec",
  "mileč",
  "miles",
  "milín",
  "milíř",
  "mimus",
  "mináž",
  "mince",
  "miner",
  "mínit",
  "minka",
  "minor",
  "minsk",
  "minus",
  "mínus",
  "mirka",
  "mírka",
  "mírný",
  "mířič",
  "mířit",
  "misál",
  "mísič",
  "misie",
  "miska",
  "místo",
  "mistr",
  "míšek",
  "míšní",
  "mitra",
  "mívat",
  "mixáž",
  "mixer",
  "mixér",
  "mizet",
  "mízní",
  "mlada",
  "mlada",
  "mládě",
  "mládí",
  "mladý",
  "mláka",
  "mláto",
  "mlází",
  "mlčet",
  "mlčka",
  "mlčky",
  "mléko",
  "mlení",
  "mletí",
  "mletý",
  "mlíčí",
  "mlsat",
  "mlska",
  "mlsný",
  "mluva",
  "mlžit",
  "mlžný",
  "mmmmm",
  "mnich",
  "mnohý",
  "mnout",
  "mnucí",
  "mnutí",
  "mnutý",
  "moaré",
  "mobil",
  "mocen",
  "mocím",
  "mocný",
  "močál",
  "močit",
  "model",
  "modem",
  "modla",
  "modro",
  "modrý",
  "modul",
  "modus",
  "moháč",
  "mohan",
  "mohér",
  "mohuč",
  "moiré",
  "mokro",
  "mokrý",
  "molto",
  "moném",
  "monte",
  "monza",
  "moped",
  "morče",
  "morda",
  "morek",
  "morfé",
  "morse",
  "mořan",
  "mořit",
  "mosaz",
  "mosul",
  "mošna",
  "motat",
  "motel",
  "motiv",
  "motor",
  "motto",
  "motýl",
  "mouka",
  "mount",
  "mozek",
  "mozol",
  "možná",
  "možno",
  "možný",
  "mraze",
  "mrazu",
  "mrazů",
  "mrazy",
  "mrhat",
  "mrcha",
  "mrkat",
  "mrkev",
  "mrška",
  "mrtvý",
  "mrvec",
  "mrvit",
  "mrzák",
  "mrzet",
  "mrzký",
  "mrzky",
  "mrzut",
  "mření",
  "mstít",
  "mstný",
  "mšice",
  "mučit",
  "mučka",
  "mukat",
  "mulat",
  "mulda",
  "mulde",
  "mumie",
  "můrka",
  "murou",
  "musel",
  "muset",
  "musil",
  "musit",
  "mustr",
  "muška",
  "mušle",
  "mužik",
  "mužík",
  "mužný",
  "myčka",
  "mýdlo",
  "mykat",
  "mýlit",
  "mýlka",
  "mylný",
  "myrha",
  "myrta",
  "mysla",
  "mysli",
  "myslí",
  "myšák",
  "myšař",
  "myška",
  "myšok",
  "mýtič",
  "mýtit",
  "mýtus",
  "mžení",
  "mživý",
  "nábal",
  "náběh",
  "nabít",
  "nabit",
  "náboj",
  "nábor",
  "nabyl",
  "nabýt",
  "nabyt",
  "načas",
  "načež",
  "načít",
  "náčrt",
  "nadát",
  "nadat",
  "naděj",
  "nadél",
  "naděl",
  "nadir",
  "nadít",
  "nádor",
  "nádrž",
  "nadto",
  "nafta",
  "naháč",
  "náhlý",
  "náhon",
  "nahur",
  "náhyb",
  "najal",
  "najat",
  "najdu",
  "najel",
  "nájem",
  "najít",
  "nákří",
  "nákup",
  "nákyp",
  "nálet",
  "nálev",
  "nález",
  "nalíc",
  "nalít",
  "nalit",
  "nálož",
  "nalžu",
  "náměr",
  "námět",
  "namol",
  "námoř",
  "namur",
  "nancy",
  "nanga",
  "nanic",
  "nanka",
  "nános",
  "nanuk",
  "naoko",
  "naomi",
  "nápad",
  "nápěv",
  "nápis",
  "napít",
  "napit",
  "nápoj",
  "nápor",
  "napůl",
  "naráz",
  "náraz",
  "narew",
  "nárok",
  "náruč",
  "narva",
  "nárys",
  "nářek",
  "nářez",
  "nasel",
  "násep",
  "naset",
  "nasil",
  "nasít",
  "náspe",
  "náspu",
  "náspů",
  "náspy",
  "násyp",
  "našel",
  "našíř",
  "našít",
  "nášup",
  "nátěr",
  "nátok",
  "natož",
  "nauen",
  "nauka",
  "nauru",
  "nával",
  "návěj",
  "návěs",
  "náves",
  "navíc",
  "návin",
  "návka",
  "návod",
  "návoz",
  "návrh",
  "návyk",
  "naxos",
  "nazad",
  "název",
  "názor",
  "nažal",
  "nažat",
  "nažít",
  "nažka",
  "nažnu",
  "neagh",
  "nebes",
  "necky",
  "nečas",
  "nečín",
  "neduh",
  "negro",
  "nehet",
  "něhož",
  "nějak",
  "nejen",
  "někam",
  "někde",
  "někdy",
  "nekov",
  "nelad",
  "nelma",
  "neman",
  "němčí",
  "nemec",
  "němec",
  "němen",
  "nemíž",
  "němka",
  "nemoc",
  "němuž",
  "nénie",
  "nepál",
  "nerez",
  "nerka",
  "neřád",
  "neteř",
  "netín",
  "netto",
  "nevid",
  "nevod",
  "nexus",
  "nezly",
  "nežit",
  "nežli",
  "něžný",
  "nicka",
  "ničem",
  "ničím",
  "ničit",
  "niger",
  "nichž",
  "nijak",
  "nikam",
  "nikde",
  "nikdy",
  "nikon",
  "nimec",
  "nimes",
  "nimiž",
  "nísán",
  "nisin",
  "nitím",
  "nitka",
  "nitra",
  "nitro",
  "nivea",
  "nivka",
  "nížní",
  "nižní",
  "nocím",
  "noční",
  "noeho",
  "noemi",
  "noemu",
  "noháč",
  "nohou",
  "nomád",
  "nonet",
  "norek",
  "noril",
  "norka",
  "norma",
  "nořit",
  "nosáč",
  "nosál",
  "nosík",
  "nosit",
  "nosní",
  "nosný",
  "notář",
  "noteč",
  "notes",
  "notka",
  "notný",
  "nouze",
  "novum",
  "nožem",
  "nožík",
  "nožíř",
  "nožka",
  "nožní",
  "nožům",
  "nudit",
  "nudle",
  "nugát",
  "nulák",
  "nurie",
  "nůsek",
  "nusle",
  "nutit",
  "nuzák",
  "nuzný",
  "nůžky",
  "nyala",
  "nýbrž",
  "nýdek",
  "nylon",
  "nymfa",
  "nýtař",
  "nýtek",
  "obava",
  "občas",
  "obden",
  "obdiv",
  "oběma",
  "obilí",
  "obití",
  "obitý",
  "objal",
  "objat",
  "objel",
  "objem",
  "objev",
  "oblak",
  "oblek",
  "oblet",
  "oblít",
  "oblit",
  "obmýt",
  "obnos",
  "obočí",
  "oboje",
  "oboji",
  "obojí",
  "obora",
  "obout",
  "obraz",
  "obrna",
  "obrok",
  "obrst",
  "obruč",
  "obrys",
  "obrýt",
  "obřad",
  "obřez",
  "obsah",
  "obšít",
  "obtah",
  "obtíž",
  "obtok",
  "obutí",
  "obutý",
  "obuvi",
  "obuví",
  "obvaz",
  "obvít",
  "obvit",
  "obvod",
  "obzor",
  "oceán",
  "octan",
  "octem",
  "octům",
  "očích",
  "očima",
  "očitý",
  "očník",
  "očpak",
  "odběh",
  "odběr",
  "odbít",
  "odboj",
  "odbor",
  "odbyl",
  "odbýt",
  "oddat",
  "oddíl",
  "oději",
  "oděju",
  "odění",
  "oděný",
  "odeon",
  "oděsa",
  "odfuk",
  "odhad",
  "odjel",
  "odkal",
  "odkaz",
  "odkdy",
  "odkop",
  "odkrm",
  "odkud",
  "odkup",
  "odlet",
  "odlít",
  "odlit",
  "odlov",
  "odluh",
  "odměr",
  "odnít",
  "odnos",
  "odnož",
  "odpad",
  "odpal",
  "odpar",
  "odpis",
  "odpít",
  "odpit",
  "odpor",
  "odrat",
  "odraz",
  "odřad",
  "odřez",
  "odřít",
  "odsek",
  "odsud",
  "odsun",
  "odtah",
  "odtát",
  "odtít",
  "odtok",
  "odtud",
  "odulý",
  "odupu",
  "odval",
  "odvar",
  "odvaz",
  "odvod",
  "odvoz",
  "odvýt",
  "odžít",
  "oenin",
  "ofina",
  "ofset",
  "ohlas",
  "ohled",
  "ohnal",
  "ohníč",
  "ohrát",
  "ohrid",
  "ohryz",
  "ohřev",
  "ochoz",
  "ojedu",
  "ojení",
  "ojetí",
  "ojetý",
  "ojíst",
  "okapu",
  "okatý",
  "okolí",
  "okolo",
  "okoun",
  "okovu",
  "okraj",
  "okres",
  "okruh",
  "okřát",
  "okřín",
  "oktan",
  "oktet",
  "okuje",
  "olein",
  "oleum",
  "olina",
  "oliva",
  "oloví",
  "olovo",
  "oltář",
  "oltul",
  "olymp",
  "omega",
  "omelu",
  "omést",
  "omice",
  "omlel",
  "omlet",
  "omlít",
  "omytí",
  "omytý",
  "onaký",
  "oněga",
  "onkat",
  "onuce",
  "oocyt",
  "opava",
  "opční",
  "opéct",
  "opera",
  "opěra",
  "opiát",
  "opice",
  "opičí",
  "opilý",
  "opíši",
  "opíšu",
  "opití",
  "opitý",
  "opium",
  "oplot",
  "opole",
  "opolí",
  "opona",
  "opora",
  "oprat",
  "opřít",
  "opsal",
  "opsán",
  "opsat",
  "optik",
  "opuka",
  "orace",
  "orání",
  "oraný",
  "oranž",
  "orava",
  "orbit",
  "orgán",
  "orgie",
  "origo",
  "orkán",
  "orlík",
  "orloj",
  "ornát",
  "orsej",
  "ortel",
  "orvat",
  "ořeší",
  "osada",
  "osaka",
  "oseji",
  "oseka",
  "osení",
  "osetí",
  "osetý",
  "osice",
  "osika",
  "osina",
  "osivo",
  "oslík",
  "osmák",
  "osoba",
  "osový",
  "osten",
  "ostrý",
  "ostří",
  "osudí",
  "osvit",
  "ošití",
  "oštěp",
  "otava",
  "otéct",
  "otice",
  "otisk",
  "otlak",
  "otrlý",
  "otrok",
  "otřep",
  "otřes",
  "otřít",
  "otvor",
  "otylý",
  "oudil",
  "oudím",
  "ouško",
  "ouvej",
  "ouzko",
  "ovace",
  "ování",
  "ovátý",
  "ovčák",
  "ovití",
  "ovitý",
  "ovoce",
  "ovoci",
  "ovsem",
  "ovsům",
  "ovšem",
  "oybin",
  "ozábl",
  "ozebu",
  "ozimý",
  "ozubí",
  "ozvat",
  "ozvuk",
  "oženu",
  "ožice",
  "ožilý",
  "ožití",
  "ožrat",
  "packa",
  "packý",
  "páčit",
  "páčka",
  "pačok",
  "padák",
  "padat",
  "pádit",
  "padlí",
  "pádlo",
  "padlý",
  "pádný",
  "pager",
  "pahýl",
  "pacht",
  "pájet",
  "pájka",
  "pajzl",
  "pakáž",
  "paket",
  "pakli",
  "palác",
  "palau",
  "palba",
  "palec",
  "palec",
  "páleč",
  "palič",
  "pálit",
  "pálka",
  "palma",
  "palný",
  "palpa",
  "pamír",
  "pampa",
  "panda",
  "panec",
  "panel",
  "panem",
  "pánem",
  "pánev",
  "paním",
  "panna",
  "pánům",
  "papat",
  "papír",
  "papua",
  "párák",
  "param",
  "parám",
  "párat",
  "párek",
  "parem",
  "párem",
  "parka",
  "parma",
  "parní",
  "parno",
  "parný",
  "paroh",
  "parou",
  "parta",
  "parte",
  "párty",
  "párům",
  "pařák",
  "pařát",
  "pařba",
  "pařez",
  "pařit",
  "pářit",
  "paříž",
  "pásák",
  "pasák",
  "pasát",
  "pásat",
  "pasáž",
  "pásek",
  "pasen",
  "pasíř",
  "paska",
  "páska",
  "pásmo",
  "pasta",
  "pasus",
  "pašák",
  "pašík",
  "pašou",
  "pašům",
  "pátek",
  "patka",
  "patní",
  "patok",
  "patos",
  "patro",
  "patty",
  "paulů",
  "pauza",
  "pavec",
  "pávík",
  "pavla",
  "pavlů",
  "pažba",
  "paždí",
  "pažit",
  "pažní",
  "pecař",
  "pecen",
  "pecím",
  "pecka",
  "pěčín",
  "pečka",
  "péčko",
  "pečky",
  "pedál",
  "pedum",
  "peggy",
  "pekáč",
  "pekař",
  "peklo",
  "pěkný",
  "pelec",
  "peleš",
  "pemba",
  "pemza",
  "penál",
  "pence",
  "peněz",
  "penis",
  "pěnit",
  "peníz",
  "penny",
  "penta",
  "penze",
  "pepka",
  "pepsi",
  "perka",
  "pérko",
  "perla",
  "perno",
  "perný",
  "peron",
  "perth",
  "peruc",
  "péřák",
  "peřej",
  "pesar",
  "pesík",
  "pesto",
  "pěšák",
  "pěšec",
  "pešek",
  "pěšky",
  "pěšmo",
  "petit",
  "pětka",
  "petra",
  "petrů",
  "pěvec",
  "pevný",
  "phare",
  "pcháč",
  "piano",
  "piava",
  "piave",
  "pícka",
  "pícní",
  "picno",
  "pičín",
  "pídit",
  "piero",
  "pieta",
  "pifka",
  "pijan",
  "pikle",
  "pilaf",
  "pilan",
  "pilíř",
  "pilka",
  "pilný",
  "pinčl",
  "pinie",
  "pinka",
  "pinsk",
  "pinta",
  "pípač",
  "pípat",
  "pipka",
  "pirát",
  "pirin",
  "pírko",
  "pirna",
  "piroh",
  "písař",
  "písek",
  "pisík",
  "písmo",
  "pista",
  "pitka",
  "pítko",
  "pitva",
  "pivař",
  "pívat",
  "pivec",
  "pivín",
  "pivko",
  "pivní",
  "pivot",
  "pixel",
  "pizza",
  "pižmo",
  "plaké",
  "pláně",
  "plání",
  "planý",
  "plast",
  "plást",
  "plato",
  "plavý",
  "plážo",
  "plebs",
  "plech",
  "pleji",
  "plémě",
  "plena",
  "plení",
  "plesk",
  "pleso",
  "plést",
  "pleše",
  "pletí",
  "pletý",
  "pleva",
  "plíce",
  "plihý",
  "plisé",
  "plizé",
  "plkat",
  "plnič",
  "plnit",
  "plotr",
  "plout",
  "plovu",
  "plšík",
  "plutí",
  "plutý",
  "plymo",
  "plzák",
  "pnout",
  "pnutí",
  "pnutý",
  "pobít",
  "pobit",
  "pobyl",
  "pobyt",
  "pobýt",
  "pocit",
  "pocta",
  "počet",
  "počin",
  "počít",
  "podat",
  "podej",
  "podél",
  "poděl",
  "podíl",
  "podít",
  "podiv",
  "podle",
  "podlý",
  "podol",
  "podst",
  "poema",
  "poéma",
  "pogum",
  "pohár",
  "pohon",
  "pohov",
  "pohyb",
  "pojal",
  "pojat",
  "pojdu",
  "pojem",
  "pojit",
  "pojít",
  "pojmu",
  "poker",
  "pokoj",
  "pokos",
  "pokrm",
  "pokud",
  "pokus",
  "pokyn",
  "polák",
  "polák",
  "poldi",
  "polec",
  "polír",
  "polít",
  "polit",
  "polka",
  "polka",
  "polly",
  "polní",
  "polom",
  "pomec",
  "poměl",
  "pomět",
  "pomoc",
  "pompa",
  "ponad",
  "pončo",
  "ponec",
  "poník",
  "ponor",
  "ponos",
  "popis",
  "popít",
  "popit",
  "popka",
  "popud",
  "porce",
  "porno",
  "porod",
  "porta",
  "porub",
  "porýt",
  "poryv",
  "pořád",
  "pořad",
  "poříz",
  "posed",
  "poset",
  "posil",
  "posít",
  "poste",
  "posté",
  "posud",
  "posun",
  "posuv",
  "posyp",
  "pošel",
  "pošít",
  "pošlu",
  "pošlý",
  "pošta",
  "pošuk",
  "potáč",
  "potah",
  "potaš",
  "potaz",
  "potěh",
  "potěr",
  "potit",
  "potíž",
  "potmě",
  "potní",
  "potok",
  "potom",
  "potos",
  "potud",
  "pouhý",
  "poupě",
  "pouto",
  "pouze",
  "poval",
  "povel",
  "povít",
  "povit",
  "povoz",
  "povyk",
  "pozdě",
  "pozér",
  "pozor",
  "požal",
  "požár",
  "požat",
  "požnu",
  "prace",
  "práce",
  "práci",
  "praha",
  "prahu",
  "prahů",
  "prahy",
  "prach",
  "praní",
  "praný",
  "prase",
  "prásk",
  "právě",
  "právo",
  "pravy",
  "pravý",
  "praxe",
  "prefa",
  "prejt",
  "prejz",
  "príma",
  "prima",
  "prkno",
  "profi",
  "promo",
  "proso",
  "proti",
  "proto",
  "proud",
  "proxy",
  "prsní",
  "prsou",
  "pršák",
  "pršet",
  "prška",
  "pršut",
  "prták",
  "prvák",
  "prvek",
  "první",
  "prvok",
  "prysk",
  "přací",
  "přání",
  "přást",
  "přece",
  "přeci",
  "přede",
  "pření",
  "přese",
  "přilb",
  "přímo",
  "přímý",
  "příst",
  "přivý",
  "psací",
  "psaní",
  "psaný",
  "psáře",
  "psavý",
  "psech",
  "psina",
  "psisk",
  "psota",
  "psoun",
  "psové",
  "psovi",
  "pšššt",
  "ptáče",
  "ptaní",
  "ptení",
  "ptice",
  "pučet",
  "pudit",
  "půdní",
  "pugét",
  "pukat",
  "puklý",
  "pulec",
  "pulec",
  "půlit",
  "půlka",
  "pulpa",
  "pumpa",
  "punta",
  "pupek",
  "pupen",
  "pupík",
  "purin",
  "pusan",
  "puška",
  "putim",
  "půtka",
  "putna",
  "půvab",
  "původ",
  "pýcha",
  "pykat",
  "pylon",
  "pyrám",
  "pyren",
  "pyrit",
  "pyrop",
  "pýřit",
  "pyšel",
  "pyšný",
  "pytel",
  "pytle",
  "pytli",
  "pytlů",
  "quinn",
  "quito",
  "rabat",
  "rabín",
  "racek",
  "ráček",
  "račín",
  "ráčit",
  "račiž",
  "radar",
  "radeč",
  "radíč",
  "rádio",
  "radit",
  "radix",
  "radka",
  "rádlo",
  "radno",
  "radom",
  "radon",
  "rafat",
  "ráfek",
  "rafie",
  "ragby",
  "ráhno",
  "rajče",
  "rájec",
  "rajka",
  "rajon",
  "rakev",
  "rákos",
  "rámař",
  "rámci",
  "rámec",
  "rámek",
  "ramie",
  "rampa",
  "rámus",
  "ranám",
  "ranař",
  "ranec",
  "ranit",
  "ranka",
  "ranko",
  "ranní",
  "raper",
  "rapid",
  "rapír",
  "raroh",
  "rastr",
  "rašit",
  "ratan",
  "rauma",
  "rázem",
  "razič",
  "razie",
  "rázný",
  "ražba",
  "rčení",
  "rdbms",
  "rdění",
  "rdící",
  "rebec",
  "rébus",
  "refýž",
  "regál",
  "regec",
  "rehau",
  "rehek",
  "rejda",
  "rekce",
  "rekem",
  "rekům",
  "relax",
  "remix",
  "remíz",
  "renée",
  "renta",
  "repro",
  "resin",
  "reteč",
  "retex",
  "retka",
  "retní",
  "retný",
  "rétor",
  "retro",
  "retuš",
  "revír",
  "revma",
  "revue",
  "režie",
  "režim",
  "režný",
  "rhein",
  "rhona",
  "rhone",
  "ribát",
  "ricin",
  "rifle",
  "rigel",
  "rigol",
  "rijád",
  "rijál",
  "rikša",
  "riový",
  "ritus",
  "robit",
  "robka",
  "ročák",
  "roček",
  "roční",
  "rodák",
  "rodit",
  "rodný",
  "rodos",
  "roháč",
  "rohák",
  "rohde",
  "rohož",
  "rojit",
  "rokaj",
  "rokem",
  "rokle",
  "rolák",
  "rolba",
  "roler",
  "rolka",
  "román",
  "romka",
  "rondo",
  "ronec",
  "ronit",
  "ropný",
  "roseč",
  "rosie",
  "rosit",
  "rosný",
  "rosol",
  "rostl",
  "rostu",
  "roští",
  "rotor",
  "rouen",
  "rouno",
  "roura",
  "routa",
  "rovný",
  "rožec",
  "rsfsr",
  "rtyně",
  "rubač",
  "rubáš",
  "rubat",
  "rubik",
  "rubin",
  "rubín",
  "ručit",
  "ručka",
  "ruční",
  "rudka",
  "rudný",
  "ruina",
  "rujný",
  "rukáv",
  "rulec",
  "rulík",
  "rumba",
  "rumun",
  "runda",
  "rupat",
  "rupec",
  "rupie",
  "rusín",
  "ruska",
  "ruský",
  "rušit",
  "rušný",
  "rutil",
  "různý",
  "růžák",
  "růžek",
  "rvaní",
  "rvaný",
  "rvavý",
  "rybák",
  "rybář",
  "rybíz",
  "rybka",
  "rýček",
  "ryčet",
  "ryčný",
  "rydlo",
  "rynek",
  "rypák",
  "rypoš",
  "rysec",
  "ryska",
  "rytec",
  "ryzák",
  "ryzec",
  "ryzec",
  "ryzka",
  "rýžák",
  "rýžka",
  "rzemi",
  "rzích",
  "rzivý",
  "ržání",
  "řácký",
  "řádek",
  "řadič",
  "řádit",
  "řádka",
  "řádný",
  "řapík",
  "řasit",
  "řecko",
  "řecký",
  "řečen",
  "řečný",
  "ředit",
  "řehot",
  "řekem",
  "řeknu",
  "řekům",
  "řepař",
  "řepeč",
  "řepík",
  "řepín",
  "řepka",
  "řepný",
  "řešit",
  "řetěz",
  "řezač",
  "řezák",
  "řezba",
  "řezný",
  "řežba",
  "řičet",
  "říčka",
  "říční",
  "říčný",
  "řídeč",
  "řidič",
  "řídit",
  "řídký",
  "říhat",
  "říjný",
  "říkat",
  "římsa",
  "řípec",
  "řítit",
  "řitka",
  "řitní",
  "řízek",
  "řízný",
  "řvaní",
  "řvaný",
  "řvavý",
  "řvoun",
  "sabat",
  "sáček",
  "sáčko",
  "sadař",
  "sadba",
  "sadec",
  "sádka",
  "sádlo",
  "sádra",
  "safír",
  "safra",
  "sahat",
  "sahel",
  "saint",
  "sajda",
  "sajga",
  "sajra",
  "sakra",
  "salám",
  "salár",
  "salaš",
  "salát",
  "sálat",
  "saldo",
  "sally",
  "salon",
  "salsa",
  "salto",
  "salva",
  "samba",
  "samec",
  "samec",
  "sámek",
  "samém",
  "samet",
  "samoa",
  "samou",
  "samum",
  "samým",
  "sankt",
  "sapér",
  "sapín",
  "sarah",
  "sarin",
  "sasso",
  "satén",
  "sauce",
  "sauna",
  "savec",
  "savín",
  "savka",
  "sazba",
  "sazeč",
  "sázeč",
  "sázet",
  "sazka",
  "sázka",
  "sbití",
  "sbitý",
  "scart",
  "scéna",
  "sebou",
  "secam",
  "secco",
  "sečen",
  "sečka",
  "sečna",
  "sečný",
  "sedák",
  "sedan",
  "sedat",
  "sedět",
  "sedlo",
  "sedlý",
  "sedma",
  "ségra",
  "seina",
  "sejdu",
  "sejít",
  "sekat",
  "sekce",
  "sekta",
  "selen",
  "selka",
  "selžu",
  "semiš",
  "senař",
  "senát",
  "senáž",
  "senec",
  "seník",
  "senný",
  "senza",
  "sépie",
  "sepse",
  "sérak",
  "seret",
  "série",
  "sérum",
  "servo",
  "sesle",
  "sesun",
  "sesuv",
  "sešel",
  "sešít",
  "sešit",
  "sešlu",
  "sešlý",
  "sešup",
  "setba",
  "setnu",
  "sever",
  "sexta",
  "sexus",
  "sezam",
  "sežal",
  "sežat",
  "sežít",
  "sežnu",
  "sféra",
  "sféře",
  "sharp",
  "shell",
  "shluk",
  "shnít",
  "shoda",
  "shora",
  "shůry",
  "schnu",
  "schod",
  "sibiř",
  "sibiu",
  "sídlo",
  "siena",
  "sifon",
  "sigma",
  "silák",
  "silám",
  "silan",
  "siláž",
  "sílit",
  "silný",
  "silon",
  "silou",
  "silur",
  "silva",
  "simka",
  "sinaj",
  "sinat",
  "singl",
  "sintr",
  "sinus",
  "sípět",
  "sípot",
  "síran",
  "siret",
  "sirka",
  "sirný",
  "sirup",
  "sířit",
  "sisak",
  "sisal",
  "sítko",
  "sivec",
  "siven",
  "sivka",
  "sjedu",
  "sjení",
  "sjetí",
  "sjetý",
  "sjezd",
  "skála",
  "skalp",
  "skaní",
  "skaný",
  "skapu",
  "skaut",
  "skejt",
  "sketa",
  "skica",
  "sklad",
  "sklář",
  "sklát",
  "sklon",
  "skluz",
  "skoba",
  "skoro",
  "skote",
  "skotu",
  "skruž",
  "skrýš",
  "skrýt",
  "skryt",
  "skrze",
  "skřek",
  "skřet",
  "skříp",
  "skvět",
  "skvít",
  "skype",
  "skýva",
  "sláma",
  "slang",
  "slaný",
  "slast",
  "sláva",
  "slech",
  "slepý",
  "sleva",
  "slézt",
  "sliač",
  "slída",
  "slina",
  "slipr",
  "slipy",
  "slití",
  "slitý",
  "slíva",
  "sloha",
  "sloka",
  "sloní",
  "slota",
  "sloup",
  "slout",
  "slovo",
  "sluch",
  "sluka",
  "slůně",
  "slutí",
  "slzný",
  "smalt",
  "smání",
  "smavý",
  "smělý",
  "směna",
  "smění",
  "smést",
  "smetí",
  "smích",
  "smluv",
  "smola",
  "smolt",
  "smrčí",
  "smrti",
  "smrtí",
  "smuha",
  "smůla",
  "smytí",
  "smytý",
  "snaha",
  "snách",
  "snake",
  "snědý",
  "sněhu",
  "sněhů",
  "sněhy",
  "snění",
  "sněný",
  "snést",
  "snící",
  "sníst",
  "snivý",
  "snový",
  "soaré",
  "sobec",
  "sobol",
  "sočit",
  "sodík",
  "sofie",
  "socha",
  "sojka",
  "sojuz",
  "soket",
  "sokol",
  "solár",
  "solec",
  "solím",
  "solit",
  "solný",
  "solux",
  "soman",
  "somme",
  "sonar",
  "sonda",
  "sonet",
  "sonja",
  "sopel",
  "sopka",
  "sople",
  "sopli",
  "soplu",
  "soplů",
  "soply",
  "sorta",
  "sosák",
  "sosat",
  "sosna",
  "soška",
  "sošný",
  "sotva",
  "soudě",
  "sovět",
  "sovka",
  "spací",
  "spála",
  "spaní",
  "spára",
  "spása",
  "spásl",
  "spást",
  "spasu",
  "spavý",
  "spéct",
  "spěch",
  "spěji",
  "spění",
  "spětí",
  "spící",
  "spíkr",
  "spíže",
  "spjat",
  "splav",
  "splaz",
  "splín",
  "split",
  "spolu",
  "spona",
  "spora",
  "sport",
  "sporý",
  "spraš",
  "sprej",
  "spřež",
  "spšse",
  "spurt",
  "squat",
  "srbeč",
  "srbka",
  "srkat",
  "srnče",
  "srnec",
  "srnec",
  "srnín",
  "srnka",
  "srpek",
  "sršet",
  "sřekl",
  "stádo",
  "stálý",
  "staní",
  "start",
  "staru",
  "starý",
  "stáří",
  "státy",
  "stavu",
  "stavů",
  "stavy",
  "steak",
  "stéct",
  "stela",
  "stéla",
  "stelu",
  "stěna",
  "stero",
  "stery",
  "stesk",
  "stětí",
  "steyr",
  "stěží",
  "stisk",
  "stlal",
  "stoik",
  "stoka",
  "stole",
  "stolu",
  "stolů",
  "stoly",
  "stopa",
  "stráž",
  "strdí",
  "stres",
  "strie",
  "strip",
  "strmý",
  "stroj",
  "strom",
  "strop",
  "struh",
  "struk",
  "strup",
  "střed",
  "střeh",
  "střep",
  "střet",
  "střih",
  "střik",
  "střít",
  "střiž",
  "stříž",
  "stuha",
  "stvol",
  "sucre",
  "sudba",
  "sudič",
  "sudka",
  "sufit",
  "sufix",
  "suflé",
  "sucho",
  "suita",
  "sukně",
  "sukno",
  "sukus",
  "sulka",
  "sumář",
  "sumec",
  "sumec",
  "sumka",
  "sumýš",
  "sundy",
  "super",
  "supět",
  "suplé",
  "supot",
  "suřík",
  "sushi",
  "sušák",
  "sušič",
  "sušit",
  "suška",
  "sušno",
  "suvný",
  "svání",
  "svatý",
  "svátý",
  "svedl",
  "svedu",
  "svést",
  "svetr",
  "svézt",
  "svíce",
  "svída",
  "svině",
  "svist",
  "svita",
  "svlak",
  "svoje",
  "svoji",
  "svojí",
  "svrab",
  "swing",
  "syčák",
  "sýček",
  "syčet",
  "sykat",
  "sýkoř",
  "sykot",
  "sylfa",
  "sylva",
  "synek",
  "synem",
  "synům",
  "sypač",
  "sýpat",
  "sypek",
  "sýpka",
  "sypký",
  "sypný",
  "sýrař",
  "sýrie",
  "syrta",
  "sysel",
  "sytič",
  "sytit",
  "syžet",
  "sžití",
  "sžitý",
  "šabat",
  "šábes",
  "šafář",
  "šakal",
  "šalba",
  "šálek",
  "šálit",
  "šálka",
  "šaman",
  "šamot",
  "šance",
  "šanon",
  "šaría",
  "šaríe",
  "šaríi",
  "šaríí",
  "šarío",
  "šaríu",
  "šárka",
  "šarka",
  "šarže",
  "šařec",
  "šašek",
  "šátek",
  "šatit",
  "šatna",
  "šavle",
  "šebíř",
  "šefců",
  "šéfka",
  "šejkr",
  "šekel",
  "šelak",
  "šelda",
  "šelma",
  "šepot",
  "šerpa",
  "šeřík",
  "šeřit",
  "ševce",
  "ševce",
  "ševci",
  "ševci",
  "ševců",
  "ševců",
  "ševče",
  "ševče",
  "ševel",
  "šibík",
  "šička",
  "šidit",
  "šídlo",
  "šifon",
  "šifra",
  "šikmý",
  "šílit",
  "šimků",
  "šípek",
  "šipka",
  "širák",
  "šíráz",
  "šířit",
  "šířka",
  "šiška",
  "šišma",
  "šívat",
  "škála",
  "škára",
  "škleb",
  "škoda",
  "škola",
  "škráb",
  "škrap",
  "škubu",
  "škvár",
  "škvor",
  "šlágr",
  "šlový",
  "šmíra",
  "šmrnc",
  "šnaps",
  "šneci",
  "šneka",
  "šneku",
  "šneků",
  "šneky",
  "šofér",
  "šopka",
  "šosák",
  "šotek",
  "šoupě",
  "špajz",
  "šperk",
  "špice",
  "špína",
  "špion",
  "špíže",
  "špona",
  "šprot",
  "šprým",
  "šrafa",
  "šroub",
  "šrůta",
  "štace",
  "štauf",
  "štěně",
  "štěrk",
  "štětí",
  "štika",
  "štoků",
  "štola",
  "štras",
  "štrok",
  "štuka",
  "šturm",
  "štváč",
  "štych",
  "šubec",
  "šubka",
  "šukat",
  "šulec",
  "šumák",
  "šumař",
  "šumět",
  "šumný",
  "šumot",
  "šunka",
  "šupák",
  "šupem",
  "šuple",
  "šupům",
  "švagr",
  "švece",
  "šveci",
  "šveců",
  "švorc",
  "švový",
  "švunk",
  "tabák",
  "tablo",
  "tabon",
  "tábor",
  "tabun",
  "tácek",
  "táčka",
  "táčky",
  "tagil",
  "tahač",
  "tahák",
  "tahat",
  "tahle",
  "táhlo",
  "táhlý",
  "tajga",
  "tajit",
  "tajný",
  "takin",
  "takto",
  "takýs",
  "takže",
  "talár",
  "tálín",
  "talíř",
  "talon",
  "tanap",
  "tanec",
  "tango",
  "tanin",
  "tapír",
  "taras",
  "taric",
  "tarif",
  "tarok",
  "tartu",
  "tasit",
  "taška",
  "tatar",
  "tatáž",
  "tatce",
  "tatér",
  "tatík",
  "tatra",
  "tatrč",
  "tatry",
  "taury",
  "tavba",
  "tavič",
  "tavit",
  "tavný",
  "taxon",
  "tázat",
  "tažný",
  "tebou",
  "těbůh",
  "tečka",
  "téčko",
  "tečna",
  "tečný",
  "tehdy",
  "téhle",
  "téhož",
  "těkat",
  "telec",
  "telex",
  "telib",
  "telit",
  "telka",
  "tělka",
  "tělní",
  "telur",
  "témbr",
  "téměř",
  "temeš",
  "temno",
  "temný",
  "tempo",
  "těmto",
  "témuž",
  "témže",
  "temže",
  "tendr",
  "tenis",
  "tento",
  "tenze",
  "tepat",
  "tepec",
  "teplo",
  "teplý",
  "tepna",
  "tepot",
  "teprv",
  "terén",
  "terka",
  "těrka",
  "terno",
  "teror",
  "terst",
  "tesák",
  "tesař",
  "tesco",
  "tesil",
  "tesla",
  "těsný",
  "těsto",
  "těšín",
  "těšit",
  "tetan",
  "tetín",
  "tetka",
  "tetra",
  "texas",
  "texem",
  "těžař",
  "těžba",
  "těžit",
  "těžní",
  "théby",
  "thein",
  "these",
  "théta",
  "thiol",
  "thlic",
  "tchaj",
  "tchán",
  "tchoř",
  "tiára",
  "tibet",
  "tibie",
  "tihle",
  "ticho",
  "tikat",
  "tiket",
  "tikot",
  "tilda",
  "tílko",
  "timor",
  "tímto",
  "tipec",
  "tiráž",
  "tiret",
  "tísek",
  "tisíc",
  "tiský",
  "tisza",
  "tišit",
  "titan",
  "titán",
  "titán",
  "titíž",
  "titul",
  "tížit",
  "tížka",
  "tkaní",
  "tkaný",
  "tkvět",
  "tkvít",
  "tlach",
  "tlama",
  "tlapa",
  "tlení",
  "tlivý",
  "tlouk",
  "tlupa",
  "tmava",
  "tmavý",
  "tnout",
  "tnutí",
  "toast",
  "točit",
  "točka",
  "točna",
  "tohle",
  "tokat",
  "token",
  "tokio",
  "tolar",
  "tolik",
  "tomec",
  "tomše",
  "tomši",
  "tomšů",
  "tomto",
  "tonáž",
  "toner",
  "tonga",
  "tonka",
  "topas",
  "topaz",
  "topič",
  "topit",
  "topný",
  "topol",
  "topor",
  "torba",
  "torna",
  "torse",
  "torso",
  "torus",
  "torze",
  "torzo",
  "tosca",
  "totem",
  "totéž",
  "totiž",
  "touha",
  "tours",
  "toust",
  "touto",
  "touže",
  "tovar",
  "tovéř",
  "toxin",
  "trabl",
  "tradá",
  "trafo",
  "trakt",
  "trans",
  "trasa",
  "trast",
  "trata",
  "tráva",
  "travé",
  "trčet",
  "trdlo",
  "trefa",
  "tréma",
  "tremp",
  "trend",
  "trent",
  "trest",
  "trhač",
  "trhák",
  "trhan",
  "trhat",
  "trhlý",
  "triak",
  "triál",
  "trias",
  "triga",
  "triko",
  "trimr",
  "trish",
  "trkat",
  "trnka",
  "trnož",
  "troja",
  "trojd",
  "troje",
  "troji",
  "tropy",
  "troud",
  "trpět",
  "trpín",
  "trpký",
  "trpný",
  "trsat",
  "trumf",
  "trung",
  "trust",
  "trvat",
  "trysk",
  "tržba",
  "tržek",
  "tržit",
  "tržní",
  "tržný",
  "třásl",
  "třást",
  "třecí",
  "třech",
  "třemi",
  "tření",
  "třený",
  "třesk",
  "třesu",
  "třída",
  "třmen",
  "třpyt",
  "tsuga",
  "tubus",
  "tucet",
  "tučín",
  "tůčka",
  "tučný",
  "tudíž",
  "tuhle",
  "tukan",
  "tukum",
  "tulák",
  "tulit",
  "tumáš",
  "tumor",
  "tunec",
  "tunel",
  "tuner",
  "tunis",
  "tupec",
  "tupec",
  "tupit",
  "turbo",
  "turek",
  "turek",
  "turks",
  "turné",
  "tuřín",
  "tušit",
  "tutéž",
  "tutor",
  "tuzér",
  "tuzex",
  "tuzla",
  "tužba",
  "tužit",
  "tužka",
  "tvého",
  "tvému",
  "tvoje",
  "tvoji",
  "tvojí",
  "tvrdý",
  "tvých",
  "tvými",
  "tweed",
  "tweet",
  "twist",
  "týček",
  "tyčit",
  "tyčka",
  "týden",
  "týdne",
  "týdně",
  "týdni",
  "týdnu",
  "týdnů",
  "týdny",
  "tyfus",
  "tygře",
  "tyhle",
  "tycho",
  "týchž",
  "tykat",
  "týkat",
  "tykev",
  "týlní",
  "tylža",
  "tylže",
  "týmiž",
  "týnec",
  "týpím",
  "tyran",
  "týrat",
  "tytam",
  "tytéž",
  "tytry",
  "ubití",
  "ubitý",
  "ubohý",
  "ubrus",
  "ubudu",
  "ubytí",
  "ucpat",
  "uctít",
  "učení",
  "učený",
  "učící",
  "učíst",
  "učivo",
  "udání",
  "udaný",
  "udice",
  "udřít",
  "udupu",
  "uhlák",
  "uhlem",
  "uhlík",
  "uhlíř",
  "uhlům",
  "uhnal",
  "uhnán",
  "uhnít",
  "uhrát",
  "uhrem",
  "uhrům",
  "ucháč",
  "ujatý",
  "ujčín",
  "ujedu",
  "ujení",
  "ujetí",
  "ujetý",
  "ujgur",
  "ujíst",
  "ujití",
  "ujmul",
  "ujmut",
  "ukapu",
  "ukout",
  "ukovu",
  "ukrýt",
  "ukutí",
  "ukutý",
  "ulézt",
  "ulice",
  "ulita",
  "ulití",
  "ulitý",
  "ulkus",
  "ulpět",
  "ulpít",
  "ultra",
  "umelu",
  "umělý",
  "umění",
  "umést",
  "umlel",
  "umlet",
  "umlít",
  "umrlý",
  "umřít",
  "umytí",
  "umytý",
  "unaec",
  "unčín",
  "unést",
  "unido",
  "unita",
  "unylý",
  "upéct",
  "upíši",
  "upíšu",
  "upití",
  "upitý",
  "uprat",
  "upřít",
  "upsal",
  "upsán",
  "upsat",
  "urbář",
  "určit",
  "uréza",
  "urísa",
  "urson",
  "urvat",
  "urzon",
  "uřekl",
  "uřvat",
  "uschl",
  "usmát",
  "uspat",
  "uspěl",
  "uspět",
  "ustál",
  "ustat",
  "ustát",
  "ušatý",
  "uších",
  "ušima",
  "ušití",
  "ušitý",
  "utéct",
  "utětí",
  "utkat",
  "utrum",
  "utřít",
  "uunet",
  "uvedl",
  "uvedu",
  "uvést",
  "uvézt",
  "uvití",
  "uvitý",
  "uzbek",
  "uzení",
  "uzený",
  "uzlík",
  "uzrát",
  "uzřít",
  "uženu",
  "užití",
  "užitý",
  "užrat",
  "užský",
  "vaasa",
  "vábit",
  "vábný",
  "váček",
  "vačka",
  "vadit",
  "vadný",
  "vaduz",
  "vafle",
  "vágní",
  "vagon",
  "vagus",
  "vahám",
  "váhat",
  "váhom",
  "vahou",
  "vajec",
  "vajgl",
  "vakát",
  "vakus",
  "valát",
  "valba",
  "válec",
  "valeč",
  "váleč",
  "válet",
  "valit",
  "válka",
  "valný",
  "valon",
  "valor",
  "vanad",
  "vanda",
  "vandr",
  "vánek",
  "vanga",
  "vanky",
  "vánoc",
  "vápno",
  "varan",
  "varix",
  "várka",
  "varle",
  "varna",
  "varta",
  "vařák",
  "vařič",
  "vařit",
  "vašků",
  "vatín",
  "vatka",
  "vatra",
  "vávrů",
  "vazač",
  "vazák",
  "vázat",
  "vazba",
  "vázka",
  "vazný",
  "vážit",
  "vážka",
  "vážný",
  "vbití",
  "vbitý",
  "včela",
  "včera",
  "vdána",
  "vdaný",
  "vdech",
  "vdova",
  "vdupu",
  "věcný",
  "večka",
  "véčko",
  "věčný",
  "vědec",
  "veden",
  "vědět",
  "vedle",
  "vědma",
  "vědní",
  "vědom",
  "vedro",
  "vědro",
  "vedví",
  "vegan",
  "vegas",
  "vejdu",
  "vějíř",
  "vejít",
  "velet",
  "velho",
  "velim",
  "velín",
  "velum",
  "velur",
  "venca",
  "venda",
  "věnec",
  "venku",
  "věnný",
  "věrka",
  "věrný",
  "verse",
  "verva",
  "verze",
  "veřej",
  "věřit",
  "vesec",
  "vesel",
  "véska",
  "veslo",
  "vesna",
  "vesta",
  "vesuv",
  "věšák",
  "vešel",
  "vešlý",
  "vešmi",
  "veteš",
  "větev",
  "vetnu",
  "větný",
  "větru",
  "větrů",
  "větry",
  "větře",
  "vězet",
  "vezír",
  "vezmu",
  "věžák",
  "věžka",
  "vhled",
  "vchod",
  "vička",
  "víčko",
  "vídat",
  "vidče",
  "viděl",
  "vidím",
  "vidín",
  "vidle",
  "vidno",
  "viera",
  "vichr",
  "viják",
  "vikář",
  "vikev",
  "vikýř",
  "vilín",
  "vilka",
  "vilma",
  "vilno",
  "vilný",
  "vinan",
  "vínan",
  "vinař",
  "vinec",
  "vinen",
  "viník",
  "vinit",
  "vínko",
  "vinný",
  "vinoř",
  "vinou",
  "vinyl",
  "viola",
  "viola",
  "virec",
  "virus",
  "vířit",
  "viset",
  "viska",
  "víska",
  "vísky",
  "visla",
  "vista",
  "visum",
  "vítat",
  "vitín",
  "vítků",
  "vitro",
  "vivat",
  "vizáž",
  "vizír",
  "vizme",
  "vizte",
  "vízum",
  "vížka",
  "vjedu",
  "vjení",
  "vjetí",
  "vjetý",
  "vjezd",
  "vklad",
  "vkovu",
  "vláda",
  "vláha",
  "vlahý",
  "vlání",
  "vlára",
  "vláří",
  "vlast",
  "vlavý",
  "vlčák",
  "vlček",
  "vléct",
  "vlekl",
  "vlevo",
  "vlézt",
  "vleže",
  "vlhko",
  "vlhký",
  "vlití",
  "vlitý",
  "vlnař",
  "vlnit",
  "vlnka",
  "vloha",
  "vloni",
  "vlore",
  "vmést",
  "vnada",
  "vnést",
  "vodák",
  "vodař",
  "vodič",
  "vodík",
  "vodit",
  "vodka",
  "vodní",
  "vogel",
  "voják",
  "vojín",
  "vojka",
  "vojna",
  "vojsk",
  "vokál",
  "volán",
  "volat",
  "volba",
  "volec",
  "voleč",
  "volej",
  "volek",
  "volem",
  "volha",
  "volit",
  "volky",
  "volno",
  "volný",
  "volta",
  "volům",
  "volvo",
  "volyn",
  "vonět",
  "vonný",
  "vorař",
  "vozem",
  "vozík",
  "vozit",
  "vozům",
  "vpéct",
  "vpich",
  "vpití",
  "vpitý",
  "vpřed",
  "vpust",
  "vrána",
  "vraný",
  "vrása",
  "vrata",
  "vrbka",
  "vrčet",
  "vrhač",
  "vrhat",
  "vrkat",
  "vrkoč",
  "vršce",
  "vršek",
  "vršit",
  "vrták",
  "vrtat",
  "vrtba",
  "vrtět",
  "vrtný",
  "vrytí",
  "vrytý",
  "vrzat",
  "vřava",
  "vřelý",
  "vření",
  "vřící",
  "vsání",
  "vsátý",
  "vsedě",
  "vsení",
  "vsetý",
  "vstal",
  "vstát",
  "vstiš",
  "vstup",
  "všanc",
  "všeho",
  "všech",
  "všemi",
  "všemu",
  "všeně",
  "všeni",
  "všení",
  "všcht",
  "vších",
  "všití",
  "všitý",
  "všivý",
  "všude",
  "všudy",
  "vtéct",
  "vtětí",
  "vtisk",
  "vůbec",
  "vůkol",
  "vulgo",
  "vulva",
  "výběh",
  "výběr",
  "vybít",
  "vybit",
  "výboj",
  "výbor",
  "vybyl",
  "vybýt",
  "výčep",
  "výčet",
  "výčtě",
  "výdaj",
  "vydat",
  "výdej",
  "vydra",
  "výdrž",
  "vydře",
  "výfuk",
  "výhon",
  "výhoz",
  "výhra",
  "vyjdu",
  "vyjel",
  "výjev",
  "vyjít",
  "vyjma",
  "vyjmu",
  "výkal",
  "vykat",
  "výkaz",
  "výkon",
  "výkop",
  "výkrm",
  "výkup",
  "výkyv",
  "výlet",
  "výlev",
  "výlez",
  "vylít",
  "vylit",
  "výlov",
  "výlož",
  "výluh",
  "vylžu",
  "výmar",
  "výmaz",
  "výměr",
  "výmet",
  "výmět",
  "výmol",
  "výmyk",
  "vymýt",
  "výnos",
  "výpad",
  "výpal",
  "výpar",
  "výpek",
  "vypěl",
  "vypět",
  "výpis",
  "vypít",
  "vypit",
  "výpon",
  "výraz",
  "výrok",
  "výron",
  "výrub",
  "vyrýt",
  "výřad",
  "výřez",
  "výseč",
  "výsek",
  "vysel",
  "výsep",
  "vyset",
  "výsev",
  "vysil",
  "vysít",
  "výspa",
  "vyšel",
  "vyšít",
  "výška",
  "vyšlu",
  "vyšlý",
  "výtah",
  "vytát",
  "výtěr",
  "výtěž",
  "vytít",
  "výtka",
  "vytnu",
  "výtoč",
  "výtok",
  "výuka",
  "vývar",
  "vývěr",
  "vývin",
  "vývod",
  "vývoj",
  "vývoz",
  "vývrt",
  "výzva",
  "vyžal",
  "vyžat",
  "výžeh",
  "vyžle",
  "vyžnu",
  "vzadu",
  "vzání",
  "vzatý",
  "vzdor",
  "vzetí",
  "vzkaz",
  "vzlet",
  "vzlyk",
  "vznět",
  "vznik",
  "vznít",
  "vznos",
  "vzoru",
  "vzpor",
  "vztah",
  "vztek",
  "vztyk",
  "vženu",
  "vžití",
  "vžitý",
  "wales",
  "waltz",
  "wayne",
  "webra",
  "webrů",
  "webry",
  "webře",
  "whist",
  "wolin",
  "wuhan",
  "xenie",
  "xenie",
  "xenon",
  "xerox",
  "xsara",
  "xviii",
  "xylen",
  "xylit",
  "yahoo",
  "yukon",
  "yvona",
  "zábal",
  "záběh",
  "záběr",
  "zabít",
  "zabit",
  "zábor",
  "zábst",
  "zácpa",
  "začít",
  "zadák",
  "zadar",
  "zadat",
  "zadek",
  "zadem",
  "zadní",
  "zádrž",
  "zádub",
  "záfuk",
  "záhon",
  "záhyb",
  "zajal",
  "zajat",
  "zajdu",
  "zajel",
  "zajíc",
  "zajít",
  "zájmu",
  "zajmu",
  "zákal",
  "zákaz",
  "zákon",
  "zákop",
  "zákus",
  "zálet",
  "zalít",
  "zalit",
  "záliv",
  "zálom",
  "zálší",
  "zalžu",
  "zamec",
  "zámek",
  "záměl",
  "záměr",
  "záměs",
  "zámiš",
  "zámlk",
  "zánět",
  "zanic",
  "zánik",
  "zános",
  "západ",
  "zápal",
  "zápar",
  "zápas",
  "zapěl",
  "zapět",
  "zápis",
  "zapít",
  "zapit",
  "zápoj",
  "zápor",
  "zarýt",
  "zářez",
  "zářič",
  "zářit",
  "zářný",
  "zásah",
  "zásed",
  "zásek",
  "zasel",
  "zaset",
  "zasil",
  "zasít",
  "záspí",
  "zasvé",
  "zásyp",
  "zašel",
  "zašít",
  "zašlu",
  "zašlý",
  "záští",
  "zátah",
  "zátěr",
  "zátěž",
  "zatím",
  "zatít",
  "zátka",
  "zatnu",
  "zátok",
  "zátop",
  "zátor",
  "zátrh",
  "zával",
  "závan",
  "závar",
  "závěj",
  "závěr",
  "závěs",
  "závin",
  "zavít",
  "závit",
  "zavit",
  "závod",
  "závoj",
  "závrt",
  "závsí",
  "zavýt",
  "zažal",
  "zažat",
  "zážeh",
  "zažnu",
  "zbití",
  "zbitý",
  "zblít",
  "zblit",
  "zboku",
  "zboží",
  "zbudu",
  "zbůch",
  "zbylý",
  "zbyny",
  "zbytí",
  "zcela",
  "zdali",
  "zdáli",
  "zdání",
  "zdech",
  "zdění",
  "zděný",
  "zdice",
  "zdící",
  "zdích",
  "zdíky",
  "zdíša",
  "zdivo",
  "zdola",
  "zdroj",
  "zdupu",
  "zdvih",
  "zdviž",
  "zebra",
  "zefír",
  "zefýr",
  "zelný",
  "zelot",
  "zemák",
  "zemní",
  "zenit",
  "zerav",
  "zetko",
  "zetor",
  "zevní",
  "zhola",
  "zídka",
  "zimní",
  "zimoř",
  "zinek",
  "zírat",
  "zítka",
  "zítra",
  "zívat",
  "zjara",
  "zkáza",
  "zkovu",
  "zkrat",
  "zkrut",
  "zlata",
  "zlato",
  "zlatý",
  "zleva",
  "zlézt",
  "zlití",
  "zlitý",
  "zloba",
  "zmást",
  "zmátl",
  "zmatu",
  "změna",
  "zmije",
  "zmoci",
  "znalý",
  "známý",
  "znání",
  "znělý",
  "znění",
  "znící",
  "znivý",
  "znova",
  "znovu",
  "zobák",
  "zoban",
  "zobec",
  "zorat",
  "zorka",
  "zpití",
  "zpitý",
  "zplna",
  "zpola",
  "zpoza",
  "zprvu",
  "způli",
  "zrada",
  "zralý",
  "zrána",
  "zrání",
  "zrnit",
  "zrnko",
  "zrubu",
  "zrůda",
  "zrytí",
  "zrytý",
  "zrzek",
  "zrzka",
  "zřekl",
  "zření",
  "zřený",
  "zřící",
  "zšíři",
  "ztéct",
  "ztuha",
  "zubař",
  "zubec",
  "zubit",
  "zubní",
  "zubný",
  "zubre",
  "zumba",
  "zuřit",
  "zuzka",
  "zvaní",
  "zvaný",
  "zvěst",
  "zvíci",
  "zvíře",
  "zvole",
  "zvrat",
  "zvůle",
  "žabák",
  "žabám",
  "žabec",
  "žaber",
  "žabka",
  "žábra",
  "žábry",
  "žáček",
  "žačka",
  "žádat",
  "žádný",
  "žakár",
  "žakéř",
  "žaket",
  "žalář",
  "žalný",
  "žalud",
  "žárný",
  "žatec",
  "žatva",
  "žatva",
  "žebro",
  "želeč",
  "želiv",
  "želka",
  "želva",
  "žemle",
  "ženáč",
  "žence",
  "ženci",
  "ženců",
  "ženče",
  "ženit",
  "ženka",
  "žervé",
  "žespé",
  "žestě",
  "žesti",
  "žeton",
  "žezlo",
  "žhavý",
  "žhery",
  "židák",
  "židle",
  "žíhat",
  "žihle",
  "žilám",
  "žilka",
  "žilní",
  "žilný",
  "žilou",
  "žínka",
  "žírec",
  "žírný",
  "žitný",
  "živec",
  "živec",
  "živel",
  "živio",
  "živit",
  "živný",
  "žláza",
  "žluna",
  "žluto",
  "žlutý",
  "žluva",
  "žnout",
  "žnutí",
  "žofie",
  "žofka",
  "žraní",
  "žrání",
  "žraný",
  "žravý",
  "žrece",
  "žreci",
  "žreců",
  "žreče",
  "žrout",
  "žudro",
  "žumpa",
  "župka",
  "žvást",
  "žvavý",
  "adria",
  "annin",
  "apríl",
  "auťák",
  "axióm",
  "balík",
  "balog",
  "balón",
  "baník",
  "baňka",
  "báseň",
  "bavor",
  "bázeň",
  "binar",
  "boing",
  "bójka",
  "brest",
  "budík",
  "bujón",
  "buňka",
  "burel",
  "bursa",
  "byťák",
  "cesta",
  "civil",
  "cucák",
  "čeleď",
  "čtvrť",
  "čurka",
  "dáseň",
  "deňka",
  "dipól",
  "doksy",
  "ďolík",
  "drúza",
  "ďubka",
  "dupák",
  "dutky",
  "fajka",
  "fióla",
  "flóra",
  "fluór",
  "fóbie",
  "fólie",
  "foťák",
  "frňák",
  "fúrie",
  "gnóze",
  "hajzl",
  "holeň",
  "chlór",
  "chmel",
  "chróm",
  "chvoj",
  "iluse",
  "javor",
  "jeřáb",
  "jeseň",
  "joint",
  "kadaň",
  "kaňka",
  "kanón",
  "kaňon",
  "katar",
  "kazaň",
  "kázeň",
  "kodaň",
  "kodér",
  "komín",
  "komňa",
  "koňak",
  "koňák",
  "koňka",
  "kónus",
  "korál",
  "kořen",
  "kreml",
  "kreol",
  "kubáň",
  "kukaň",
  "kuňka",
  "kupón",
  "kužel",
  "kvóta",
  "labuť",
  "laňka",
  "laťka",
  "lázeň",
  "lemúz",
  "lhota",
  "libáň",
  "libeň",
  "libuň",
  "líheň",
  "líšeň",
  "loďka",
  "louny",
  "luďka",
  "ľupča",
  "lužka",
  "maria",
  "měďák",
  "milka",
  "mimoň",
  "míšeň",
  "mocha",
  "morka",
  "moták",
  "muňka",
  "námel",
  "náplň",
  "národ",
  "odbyt",
  "odliv",
  "oprať",
  "orion",
  "ořech",
  "ósaka",
  "panák",
  "páteř",
  "pausa",
  "pečeť",
  "pense",
  "pérák",
  "perón",
  "peruť",
  "pěťák",
  "piják",
  "pilot",
  "píseň",
  "plasy",
  "plzeň",
  "polyp",
  "poměr",
  "popel",
  "potka",
  "poušť",
  "prdel",
  "próza",
  "prťák",
  "prvka",
  "puťák",
  "puťka",
  "rajón",
  "robot",
  "roveň",
  "rover",
  "rozum",
  "řemen",
  "salón",
  "sáňky",
  "sifón",
  "síňka",
  "síťka",
  "sklep",
  "skráň",
  "skreč",
  "skříň",
  "skútr",
  "smrad",
  "smršť",
  "smysl",
  "solar",
  "soluň",
  "spóra",
  "srbka",
  "sršeň",
  "srůst",
  "stáňa",
  "stráň",
  "súdán",
  "sumit",
  "šatka",
  "šifón",
  "šípka",
  "šišák",
  "škrob",
  "šmejd",
  "šňůra",
  "špunt",
  "šťára",
  "šťáva",
  "štóla",
  "ťafka",
  "talin",
  "talón",
  "ťapka",
  "taxík",
  "tenor",
  "theta",
  "tíseň",
  "tonus",
  "toruň",
  "trója",
  "tříšť",
  "ťukot",
  "tulka",
  "tunka",
  "tůňka",
  "učňák",
  "úhona",
  "úchyt",
  "újezd",
  "úklad",
  "úklid",
  "úklon",
  "úkrok",
  "úkrop",
  "úkryt",
  "úleva",
  "úloha",
  "úměra",
  "úmysl",
  "únava",
  "úplet",
  "úroda",
  "úřada",
  "úsada",
  "úskok",
  "úsměv",
  "ústav",
  "ústrk",
  "ústup",
  "úsvit",
  "úštěk",
  "úštěp",
  "útisk",
  "útlak",
  "útlum",
  "útvar",
  "úvaha",
  "úžina",
  "vagón",
  "vášeň",
  "vídeň",
  "višeň",
  "vrůst",
  "výduť",
  "výheň",
  "výplň",
  "zbraň",
  "zbroj",
  "zeleň",
  "žízeň",
  "žolík",
  "župan",
  "abuse",
  "abúzu",
  "acené",
  "adama",
  "adame",
  "adamu",
  "adamy",
  "adamů",
  "admin",
  "adobe",
  "adolf",
  "adres",
  "adrou",
  "adách",
  "adéla",
  "adéle",
  "adélu",
  "adély",
  "aftem",
  "aftům",
  "aféru",
  "aféry",
  "aféře",
  "agend",
  "agfou",
  "agáta",
  "agáto",
  "agátu",
  "agáty",
  "agátě",
  "ahuje",
  "akcii",
  "akcií",
  "akcím",
  "akrem",
  "akrům",
  "aktem",
  "aktům",
  "akátu",
  "akáty",
  "akátů",
  "akčně",
  "albem",
  "albou",
  "albům",
  "aleje",
  "aleji",
  "alejí",
  "alena",
  "aleno",
  "alenu",
  "aleny",
  "aleně",
  "alexe",
  "aleše",
  "aleši",
  "alfou",
  "alice",
  "alici",
  "alicí",
  "alkou",
  "alláh",
  "alois",
  "alpha",
  "alpou",
  "alpám",
  "altem",
  "alšům",
  "alšův",
  "alžír",
  "ambic",
  "amfor",
  "amino",
  "aminu",
  "aminy",
  "aminů",
  "amoku",
  "amora",
  "amorů",
  "amuru",
  "amébu",
  "améby",
  "amíci",
  "andám",
  "anděl",
  "aneta",
  "aneto",
  "anetu",
  "anety",
  "anetě",
  "anexi",
  "anexí",
  "angín",
  "anita",
  "anket",
  "ankou",
  "annin",
  "annou",
  "anném",
  "anodu",
  "anody",
  "anodě",
  "anoví",
  "antil",
  "anton",
  "antén",
  "anuit",
  "anály",
  "análů",
  "aných",
  "anýzu",
  "ančin",
  "ančím",
  "aortu",
  "aorty",
  "aortě",
  "apelu",
  "apely",
  "aplet",
  "apnoi",
  "apnoí",
  "apple",
  "apsid",
  "araba",
  "arabi",
  "arabu",
  "araby",
  "arabů",
  "arama",
  "archu",
  "archy",
  "archů",
  "arciť",
  "ariím",
  "arkád",
  "armád",
  "armén",
  "arsko",
  "artis",
  "artur",
  "artěl",
  "aruba",
  "arénu",
  "arény",
  "aréně",
  "aróma",
  "ascii",
  "ascií",
  "asiat",
  "aspoň",
  "aster",
  "astou",
  "astro",
  "astry",
  "ataku",
  "ataky",
  "ataků",
  "atapi",
  "atari",
  "atipu",
  "atnit",
  "atněn",
  "atolu",
  "atoly",
  "atolů",
  "atomu",
  "atomy",
  "atomů",
  "atrap",
  "atria",
  "atriu",
  "atého",
  "atény",
  "atých",
  "audia",
  "audio",
  "aukci",
  "aukcí",
  "aulou",
  "aulám",
  "aurou",
  "autem",
  "autou",
  "autům",
  "avala",
  "avalo",
  "avalu",
  "avary",
  "avarů",
  "avast",
  "avaři",
  "avizo",
  "avíza",
  "azory",
  "azték",
  "azuru",
  "azylu",
  "azyly",
  "ašsko",
  "babce",
  "babek",
  "babit",
  "babku",
  "babky",
  "babou",
  "babám",
  "babím",
  "bacím",
  "bafal",
  "bafli",
  "bafni",
  "baget",
  "bagle",
  "bagru",
  "bagry",
  "bagrů",
  "bahna",
  "bahnu",
  "bahny",
  "bahně",
  "bajce",
  "bajek",
  "bajku",
  "bajky",
  "bajtu",
  "bajty",
  "bajtů",
  "bakov",
  "balad",
  "balem",
  "balen",
  "balil",
  "balič",
  "balme",
  "balné",
  "balte",
  "baltu",
  "balzu",
  "balzy",
  "balím",
  "balíš",
  "bance",
  "bandu",
  "bandy",
  "bandě",
  "banko",
  "banku",
  "banky",
  "baník",
  "baněk",
  "barem",
  "baria",
  "bariu",
  "barií",
  "barka",
  "barma",
  "barme",
  "barmu",
  "barmy",
  "barmě",
  "barou",
  "barvi",
  "barvu",
  "barvy",
  "barví",
  "barvě",
  "barya",
  "baryu",
  "barům",
  "basem",
  "basic",
  "basku",
  "basou",
  "basse",
  "basám",
  "basům",
  "baudů",
  "baven",
  "bavil",
  "bavme",
  "bavte",
  "bavím",
  "bavíš",
  "bazuk",
  "baňce",
  "baňku",
  "baňky",
  "baška",
  "baštu",
  "bašty",
  "baští",
  "baště",
  "baťoh",
  "bažil",
  "bažin",
  "bažím",
  "bdíme",
  "bdíte",
  "bděla",
  "bděle",
  "bděli",
  "bdělo",
  "bděly",
  "bdělá",
  "bdělé",
  "bdělí",
  "bděte",
  "beata",
  "becně",
  "becvu",
  "becvy",
  "bedel",
  "beden",
  "bedlu",
  "bedly",
  "bednu",
  "bedny",
  "bedně",
  "bedro",
  "bejby",
  "belle",
  "beneš",
  "benin",
  "benou",
  "bereš",
  "berli",
  "berly",
  "berlí",
  "berme",
  "bernu",
  "berou",
  "berta",
  "berte",
  "besed",
  "besip",
  "betón",
  "bezno",
  "beáta",
  "beček",
  "bečel",
  "bečku",
  "bečky",
  "bečov",
  "bečva",
  "bečvu",
  "bečvy",
  "bečvě",
  "bibli",
  "biblí",
  "bicím",
  "bidel",
  "bidla",
  "bidle",
  "bidlu",
  "bidly",
  "bijem",
  "biješ",
  "bijme",
  "bijte",
  "bikin",
  "billa",
  "billy",
  "biosu",
  "biosy",
  "biosů",
  "bitce",
  "bitek",
  "bitem",
  "bitev",
  "bitku",
  "bitky",
  "bitou",
  "bitvu",
  "bitvy",
  "bitvě",
  "bitém",
  "bitím",
  "bitým",
  "bitům",
  "bivoj",
  "bizet",
  "bičem",
  "bičuj",
  "bičům",
  "biřic",
  "biřič",
  "black",
  "blafy",
  "blafů",
  "blaha",
  "blahu",
  "blahy",
  "blahé",
  "blata",
  "blaty",
  "blaze",
  "blbce",
  "blbci",
  "blbců",
  "blbej",
  "blbky",
  "blbli",
  "blbne",
  "blbni",
  "blbnu",
  "blbou",
  "blbém",
  "blbým",
  "blbče",
  "blbům",
  "blbův",
  "blech",
  "bledl",
  "bledá",
  "bledé",
  "bledí",
  "bledě",
  "bleje",
  "bleší",
  "blice",
  "blije",
  "bliky",
  "bliká",
  "bliké",
  "bliký",
  "blila",
  "blili",
  "blilo",
  "blily",
  "blitá",
  "blité",
  "blogu",
  "blogy",
  "blogů",
  "bloku",
  "bloky",
  "bloků",
  "bludu",
  "bludy",
  "bludů",
  "blufu",
  "blufy",
  "bláno",
  "blánu",
  "blány",
  "bláně",
  "bláta",
  "blátu",
  "bláty",
  "blátě",
  "bláža",
  "blínu",
  "blíže",
  "blíží",
  "blůze",
  "blůzu",
  "blůzy",
  "bního",
  "bobax",
  "bobeš",
  "bobku",
  "bobky",
  "bobou",
  "bobra",
  "bobru",
  "bobry",
  "bobrů",
  "bobře",
  "bobři",
  "bobří",
  "bobše",
  "bobši",
  "bobšů",
  "bobům",
  "bodal",
  "bodav",
  "bodce",
  "bodci",
  "bodej",
  "bodem",
  "bodla",
  "bodli",
  "bodne",
  "bodni",
  "bodná",
  "bodné",
  "bodou",
  "bodrá",
  "bodré",
  "boduj",
  "bodám",
  "bodán",
  "bodáš",
  "bodém",
  "bodým",
  "bodře",
  "bodří",
  "bodům",
  "bohou",
  "bohyň",
  "bohém",
  "bohův",
  "bojem",
  "bojme",
  "bojou",
  "bojte",
  "bojuj",
  "bojím",
  "bojíš",
  "bojům",
  "bokům",
  "bolek",
  "bolel",
  "bolem",
  "bolou",
  "bombu",
  "bomby",
  "bombě",
  "bondy",
  "bonem",
  "bonnu",
  "bonům",
  "boomu",
  "bootů",
  "borce",
  "borci",
  "borců",
  "bordó",
  "borek",
  "borem",
  "boris",
  "bortí",
  "bosch",
  "bosna",
  "bosnu",
  "bosny",
  "bosně",
  "bosou",
  "bosse",
  "bossů",
  "bosém",
  "bosým",
  "botek",
  "botku",
  "botky",
  "botou",
  "botám",
  "boudu",
  "boudy",
  "boudě",
  "bouli",
  "boulí",
  "bourá",
  "bouré",
  "bourý",
  "bouři",
  "bouří",
  "bowle",
  "boxem",
  "boxuj",
  "boxík",
  "boxům",
  "bočně",
  "bořek",
  "bořil",
  "bořte",
  "bořím",
  "boříš",
  "božím",
  "bradu",
  "brady",
  "bradě",
  "braku",
  "brala",
  "brali",
  "bralo",
  "braly",
  "braná",
  "brané",
  "brblá",
  "brblé",
  "brblý",
  "breku",
  "bremy",
  "brept",
  "brest",
  "brečí",
  "brita",
  "brity",
  "britů",
  "brkem",
  "brkou",
  "brnem",
  "brnkl",
  "brnká",
  "brnou",
  "brněl",
  "brodu",
  "brody",
  "brodí",
  "brodě",
  "brodů",
  "brojí",
  "broku",
  "broky",
  "broků",
  "bromu",
  "brože",
  "broži",
  "broží",
  "brumu",
  "brumy",
  "bruno",
  "brusn",
  "brusu",
  "brusy",
  "bručí",
  "brvou",
  "brzdi",
  "brzdu",
  "brzdy",
  "brzdí",
  "brzdě",
  "brzká",
  "brzké",
  "brzký",
  "bráni",
  "bráno",
  "bránu",
  "brány",
  "brání",
  "bráně",
  "bráti",
  "brázd",
  "brómu",
  "brýlí",
  "brček",
  "brčka",
  "brňan",
  "brňák",
  "bublá",
  "bublé",
  "bublý",
  "bubnu",
  "bubny",
  "bubnů",
  "budce",
  "budek",
  "budem",
  "budeč",
  "budeš",
  "budhu",
  "budhy",
  "budil",
  "budiž",
  "budku",
  "budky",
  "budou",
  "budov",
  "buduj",
  "budíc",
  "budím",
  "budíš",
  "build",
  "bujel",
  "bujná",
  "bujné",
  "bujní",
  "bujně",
  "bujou",
  "bukem",
  "bulce",
  "bulek",
  "bulku",
  "bulky",
  "bulou",
  "bulte",
  "bulvu",
  "bulvy",
  "bulvě",
  "bulím",
  "bundu",
  "bundy",
  "bundě",
  "buněk",
  "burce",
  "burek",
  "burku",
  "burky",
  "burse",
  "burze",
  "burzu",
  "burzy",
  "busem",
  "bustu",
  "busty",
  "bustě",
  "buzen",
  "buzna",
  "buzny",
  "buzol",
  "buzík",
  "buček",
  "bučel",
  "bučme",
  "bučte",
  "bučím",
  "bučíš",
  "buďme",
  "buďsi",
  "buďte",
  "buďto",
  "buňce",
  "buňek",
  "buňku",
  "buňky",
  "buřta",
  "buřty",
  "buřtů",
  "bušem",
  "bušil",
  "bušme",
  "bušte",
  "buším",
  "bušíš",
  "bydel",
  "bydla",
  "bydle",
  "bydlu",
  "bydly",
  "bydlí",
  "bylin",
  "bylou",
  "byret",
  "bysme",
  "bysty",
  "bytem",
  "bytná",
  "bytné",
  "bytou",
  "bytím",
  "bytům",
  "byňov",
  "byťsi",
  "bzová",
  "bzové",
  "bzový",
  "bzučí",
  "bábou",
  "bábám",
  "bádal",
  "bádej",
  "bádou",
  "bádám",
  "bádáš",
  "báglu",
  "bájil",
  "bájná",
  "bájné",
  "bájní",
  "bájou",
  "bájím",
  "bálem",
  "bálou",
  "bálům",
  "bánov",
  "bárce",
  "bárek",
  "bárin",
  "bárku",
  "bárky",
  "bárou",
  "bárta",
  "básni",
  "básní",
  "básně",
  "bázni",
  "bázní",
  "bázně",
  "bázím",
  "bářin",
  "bédou",
  "bédův",
  "bérce",
  "béček",
  "béčka",
  "béčku",
  "béďův",
  "bídná",
  "bídné",
  "bídní",
  "bídně",
  "bídou",
  "bílej",
  "bílil",
  "bílku",
  "bílky",
  "bílků",
  "bílou",
  "bílém",
  "bílím",
  "bílíš",
  "bílým",
  "bíteš",
  "bítov",
  "bójce",
  "bójek",
  "bójku",
  "bójky",
  "bójou",
  "bójím",
  "bórem",
  "bórou",
  "býkem",
  "býkům",
  "býkův",
  "býlím",
  "býval",
  "bývou",
  "bývám",
  "bývém",
  "bývým",
  "býčci",
  "býčka",
  "býčky",
  "býčků",
  "býčím",
  "bědná",
  "bědné",
  "bědou",
  "běduj",
  "běhal",
  "běhej",
  "běhno",
  "běhou",
  "běhám",
  "běháš",
  "běhém",
  "běhým",
  "bělal",
  "bělil",
  "bělin",
  "bělič",
  "bělma",
  "bělmu",
  "bělou",
  "bělém",
  "bělím",
  "bělým",
  "běsná",
  "běsné",
  "běsní",
  "bětin",
  "bětka",
  "bětou",
  "běžce",
  "běžci",
  "běžců",
  "běžek",
  "běžel",
  "běžná",
  "běžné",
  "běžní",
  "běžně",
  "běžte",
  "běžím",
  "běžíš",
  "běžče",
  "břasy",
  "břehu",
  "břehy",
  "břehů",
  "břest",
  "břich",
  "břitu",
  "břity",
  "břitá",
  "břité",
  "břitý",
  "břitů",
  "břéťa",
  "břímě",
  "bříze",
  "břízu",
  "břízy",
  "bůrou",
  "bůčku",
  "bůžka",
  "cache",
  "cadca",
  "cadci",
  "canon",
  "capou",
  "carem",
  "carou",
  "carům",
  "carův",
  "cause",
  "cd-da",
  "cd-rw",
  "cebit",
  "cebiv",
  "cecha",
  "cechu",
  "cechy",
  "cechů",
  "cecky",
  "cedil",
  "cedry",
  "cedrů",
  "cedím",
  "cedíš",
  "cejny",
  "cekou",
  "cekáš",
  "cekém",
  "cekým",
  "celku",
  "celky",
  "celků",
  "cella",
  "celně",
  "celou",
  "celtu",
  "celty",
  "celtě",
  "celul",
  "celám",
  "celém",
  "celím",
  "celým",
  "cenil",
  "cenin",
  "cenná",
  "cenné",
  "cenou",
  "centi",
  "centu",
  "centy",
  "centů",
  "cenám",
  "ceníc",
  "cením",
  "ceníš",
  "ceněn",
  "cepem",
  "cepuj",
  "cepům",
  "cerem",
  "ceres",
  "cesia",
  "cesiu",
  "cesií",
  "cesto",
  "cestu",
  "cesty",
  "cestě",
  "cetek",
  "cetku",
  "cetky",
  "cetno",
  "chabá",
  "chabé",
  "chabě",
  "chart",
  "chatu",
  "chaty",
  "chatě",
  "chatů",
  "chcal",
  "chceš",
  "chcát",
  "chcíp",
  "cheat",
  "chebu",
  "chile",
  "chipu",
  "chipy",
  "chlaď",
  "chlop",
  "chlub",
  "chlév",
  "chlív",
  "chmat",
  "chmur",
  "chodu",
  "chody",
  "chodí",
  "chodě",
  "chodů",
  "chopí",
  "chorá",
  "choré",
  "choti",
  "chotí",
  "chotě",
  "chovn",
  "chovu",
  "chovy",
  "chová",
  "chové",
  "chový",
  "chovů",
  "choří",
  "choťe",
  "chraň",
  "chrlí",
  "chrní",
  "chrpu",
  "chrpy",
  "chrpě",
  "chrta",
  "chrti",
  "chrty",
  "chrtí",
  "chrtů",
  "chrčí",
  "chtěj",
  "chudl",
  "chudá",
  "chudé",
  "chudí",
  "chudě",
  "chuti",
  "chutí",
  "chvál",
  "chvěj",
  "chybu",
  "chyby",
  "chybí",
  "chybě",
  "chytl",
  "chyty",
  "chytá",
  "chyté",
  "chytí",
  "chytý",
  "chyše",
  "chápe",
  "chápu",
  "chóru",
  "chóry",
  "chórů",
  "chýlí",
  "chýši",
  "chýší",
  "chříč",
  "chůdy",
  "chůvo",
  "chůvu",
  "chůvy",
  "chůvě",
  "chůzi",
  "chůzí",
  "cidry",
  "cifer",
  "cifru",
  "cifry",
  "cigán",
  "cihel",
  "cihle",
  "cihlu",
  "cihly",
  "cikád",
  "cikán",
  "cilka",
  "cimru",
  "cimry",
  "cimře",
  "cinch",
  "cinkl",
  "cinky",
  "cinká",
  "cinké",
  "cinký",
  "cinou",
  "cirku",
  "citem",
  "citer",
  "cituj",
  "citům",
  "civím",
  "civěl",
  "cizin",
  "cizím",
  "clech",
  "clená",
  "clené",
  "clonu",
  "clony",
  "cloní",
  "cloně",
  "cobol",
  "cocou",
  "codou",
  "colou",
  "compu",
  "compy",
  "copem",
  "copům",
  "cotou",
  "coulu",
  "coulů",
  "couru",
  "coury",
  "courá",
  "couré",
  "courý",
  "couvl",
  "couvá",
  "couvé",
  "couvý",
  "covní",
  "cpala",
  "cpali",
  "cpalo",
  "cpaly",
  "cpaná",
  "cpané",
  "cpeme",
  "cpete",
  "cpěte",
  "cross",
  "cruss",
  "crčel",
  "cslou",
  "cstug",
  "csvts",
  "ctila",
  "ctili",
  "ctilo",
  "ctily",
  "ctnou",
  "ctném",
  "ctným",
  "ctíme",
  "ctíte",
  "ctěme",
  "ctěno",
  "ctěná",
  "ctěné",
  "ctěte",
  "cucal",
  "cuchá",
  "cucky",
  "cucla",
  "cucli",
  "cuclo",
  "cucly",
  "cucou",
  "cucám",
  "cucém",
  "cucým",
  "cudná",
  "cudné",
  "cudně",
  "cukal",
  "cuket",
  "cukla",
  "cukli",
  "cuklo",
  "cukly",
  "cukne",
  "cuknu",
  "cukou",
  "cukru",
  "cukry",
  "cukrů",
  "cukém",
  "cukým",
  "culil",
  "culíš",
  "cupek",
  "curia",
  "curiu",
  "cvakl",
  "cvaká",
  "cvaké",
  "cvaký",
  "cvalu",
  "cvikl",
  "cviku",
  "cviky",
  "cviků",
  "cviče",
  "cvičí",
  "cvoci",
  "cvoka",
  "cvoku",
  "cvoky",
  "cvoků",
  "cválá",
  "cválé",
  "cválý",
  "cyklo",
  "cyklu",
  "cykly",
  "cyklí",
  "cyklů",
  "cyrda",
  "cyril",
  "cystu",
  "cysty",
  "cystě",
  "czech",
  "cákal",
  "cákla",
  "cákli",
  "cáklo",
  "cákly",
  "cápek",
  "cárem",
  "cárům",
  "césar",
  "cévce",
  "cévek",
  "cévku",
  "cévky",
  "cévně",
  "cévou",
  "cévám",
  "céčka",
  "cíchy",
  "cídil",
  "cídím",
  "cídíš",
  "cílem",
  "cílen",
  "cílie",
  "cílil",
  "cílou",
  "cílům",
  "cínem",
  "cípem",
  "cípům",
  "císař",
  "cítil",
  "cítov",
  "cítím",
  "cítíš",
  "cívce",
  "cívek",
  "cívku",
  "cívky",
  "cůpek",
  "cůpky",
  "dabuj",
  "dacan",
  "dadou",
  "dakar",
  "dalar",
  "další",
  "danem",
  "danin",
  "danka",
  "danou",
  "dante",
  "daném",
  "daník",
  "daním",
  "daným",
  "daněm",
  "daněn",
  "danův",
  "darem",
  "darja",
  "darou",
  "daruj",
  "darům",
  "datem",
  "datlí",
  "datou",
  "datuj",
  "datům",
  "davem",
  "david",
  "davle",
  "davům",
  "dačic",
  "daňci",
  "daňka",
  "daňky",
  "daňků",
  "daňov",
  "daňčí",
  "dařil",
  "daříš",
  "dbají",
  "dbala",
  "dbali",
  "dbalo",
  "dbaly",
  "dbalá",
  "dbalé",
  "dbalí",
  "dbáme",
  "dbáno",
  "dbáte",
  "dbého",
  "dbému",
  "dbých",
  "dbýma",
  "dbými",
  "debat",
  "debet",
  "debil",
  "dechu",
  "dechy",
  "dechů",
  "decim",
  "decin",
  "dehtu",
  "dehty",
  "dehtů",
  "dejme",
  "dejte",
  "deklu",
  "dekou",
  "dekuj",
  "dekád",
  "deltu",
  "delty",
  "deltě",
  "delší",
  "demou",
  "denis",
  "denně",
  "depem",
  "depku",
  "depky",
  "depot",
  "depou",
  "deptá",
  "depům",
  "dereš",
  "derou",
  "desce",
  "desek",
  "deset",
  "desku",
  "desky",
  "desná",
  "desné",
  "desný",
  "deviz",
  "devíz",
  "dečce",
  "deček",
  "dečku",
  "dečky",
  "dešná",
  "dešné",
  "dešný",
  "dešov",
  "deštů",
  "dešťů",
  "dhtml",
  "diaku",
  "diaky",
  "diaků",
  "diana",
  "diany",
  "dianě",
  "dietu",
  "diety",
  "dietě",
  "dikci",
  "dikcí",
  "dinas",
  "dinem",
  "dingo",
  "dingy",
  "diodu",
  "diody",
  "diodě",
  "disku",
  "disky",
  "disků",
  "ditin",
  "ditou",
  "divem",
  "divil",
  "divno",
  "divná",
  "divné",
  "divní",
  "divně",
  "divou",
  "divte",
  "divém",
  "divíc",
  "divím",
  "divíš",
  "divým",
  "divům",
  "diáře",
  "diáři",
  "diářů",
  "dlabe",
  "dlabu",
  "dlahu",
  "dlahy",
  "dlani",
  "dlaní",
  "dlaně",
  "dlaze",
  "dlela",
  "dlouh",
  "dluhu",
  "dluhy",
  "dluhů",
  "dluží",
  "dláta",
  "dlátu",
  "dláty",
  "dlátě",
  "dlíme",
  "dmula",
  "dmuly",
  "dnota",
  "dnová",
  "dnové",
  "dnový",
  "dnání",
  "dních",
  "dober",
  "dobil",
  "dobou",
  "dobra",
  "dobru",
  "dobry",
  "dobrá",
  "dobré",
  "dobám",
  "dobře",
  "dobří",
  "docel",
  "dodal",
  "dodav",
  "dodej",
  "dodou",
  "dodám",
  "dodán",
  "dodáš",
  "dodém",
  "dodým",
  "dogou",
  "dohod",
  "dohoz",
  "dohoň",
  "dohro",
  "dohru",
  "dohry",
  "dohře",
  "dojde",
  "dojdi",
  "dojen",
  "dojet",
  "dojez",
  "dojeď",
  "dojic",
  "dojil",
  "dojič",
  "dojme",
  "dojmy",
  "dojmů",
  "dojná",
  "dojné",
  "dojte",
  "dojím",
  "dojíš",
  "dokaž",
  "dokem",
  "doksy",
  "dokuř",
  "dokům",
  "dolaď",
  "dolec",
  "doler",
  "dolfi",
  "dolij",
  "dolil",
  "dolin",
  "dolič",
  "dolou",
  "dolož",
  "dolsů",
  "doluj",
  "domiř",
  "domku",
  "domky",
  "domků",
  "domou",
  "domov",
  "domyj",
  "domyl",
  "domyt",
  "domén",
  "doměř",
  "domře",
  "dones",
  "donič",
  "donou",
  "donuť",
  "dopij",
  "dopil",
  "dopiš",
  "doplň",
  "doptá",
  "dopuj",
  "dopěv",
  "dorou",
  "dortu",
  "dorty",
  "dortů",
  "doruč",
  "dosaď",
  "dosem",
  "dosuš",
  "doteď",
  "dotkl",
  "dotoč",
  "dotuj",
  "dotěž",
  "doufá",
  "doufé",
  "doufý",
  "douti",
  "doučí",
  "dovař",
  "dovaž",
  "doveď",
  "dovol",
  "dovrš",
  "dovát",
  "dovím",
  "dovíš",
  "dovča",
  "dovče",
  "dovču",
  "dozná",
  "dozní",
  "dozný",
  "dozví",
  "dočká",
  "dočte",
  "dočti",
  "dočtu",
  "dořeš",
  "dořin",
  "došku",
  "došky",
  "došků",
  "došla",
  "došli",
  "došlo",
  "došly",
  "došlá",
  "došlé",
  "došlý",
  "dožer",
  "dožij",
  "dožil",
  "dožít",
  "draci",
  "draga",
  "draho",
  "drahá",
  "drahé",
  "drahý",
  "draka",
  "draku",
  "draky",
  "draků",
  "drala",
  "drali",
  "dralo",
  "draly",
  "dranc",
  "dravá",
  "dravé",
  "draví",
  "dravě",
  "draze",
  "drazí",
  "dračí",
  "draže",
  "draží",
  "drbal",
  "drbat",
  "drben",
  "drbeš",
  "drbnu",
  "drbny",
  "drbně",
  "drbou",
  "drbům",
  "drcen",
  "drcám",
  "drdou",
  "dresu",
  "dresy",
  "dresů",
  "drhla",
  "drhli",
  "drhlo",
  "drhly",
  "drhne",
  "drhni",
  "drhnu",
  "drilu",
  "drime",
  "drive",
  "drncl",
  "drncá",
  "drnem",
  "drnčí",
  "droby",
  "drobí",
  "drogu",
  "drogy",
  "drolí",
  "drops",
  "dropt",
  "droze",
  "drsná",
  "drsné",
  "drsní",
  "drsně",
  "drtil",
  "drtič",
  "drtím",
  "druha",
  "druhu",
  "druhy",
  "druhá",
  "druhý",
  "druhů",
  "druzi",
  "družs",
  "druží",
  "drzou",
  "drzém",
  "drzým",
  "drába",
  "drábe",
  "drábi",
  "drábu",
  "dráby",
  "drábů",
  "dráhu",
  "dráhy",
  "drápu",
  "drápy",
  "drápů",
  "drásá",
  "drásé",
  "drásý",
  "drátu",
  "dráty",
  "drátě",
  "drátů",
  "dráze",
  "dráže",
  "drénu",
  "drény",
  "drúzy",
  "drťme",
  "drťte",
  "držav",
  "držbu",
  "držby",
  "držbě",
  "držce",
  "držeb",
  "držel",
  "držen",
  "držko",
  "držku",
  "držky",
  "držme",
  "držte",
  "držíc",
  "držím",
  "držíš",
  "duant",
  "dubem",
  "duben",
  "dubna",
  "dubnu",
  "dubou",
  "dubém",
  "dubým",
  "dubům",
  "ducha",
  "duchy",
  "duchů",
  "dudci",
  "dudka",
  "dudku",
  "dudky",
  "dudků",
  "duelu",
  "duely",
  "duelů",
  "dueta",
  "duetu",
  "duety",
  "duhou",
  "dujou",
  "dukla",
  "dumal",
  "dumou",
  "dumám",
  "dumáš",
  "dunai",
  "dunaj",
  "dunou",
  "dunám",
  "duněl",
  "dupal",
  "dupej",
  "dupeš",
  "dupla",
  "duplo",
  "dupne",
  "dupou",
  "dupám",
  "dupáš",
  "dusal",
  "dusil",
  "dusme",
  "dusnu",
  "dusná",
  "dusné",
  "dusně",
  "dusou",
  "duste",
  "dusám",
  "dusán",
  "dusáš",
  "dusém",
  "dusím",
  "dusým",
  "dutin",
  "dutou",
  "dutém",
  "dutým",
  "duálu",
  "dušan",
  "dušno",
  "dušná",
  "dušné",
  "duším",
  "dužin",
  "dvb-c",
  "dvb-s",
  "dvb-t",
  "dvd-r",
  "dveří",
  "dvojí",
  "dvoří",
  "dvéře",
  "dvěmi",
  "dword",
  "dyjou",
  "dynam",
  "dynín",
  "dyšná",
  "dyšné",
  "dyšný",
  "dádou",
  "dálav",
  "dálce",
  "dálek",
  "dálku",
  "dálky",
  "dálná",
  "dálné",
  "dálou",
  "dámou",
  "dámám",
  "dánem",
  "dánky",
  "dáním",
  "dánům",
  "dánův",
  "dárce",
  "dárci",
  "dárců",
  "dárku",
  "dárky",
  "dárků",
  "dásni",
  "dásní",
  "dásně",
  "dátní",
  "dátou",
  "dával",
  "dávan",
  "dávce",
  "dávej",
  "dávek",
  "dávič",
  "dávku",
  "dávky",
  "dávno",
  "dávná",
  "dávné",
  "dávní",
  "dávný",
  "dávou",
  "dávám",
  "dáván",
  "dáváš",
  "dávém",
  "dávím",
  "dášin",
  "délce",
  "délek",
  "délko",
  "délku",
  "délky",
  "délou",
  "dílce",
  "dílci",
  "dílců",
  "dílen",
  "dílka",
  "dílku",
  "dílky",
  "dílků",
  "dílnu",
  "dílny",
  "dílná",
  "dílné",
  "dílný",
  "dílně",
  "dílou",
  "dílčí",
  "dílům",
  "dírce",
  "dírek",
  "dírku",
  "dírky",
  "dírná",
  "dírné",
  "dírný",
  "dírou",
  "dírám",
  "dítek",
  "dítka",
  "dítku",
  "dítky",
  "díval",
  "dívce",
  "dívej",
  "dívek",
  "dívko",
  "dívku",
  "dívky",
  "dívou",
  "dívám",
  "díváš",
  "dívém",
  "dívým",
  "dížím",
  "dómem",
  "dózou",
  "dýchl",
  "dýchá",
  "dýché",
  "dýchý",
  "dýhou",
  "dýhám",
  "dýkou",
  "dýkám",
  "dýmal",
  "dýmce",
  "dýmek",
  "dýmem",
  "dýmit",
  "dýmku",
  "dýmky",
  "dýmům",
  "dýním",
  "dýzou",
  "děcek",
  "děcka",
  "děcku",
  "dědci",
  "dědem",
  "dědic",
  "dědil",
  "dědič",
  "dědka",
  "dědku",
  "dědky",
  "dědků",
  "dědou",
  "dědům",
  "dědův",
  "dějem",
  "dějin",
  "dějou",
  "dějíc",
  "dějům",
  "děkuj",
  "dělaj",
  "dělal",
  "dělbu",
  "dělby",
  "dělbě",
  "dělej",
  "dělem",
  "dělen",
  "dělil",
  "dělme",
  "dělná",
  "dělné",
  "dělní",
  "děloh",
  "dělou",
  "dělte",
  "dělám",
  "dělán",
  "děláš",
  "dělím",
  "dělům",
  "děním",
  "děrná",
  "děrné",
  "děruj",
  "děsem",
  "děsil",
  "děsná",
  "děsné",
  "děsně",
  "děste",
  "děsím",
  "děsíš",
  "děvce",
  "děvek",
  "děvko",
  "děvku",
  "děvky",
  "děvou",
  "děvám",
  "děčín",
  "dřela",
  "dřeli",
  "dřelo",
  "dřely",
  "dřeme",
  "dřeni",
  "dřená",
  "dřené",
  "dřeně",
  "dřepl",
  "dřepu",
  "dřepy",
  "dřepí",
  "dřepů",
  "dřete",
  "dřeva",
  "dřevu",
  "dřevy",
  "dřevě",
  "dřezu",
  "dřezy",
  "dřezů",
  "dřinu",
  "dřiny",
  "dřině",
  "dřivé",
  "dříku",
  "dříky",
  "dříků",
  "dřímá",
  "dřínu",
  "dříny",
  "dřínů",
  "dřísy",
  "dříve",
  "dříče",
  "dříči",
  "dříčů",
  "dštil",
  "dštěl",
  "důlku",
  "důlky",
  "důlků",
  "důlně",
  "důlou",
  "důtce",
  "důtek",
  "důtku",
  "důvěr",
  "džemu",
  "džemy",
  "džemů",
  "džezu",
  "džina",
  "džusu",
  "džusy",
  "džusů",
  "džínů",
  "džípu",
  "džípy",
  "džípů",
  "ebolu",
  "eboly",
  "echem",
  "edení",
  "edici",
  "edicí",
  "edita",
  "editu",
  "edity",
  "edání",
  "edému",
  "efezu",
  "eftou",
  "egida",
  "egona",
  "egone",
  "egypt",
  "eidou",
  "ejich",
  "ejném",
  "elena",
  "elfem",
  "elfou",
  "elfům",
  "elips",
  "elite",
  "elitu",
  "elity",
  "elitě",
  "eliáš",
  "elánu",
  "emblá",
  "emblé",
  "emblý",
  "emila",
  "emile",
  "emilů",
  "eminu",
  "emisi",
  "emisí",
  "emoci",
  "emocí",
  "enter",
  "entit",
  "enumu",
  "epice",
  "epiku",
  "epiky",
  "epoch",
  "eposu",
  "eposy",
  "eposů",
  "epoše",
  "eprom",
  "epson",
  "erbem",
  "erben",
  "erbia",
  "erbiu",
  "erbií",
  "erbům",
  "erich",
  "erika",
  "eriku",
  "erozi",
  "erozí",
  "error",
  "ervin",
  "ervín",
  "eráru",
  "eráry",
  "erých",
  "esech",
  "eseje",
  "eseji",
  "esejí",
  "esejů",
  "eshop",
  "eskou",
  "esová",
  "esové",
  "etanu",
  "etapu",
  "etapy",
  "etapě",
  "etice",
  "etiku",
  "etiky",
  "etiků",
  "etnik",
  "etnou",
  "etudu",
  "etudy",
  "etudě",
  "etuje",
  "etuji",
  "etáže",
  "etáži",
  "etáží",
  "eulou",
  "eurem",
  "eurou",
  "eurům",
  "event",
  "evina",
  "evine",
  "evino",
  "eviny",
  "evině",
  "evkou",
  "evzen",
  "evčin",
  "evžen",
  "excel",
  "exifu",
  "exilu",
  "exodu",
  "exota",
  "exoti",
  "exoty",
  "exotů",
  "ezené",
  "ešusu",
  "facce",
  "facek",
  "facku",
  "facky",
  "fakta",
  "fakte",
  "faktu",
  "fakty",
  "faktů",
  "faldy",
  "falou",
  "falza",
  "fanda",
  "fandu",
  "fandy",
  "fandí",
  "fandů",
  "fanka",
  "fantu",
  "fanty",
  "fantů",
  "farce",
  "farem",
  "farmu",
  "farmy",
  "farmě",
  "farní",
  "farou",
  "farám",
  "fasou",
  "fasád",
  "fatou",
  "faulu",
  "fauly",
  "faulů",
  "faunu",
  "fauny",
  "fauně",
  "faust",
  "faxem",
  "faxuj",
  "faxům",
  "felix",
  "femto",
  "fence",
  "fenek",
  "fenku",
  "fenky",
  "fenou",
  "fenyl",
  "fenám",
  "ferda",
  "ferdy",
  "fetem",
  "fetuj",
  "fezem",
  "fezům",
  "fešná",
  "fešné",
  "feťák",
  "fialo",
  "fialu",
  "fialy",
  "fiata",
  "fiatu",
  "fidži",
  "fifou",
  "figur",
  "fikci",
  "fikcí",
  "filek",
  "files",
  "filip",
  "filmu",
  "filmy",
  "filmů",
  "finan",
  "finek",
  "finem",
  "finka",
  "finku",
  "finky",
  "finou",
  "fintu",
  "finty",
  "fintě",
  "finum",
  "finuv",
  "finům",
  "finův",
  "fiona",
  "firem",
  "firmu",
  "firmy",
  "firmě",
  "firnu",
  "firny",
  "fitka",
  "fitko",
  "fitku",
  "fixek",
  "fixem",
  "fixně",
  "fixou",
  "flash",
  "fleku",
  "fleky",
  "fleků",
  "flikr",
  "floka",
  "floky",
  "flopu",
  "fluid",
  "fluše",
  "flákl",
  "fláká",
  "fláké",
  "fláký",
  "fláků",
  "flámu",
  "flámy",
  "flámů",
  "flóru",
  "flóry",
  "flóře",
  "fobii",
  "fobií",
  "focen",
  "fofru",
  "fojta",
  "fojtů",
  "folku",
  "fondy",
  "fondů",
  "fontu",
  "fonty",
  "fontů",
  "forem",
  "formu",
  "formy",
  "fotce",
  "fotek",
  "fotil",
  "fotku",
  "fotky",
  "fotou",
  "fotím",
  "fotíš",
  "fotři",
  "foukl",
  "fouká",
  "fouké",
  "fouký",
  "fouňa",
  "fošny",
  "foťte",
  "fraku",
  "fraky",
  "fraků",
  "franz",
  "frgál",
  "fritz",
  "frkal",
  "frmol",
  "front",
  "frázi",
  "frází",
  "fráňa",
  "fréze",
  "frézu",
  "frézy",
  "frída",
  "frčky",
  "frčím",
  "frťan",
  "fugou",
  "fugám",
  "fujar",
  "fukcí",
  "funím",
  "funíš",
  "funěl",
  "futra",
  "futur",
  "fušce",
  "fušku",
  "fušuj",
  "fádně",
  "fámou",
  "fámám",
  "fáral",
  "fárou",
  "fárám",
  "fárém",
  "fárým",
  "fárům",
  "fátou",
  "fátum",
  "fázím",
  "fémou",
  "fénem",
  "fígle",
  "fíglu",
  "fígly",
  "fíglů",
  "fíkem",
  "fíkům",
  "fízlů",
  "fólii",
  "fólií",
  "fórek",
  "fórem",
  "fórky",
  "fórků",
  "fórou",
  "fórum",
  "fórům",
  "fúzní",
  "fúzím",
  "fňuká",
  "fňuké",
  "fňuký",
  "fůrou",
  "fůzím",
  "gabon",
  "galia",
  "galie",
  "galiu",
  "galér",
  "gamba",
  "gamet",
  "gamma",
  "gamou",
  "ganga",
  "gangu",
  "gangy",
  "gangů",
  "gardu",
  "gardy",
  "gardě",
  "gates",
  "gatím",
  "gauss",
  "gauče",
  "gauči",
  "gaučů",
  "gayem",
  "gayům",
  "gayův",
  "gazel",
  "gejši",
  "gejšu",
  "gelem",
  "gelům",
  "genem",
  "genia",
  "genií",
  "genou",
  "genům",
  "geoid",
  "gerta",
  "gesci",
  "gesta",
  "geste",
  "gestu",
  "gesty",
  "gestě",
  "ghana",
  "ghanu",
  "ghany",
  "ghaně",
  "gheto",
  "ghett",
  "gigou",
  "gilda",
  "gitin",
  "gitou",
  "glanc",
  "globe",
  "glose",
  "glosu",
  "glosy",
  "glóbu",
  "glóby",
  "glóbů",
  "golem",
  "golet",
  "golfu",
  "golfů",
  "golou",
  "gongu",
  "gongy",
  "gongů",
  "goril",
  "grada",
  "grade",
  "grady",
  "grafe",
  "grafu",
  "grafy",
  "grafů",
  "gramu",
  "gramy",
  "gramů",
  "grand",
  "grepu",
  "grepy",
  "grift",
  "grify",
  "grifů",
  "grilu",
  "grily",
  "grilů",
  "grogu",
  "groše",
  "groši",
  "grošů",
  "grupu",
  "grupy",
  "grupě",
  "grády",
  "grálu",
  "grázl",
  "grófy",
  "guano",
  "gulou",
  "gumou",
  "gumuj",
  "gusta",
  "gustu",
  "gypsu",
  "gázou",
  "génia",
  "génii",
  "géniu",
  "géniů",
  "géček",
  "gólem",
  "gólou",
  "gólům",
  "habru",
  "habry",
  "habrů",
  "hacku",
  "hacky",
  "hadem",
  "hadic",
  "hadou",
  "hadru",
  "hadry",
  "hadrů",
  "hadím",
  "hadům",
  "hadův",
  "hadži",
  "hahou",
  "haiti",
  "hajná",
  "hajné",
  "hajní",
  "hajte",
  "haldu",
  "haldy",
  "haldě",
  "halil",
  "halič",
  "halou",
  "halám",
  "halíš",
  "halže",
  "hamru",
  "hamry",
  "hamrů",
  "hanbo",
  "hanbu",
  "hanby",
  "hanbě",
  "hanil",
  "hanin",
  "hanka",
  "hanko",
  "hanku",
  "hanky",
  "hanoj",
  "hanou",
  "hanuš",
  "haním",
  "haníš",
  "haněl",
  "haněn",
  "hanět",
  "harfu",
  "harfy",
  "harfě",
  "hasek",
  "hasil",
  "hasiv",
  "haska",
  "hasly",
  "hasme",
  "hasne",
  "hasím",
  "hasíš",
  "havaj",
  "havel",
  "havěť",
  "hašen",
  "hbity",
  "hbitá",
  "hbité",
  "hbití",
  "hbitě",
  "hebká",
  "hebké",
  "hebčí",
  "hejna",
  "hejno",
  "hejnu",
  "hejny",
  "hejně",
  "hekal",
  "hekla",
  "hekli",
  "heklo",
  "hekly",
  "hekto",
  "hekám",
  "helem",
  "heleď",
  "helia",
  "helin",
  "heliu",
  "helií",
  "helmu",
  "helmy",
  "helmě",
  "helou",
  "helpu",
  "helpy",
  "hemží",
  "hepou",
  "herce",
  "herci",
  "herců",
  "herec",
  "heren",
  "hernu",
  "herny",
  "herně",
  "herče",
  "hesel",
  "hesla",
  "hesle",
  "heslu",
  "hesly",
  "hezcí",
  "hezky",
  "hezká",
  "hezké",
  "hezký",
  "hezčí",
  "hihňá",
  "hihňé",
  "hihňý",
  "hijou",
  "hitem",
  "hitům",
  "hladu",
  "hladí",
  "hlase",
  "hlasu",
  "hlasy",
  "hlasů",
  "hlavo",
  "hlavu",
  "hlavy",
  "hlavě",
  "hledu",
  "hledá",
  "hledé",
  "hledí",
  "hledý",
  "hledě",
  "hlenu",
  "hleny",
  "hlenů",
  "hlesl",
  "hlesu",
  "hlodá",
  "hlodé",
  "hlodý",
  "hlohu",
  "hlohy",
  "hlohů",
  "hltal",
  "hltač",
  "hltem",
  "hltná",
  "hltné",
  "hltou",
  "hltám",
  "hltán",
  "hltáš",
  "hltém",
  "hltým",
  "hltům",
  "hluku",
  "hluky",
  "hluků",
  "hlučí",
  "hluše",
  "hluší",
  "hlásá",
  "hlásé",
  "hlásí",
  "hlásý",
  "hlédl",
  "hlídá",
  "hlídé",
  "hlídý",
  "hlínu",
  "hlíny",
  "hlíně",
  "hlívu",
  "hlívy",
  "hlívě",
  "hlíze",
  "hlízu",
  "hlízy",
  "hmatu",
  "hmaty",
  "hmatá",
  "hmatů",
  "hmity",
  "hmotu",
  "hmoty",
  "hmotě",
  "hmyzí",
  "hmátl",
  "hnala",
  "hnali",
  "hnalo",
  "hnaly",
  "hnaná",
  "hnané",
  "hneme",
  "hnete",
  "hnidy",
  "hnije",
  "hniji",
  "hnijí",
  "hnila",
  "hnilo",
  "hnily",
  "hnisu",
  "hnisá",
  "hnisé",
  "hnisý",
  "hnojí",
  "hnula",
  "hnuli",
  "hnulo",
  "hnuly",
  "hnusu",
  "hnusy",
  "hnusí",
  "hnusů",
  "hnutá",
  "hnuté",
  "hnána",
  "hnáni",
  "hnáno",
  "hnány",
  "hnáty",
  "hnátů",
  "hnízd",
  "hněda",
  "hnědi",
  "hnědl",
  "hnědá",
  "hnědé",
  "hnědí",
  "hnědě",
  "hněme",
  "hněte",
  "hnětl",
  "hněvu",
  "hněvy",
  "hněvá",
  "hněvé",
  "hněvý",
  "hoaxu",
  "hoaxy",
  "hoaxů",
  "hobzí",
  "hobře",
  "hocha",
  "hochu",
  "hochy",
  "hochů",
  "hodem",
  "hoden",
  "hodil",
  "hodin",
  "hodlá",
  "hodlé",
  "hodlý",
  "hodna",
  "hodni",
  "hodno",
  "hodny",
  "hodná",
  "hodné",
  "hodní",
  "hodov",
  "hodím",
  "hodíš",
  "hodům",
  "hojil",
  "hojná",
  "hojné",
  "hojní",
  "hojně",
  "hojím",
  "hojíš",
  "holce",
  "holdu",
  "holej",
  "holek",
  "holic",
  "holil",
  "holin",
  "holku",
  "holky",
  "holme",
  "holou",
  "holte",
  "holém",
  "holíš",
  "holým",
  "homer",
  "homér",
  "honbu",
  "honby",
  "honbě",
  "honci",
  "honda",
  "honil",
  "honor",
  "honos",
  "honza",
  "honzo",
  "honzu",
  "honzy",
  "honzů",
  "honím",
  "honíš",
  "honěn",
  "honům",
  "hopem",
  "hopká",
  "hopké",
  "hopký",
  "hopsá",
  "hopsé",
  "hopsý",
  "horal",
  "horce",
  "hordu",
  "hordy",
  "hordě",
  "horek",
  "horku",
  "horká",
  "horké",
  "horlí",
  "horne",
  "horně",
  "horou",
  "horuj",
  "horám",
  "horší",
  "hosta",
  "hoste",
  "hosti",
  "hostu",
  "hosty",
  "hosté",
  "hostů",
  "houbu",
  "houby",
  "houbě",
  "houfu",
  "houfy",
  "houkl",
  "houká",
  "houké",
  "houký",
  "houpe",
  "houpu",
  "houpá",
  "houpé",
  "houpý",
  "houšť",
  "hovna",
  "hovny",
  "hovoř",
  "hovím",
  "hovíš",
  "hověj",
  "hověl",
  "hovět",
  "hozen",
  "hoďku",
  "hoďky",
  "hoďme",
  "hoďte",
  "hoňte",
  "hořce",
  "hořci",
  "hořců",
  "hořel",
  "hořem",
  "hořen",
  "hořic",
  "hořkl",
  "hořká",
  "hořké",
  "hořím",
  "hoříš",
  "hrabe",
  "hrabu",
  "hradu",
  "hrady",
  "hradí",
  "hradě",
  "hradů",
  "hraje",
  "hraji",
  "hraju",
  "hrají",
  "hrami",
  "hranu",
  "hrany",
  "hraná",
  "hrané",
  "hraně",
  "hravá",
  "hravé",
  "hraví",
  "hravě",
  "hrbem",
  "hrbil",
  "hrbím",
  "hrbíš",
  "hrbům",
  "hrdel",
  "hrdla",
  "hrdle",
  "hrdlu",
  "hrdly",
  "hrdou",
  "hrdém",
  "hrdým",
  "hriba",
  "hrkla",
  "hrklo",
  "hrkou",
  "hrkém",
  "hrkým",
  "hrmou",
  "hrnce",
  "hrnci",
  "hrnců",
  "hrnku",
  "hrnky",
  "hrnků",
  "hrnou",
  "hrnul",
  "hrobu",
  "hroby",
  "hrobě",
  "hrobů",
  "hromu",
  "hromy",
  "hromů",
  "hrotu",
  "hroty",
  "hrotí",
  "hrotů",
  "hrozí",
  "hroši",
  "hroší",
  "hrsti",
  "hrstí",
  "hrubá",
  "hrubé",
  "hrubí",
  "hrubě",
  "hrudi",
  "hrudí",
  "hryzl",
  "hryže",
  "hrábl",
  "hrábí",
  "hrála",
  "hráli",
  "hrálo",
  "hrály",
  "hrána",
  "hráno",
  "hrány",
  "hráze",
  "hrázi",
  "hrází",
  "hráče",
  "hráči",
  "hráčů",
  "hrůze",
  "hrůzo",
  "hrůzu",
  "hrůzy",
  "hsdpa",
  "https",
  "hubač",
  "hubce",
  "hubek",
  "hubem",
  "huben",
  "hubic",
  "hubil",
  "hubič",
  "hubku",
  "hubky",
  "hubla",
  "hubme",
  "hubne",
  "hubnu",
  "hubou",
  "hubte",
  "hubím",
  "hubíš",
  "hudbu",
  "hudby",
  "hudbě",
  "hudci",
  "hudeb",
  "hugem",
  "hugou",
  "hugův",
  "hulil",
  "hulím",
  "hulín",
  "hulíš",
  "humna",
  "humnu",
  "humny",
  "humně",
  "humra",
  "humrů",
  "humři",
  "humří",
  "hupla",
  "hupne",
  "hupsl",
  "hurou",
  "hurém",
  "hurým",
  "husle",
  "husou",
  "husto",
  "hustá",
  "husté",
  "hustí",
  "hustý",
  "hustě",
  "husám",
  "husím",
  "hutná",
  "hutné",
  "hutní",
  "hutně",
  "hutím",
  "hučel",
  "hučku",
  "hučím",
  "hučíš",
  "huňáč",
  "hušek",
  "huťmi",
  "hvězd",
  "hybná",
  "hybné",
  "hydou",
  "hydru",
  "hydry",
  "hydře",
  "hyeno",
  "hyenu",
  "hyeny",
  "hyeně",
  "hyjou",
  "hylná",
  "hylné",
  "hylný",
  "hymen",
  "hymnu",
  "hymny",
  "hymně",
  "hynek",
  "hyneš",
  "hynka",
  "hynku",
  "hynou",
  "hynul",
  "hyper",
  "hyzdí",
  "hyřme",
  "hyřte",
  "hádal",
  "hádce",
  "hádej",
  "hádku",
  "hádky",
  "hádou",
  "hádám",
  "hádán",
  "hádáš",
  "hájem",
  "hájen",
  "hájil",
  "hájou",
  "hájím",
  "hájíš",
  "hájům",
  "hákem",
  "hákům",
  "háral",
  "hárem",
  "hávem",
  "házej",
  "házel",
  "házen",
  "házet",
  "házím",
  "házíš",
  "háček",
  "háčku",
  "háčky",
  "háčků",
  "hážou",
  "hélia",
  "héliu",
  "hýbal",
  "hýbat",
  "hýbej",
  "hýbeš",
  "hýbou",
  "hýbám",
  "hýbém",
  "hýbým",
  "hýkal",
  "hýkej",
  "hýkám",
  "hýkáš",
  "hýlem",
  "hýlou",
  "hýlům",
  "hýlův",
  "hýsek",
  "hýska",
  "hýčká",
  "hýčké",
  "hýčký",
  "hýřil",
  "hýříc",
  "hýřím",
  "hýříš",
  "hýždí",
  "hňupa",
  "hňupe",
  "hňupy",
  "hňupů",
  "hřadu",
  "hřadě",
  "hřebu",
  "hřeby",
  "hřebí",
  "hřebů",
  "hřeje",
  "hřeji",
  "hřejí",
  "hřeší",
  "hřibu",
  "hřiby",
  "hřibů",
  "hřišť",
  "hřměl",
  "hřála",
  "hřáli",
  "hřálo",
  "hřály",
  "hřímá",
  "hřívu",
  "hřívy",
  "hřívě",
  "hůlce",
  "hůlek",
  "hůlku",
  "hůlky",
  "hůrce",
  "hůrek",
  "ibiza",
  "ictví",
  "ideou",
  "ideám",
  "idnes",
  "idola",
  "idolu",
  "idoly",
  "idolů",
  "idyle",
  "idylu",
  "idyly",
  "igmac",
  "ignác",
  "igora",
  "igore",
  "ihůtě",
  "ikonu",
  "ikony",
  "ikoně",
  "ikání",
  "iljou",
  "iljův",
  "ilona",
  "ilono",
  "ilonu",
  "ilony",
  "iloně",
  "ilusi",
  "iluzi",
  "iluzí",
  "imbus",
  "imisi",
  "imisí",
  "imkou",
  "imága",
  "imágo",
  "imágu",
  "imágy",
  "inami",
  "incká",
  "incké",
  "incký",
  "indce",
  "indek",
  "indem",
  "india",
  "indie",
  "indig",
  "indii",
  "indiu",
  "indií",
  "indka",
  "indku",
  "indky",
  "indos",
  "indoš",
  "indům",
  "inhal",
  "inkas",
  "inkou",
  "inová",
  "inové",
  "inový",
  "intel",
  "invex",
  "inému",
  "iontu",
  "ionty",
  "iontů",
  "iotou",
  "ipadu",
  "ipady",
  "ipadů",
  "ipodu",
  "ipody",
  "irech",
  "ireno",
  "ireny",
  "ireně",
  "irmin",
  "irmou",
  "ironů",
  "irové",
  "irska",
  "irsko",
  "irsku",
  "irsky",
  "irská",
  "irské",
  "iráku",
  "irčin",
  "irští",
  "itala",
  "itale",
  "italu",
  "italy",
  "italů",
  "itina",
  "itálu",
  "ivana",
  "ivane",
  "ivano",
  "ivanu",
  "ivany",
  "ivaně",
  "iveta",
  "iveto",
  "ivetu",
  "ivety",
  "ivetě",
  "ivkou",
  "ivona",
  "ivoně",
  "ivčin",
  "izího",
  "jahod",
  "jahve",
  "jakej",
  "jakou",
  "jakub",
  "jakém",
  "jakým",
  "jamce",
  "jamek",
  "jamku",
  "jamky",
  "janem",
  "janin",
  "janka",
  "janku",
  "janou",
  "janov",
  "januš",
  "janík",
  "janča",
  "jančo",
  "janův",
  "jarda",
  "jardy",
  "jarem",
  "jarko",
  "jarky",
  "jarně",
  "jarou",
  "jarča",
  "jarům",
  "jasem",
  "jasno",
  "jasná",
  "jasné",
  "jasní",
  "jasně",
  "jasoň",
  "jasům",
  "jatek",
  "jatou",
  "jatém",
  "jatým",
  "javou",
  "jazyk",
  "jazzu",
  "jařin",
  "jdeme",
  "jdete",
  "jdouc",
  "jděme",
  "jděte",
  "je-li",
  "jedem",
  "jeden",
  "jedeš",
  "jedla",
  "jedli",
  "jedlo",
  "jedly",
  "jedlá",
  "jedlé",
  "jedlí",
  "jedna",
  "jedni",
  "jedno",
  "jednu",
  "jedny",
  "jedná",
  "jedné",
  "jedou",
  "jedům",
  "jehel",
  "jehle",
  "jehlo",
  "jehlu",
  "jehly",
  "jejiž",
  "jejou",
  "jejím",
  "jekem",
  "jelce",
  "jelci",
  "jelců",
  "jelit",
  "jemen",
  "jemno",
  "jemná",
  "jemné",
  "jemně",
  "jenda",
  "jenem",
  "jeneč",
  "jenik",
  "jenom",
  "jenou",
  "jeník",
  "jepic",
  "jeslí",
  "jetou",
  "jetém",
  "jetým",
  "jevem",
  "jevil",
  "jevím",
  "jevíš",
  "jevům",
  "jezce",
  "jezde",
  "jezdi",
  "jezdí",
  "jezdů",
  "jezem",
  "jezer",
  "jezme",
  "jezte",
  "jezům",
  "ječel",
  "ječná",
  "ječné",
  "ječte",
  "ječím",
  "ječíš",
  "jeďme",
  "jeďte",
  "ježci",
  "ježil",
  "ježka",
  "ježky",
  "ježků",
  "ježov",
  "ježím",
  "ježíš",
  "ježčí",
  "jicin",
  "jidáš",
  "jihem",
  "jihlo",
  "jihly",
  "jihne",
  "jihnu",
  "jiker",
  "jikru",
  "jikry",
  "jikře",
  "jiljí",
  "jilmu",
  "jilmy",
  "jilmů",
  "jinak",
  "jince",
  "jinou",
  "jinud",
  "jiném",
  "jiným",
  "jinší",
  "jirka",
  "jirko",
  "jirku",
  "jirky",
  "jirků",
  "jirny",
  "jista",
  "jisti",
  "jisto",
  "jisty",
  "jistá",
  "jisté",
  "jistí",
  "jistý",
  "jitce",
  "jiter",
  "jitka",
  "jitko",
  "jitku",
  "jitky",
  "jitra",
  "jitru",
  "jitry",
  "jitří",
  "jizbu",
  "jizbě",
  "jizev",
  "jizvu",
  "jizvy",
  "jizvě",
  "jičín",
  "jiřin",
  "jiřka",
  "jiřík",
  "jiřím",
  "jižan",
  "jižně",
  "jmeme",
  "jména",
  "jméno",
  "jménu",
  "jmény",
  "jméně",
  "johan",
  "jojem",
  "jolou",
  "jonáš",
  "josef",
  "jotou",
  "joulu",
  "jouly",
  "joulů",
  "jozef",
  "jožka",
  "jpegu",
  "jsouc",
  "jstou",
  "juchú",
  "juchý",
  "judem",
  "judou",
  "julie",
  "julii",
  "julií",
  "julka",
  "junou",
  "junty",
  "juraj",
  "jurou",
  "jurtu",
  "jurty",
  "jurtě",
  "jutou",
  "jádra",
  "jádro",
  "jádru",
  "jádry",
  "jádře",
  "jáhel",
  "jáhly",
  "jákob",
  "jámou",
  "jámám",
  "jánka",
  "járou",
  "járův",
  "jásal",
  "jásej",
  "jásou",
  "jásám",
  "jásém",
  "jásým",
  "játra",
  "játry",
  "jávou",
  "jéjou",
  "jéčka",
  "jícnu",
  "jícny",
  "jícnů",
  "jícím",
  "jídal",
  "jídel",
  "jídla",
  "jídle",
  "jídlu",
  "jídly",
  "jídáš",
  "jílce",
  "jílci",
  "jílců",
  "jílem",
  "jímal",
  "jímce",
  "jímek",
  "jímku",
  "jímky",
  "jímán",
  "jíním",
  "jírou",
  "jítou",
  "jívou",
  "jízdu",
  "jízdy",
  "jízdě",
  "jízek",
  "jíšce",
  "jíšku",
  "jíšky",
  "jódem",
  "jógou",
  "jěhož",
  "kabin",
  "kacou",
  "kadaň",
  "kadit",
  "kadov",
  "kafem",
  "kafru",
  "kajek",
  "kaješ",
  "kajku",
  "kajky",
  "kajme",
  "kajte",
  "kajut",
  "kajčí",
  "kakaa",
  "kakai",
  "kakau",
  "kakáš",
  "kaldo",
  "kalek",
  "kalem",
  "kalen",
  "kalif",
  "kalil",
  "kalku",
  "kalná",
  "kalné",
  "kalou",
  "kalám",
  "kalím",
  "kalíš",
  "kalům",
  "kamer",
  "kamil",
  "kampa",
  "kampy",
  "kamýk",
  "kance",
  "kanci",
  "kanců",
  "kanou",
  "kanoí",
  "kanta",
  "kanul",
  "kančí",
  "kaněk",
  "kapal",
  "kapce",
  "kapek",
  "kapel",
  "kapii",
  "kapií",
  "kapku",
  "kapky",
  "kapká",
  "kapké",
  "kapký",
  "kapli",
  "kaplí",
  "kapot",
  "kapou",
  "kappa",
  "kapra",
  "kapru",
  "kapry",
  "kaprů",
  "kapse",
  "kapsu",
  "kapsy",
  "kaput",
  "kapře",
  "kapři",
  "kapří",
  "karel",
  "karem",
  "karin",
  "karla",
  "karle",
  "karlo",
  "karlu",
  "karly",
  "karmu",
  "karmy",
  "karmě",
  "kartu",
  "karty",
  "kartě",
  "kasal",
  "kasin",
  "kasou",
  "kasto",
  "kasty",
  "kastě",
  "kasám",
  "katce",
  "katem",
  "katka",
  "katko",
  "katku",
  "katky",
  "katod",
  "katou",
  "katům",
  "katův",
  "kauci",
  "kaucí",
  "kauze",
  "kauzu",
  "kauzy",
  "kavce",
  "kavek",
  "kavku",
  "kavky",
  "kavčí",
  "kazaa",
  "kazem",
  "kazet",
  "kazil",
  "kazin",
  "kazím",
  "kazíš",
  "kazům",
  "kačce",
  "kaček",
  "kačku",
  "kačky",
  "kaňku",
  "kaňky",
  "kařez",
  "kašen",
  "kašlu",
  "kašnu",
  "kašny",
  "kašně",
  "každá",
  "každé",
  "každý",
  "kbaud",
  "kbyte",
  "kdeco",
  "kdečí",
  "kdože",
  "kdyně",
  "kdáká",
  "kecal",
  "kecky",
  "kecou",
  "kecám",
  "kecáš",
  "kecém",
  "kecým",
  "kecům",
  "keksy",
  "kelta",
  "kelte",
  "keltu",
  "kelty",
  "keltů",
  "kempu",
  "kempy",
  "kempů",
  "kemrů",
  "keňan",
  "keřem",
  "keřům",
  "keťas",
  "kibic",
  "kilem",
  "kiltu",
  "kilty",
  "kilům",
  "kimon",
  "kinem",
  "kinou",
  "kinům",
  "kitem",
  "kitům",
  "klade",
  "kladl",
  "kladu",
  "klady",
  "kladů",
  "klame",
  "klamu",
  "klamy",
  "klamů",
  "klanu",
  "klany",
  "klaní",
  "klanů",
  "klape",
  "klapl",
  "klapá",
  "klapé",
  "klapý",
  "klare",
  "klase",
  "klasu",
  "klasy",
  "klasů",
  "klatá",
  "klaté",
  "klatě",
  "klaus",
  "klece",
  "klech",
  "kleci",
  "klecí",
  "kleje",
  "klejt",
  "klejí",
  "klejů",
  "klekl",
  "kleká",
  "klela",
  "kleli",
  "klelo",
  "klely",
  "klene",
  "kleny",
  "klepe",
  "klepl",
  "klepu",
  "klepy",
  "klepů",
  "klesl",
  "klesá",
  "klesé",
  "klesý",
  "kletá",
  "kleté",
  "kleče",
  "klečí",
  "klice",
  "klidu",
  "klidí",
  "klihu",
  "klikl",
  "kliku",
  "kliky",
  "kliká",
  "kliké",
  "kliký",
  "kliků",
  "klipu",
  "klipy",
  "klipů",
  "klkům",
  "kloak",
  "klonu",
  "klony",
  "kloní",
  "klonů",
  "klopu",
  "klopy",
  "klopí",
  "klopě",
  "klove",
  "klová",
  "klové",
  "klube",
  "kluby",
  "klubů",
  "kluci",
  "kluka",
  "kluku",
  "kluky",
  "kluků",
  "klusa",
  "klusu",
  "klusá",
  "kluzu",
  "kluše",
  "klušu",
  "kládu",
  "klády",
  "kládě",
  "klára",
  "kláro",
  "kláru",
  "kláry",
  "klátí",
  "kláře",
  "klého",
  "klému",
  "kléru",
  "klíma",
  "klína",
  "klínu",
  "klíny",
  "klíně",
  "klínů",
  "klíti",
  "klíče",
  "klíči",
  "klíčí",
  "klíčů",
  "klíží",
  "klých",
  "klýma",
  "klými",
  "kmene",
  "kmeni",
  "kment",
  "kmenu",
  "kmeny",
  "kmenů",
  "kmeta",
  "kmete",
  "kmeti",
  "kmety",
  "kmetů",
  "kmitl",
  "kmitu",
  "kmity",
  "kmitá",
  "kmité",
  "kmitý",
  "kmitů",
  "kmoch",
  "kmána",
  "kmáne",
  "kmáni",
  "kmány",
  "kmínu",
  "knaur",
  "kneží",
  "kniho",
  "knihu",
  "knihy",
  "knize",
  "knotu",
  "knoty",
  "knotů",
  "knuty",
  "kníru",
  "kníry",
  "knírů",
  "knězů",
  "koala",
  "koale",
  "koalo",
  "koalu",
  "koaly",
  "koalů",
  "kobce",
  "kobek",
  "kober",
  "kobku",
  "kobky",
  "kobru",
  "kobry",
  "kobyl",
  "kobza",
  "kobře",
  "kochá",
  "koché",
  "kochý",
  "kodak",
  "kodaň",
  "kojen",
  "kojil",
  "kojme",
  "kojná",
  "kojné",
  "kojný",
  "kojte",
  "kojím",
  "kojíš",
  "koket",
  "kokil",
  "kokot",
  "kokou",
  "koksu",
  "kokta",
  "koktu",
  "koktá",
  "kokté",
  "koktý",
  "koled",
  "kolen",
  "koles",
  "koleč",
  "kolka",
  "kolku",
  "kolky",
  "kolků",
  "kolmo",
  "kolmá",
  "kolmé",
  "kolmě",
  "kolno",
  "kolnu",
  "kolny",
  "kolně",
  "kolon",
  "kolou",
  "koltu",
  "kolty",
  "koltů",
  "koluj",
  "kolín",
  "kolům",
  "komet",
  "komis",
  "komod",
  "komor",
  "komou",
  "komoň",
  "kompu",
  "kompy",
  "komun",
  "komže",
  "konal",
  "konců",
  "konej",
  "konga",
  "kongo",
  "kongu",
  "konou",
  "konta",
  "kontu",
  "konty",
  "kontě",
  "konve",
  "konvi",
  "konví",
  "konám",
  "konán",
  "konáš",
  "koném",
  "koným",
  "konči",
  "končí",
  "kopal",
  "kopat",
  "kopce",
  "kopci",
  "kopců",
  "kopej",
  "kopem",
  "kopeš",
  "kopii",
  "kopií",
  "kopky",
  "kopla",
  "kopli",
  "koplo",
  "koply",
  "kopne",
  "kopni",
  "kopnu",
  "kopná",
  "kopné",
  "kopou",
  "kopru",
  "kopry",
  "kopti",
  "koptu",
  "kopty",
  "koptů",
  "kopyt",
  "kopám",
  "kopán",
  "kopém",
  "kopím",
  "kopým",
  "kopům",
  "korbu",
  "korby",
  "korbě",
  "kordu",
  "kordy",
  "kordů",
  "korea",
  "koreb",
  "korei",
  "koreu",
  "korey",
  "korgo",
  "korku",
  "korse",
  "korun",
  "koryt",
  "korze",
  "korón",
  "kosem",
  "kosic",
  "kosil",
  "kosma",
  "kosou",
  "kosti",
  "kostí",
  "kosám",
  "kosém",
  "kosím",
  "kosíš",
  "kosým",
  "kosům",
  "kosův",
  "kotce",
  "kotci",
  "kotců",
  "kotev",
  "kotvu",
  "kotvy",
  "kotví",
  "kotvě",
  "koukl",
  "kouká",
  "kouli",
  "koulí",
  "koumá",
  "koupe",
  "koupi",
  "koupu",
  "koupí",
  "kousl",
  "koutu",
  "kouče",
  "kouči",
  "koučů",
  "kouře",
  "kouři",
  "kouří",
  "kouřů",
  "kouše",
  "koval",
  "kovat",
  "kovem",
  "kovou",
  "kovov",
  "kován",
  "kováč",
  "kovům",
  "kozin",
  "kozla",
  "kozly",
  "kozlí",
  "kozlů",
  "kozou",
  "kozák",
  "kozám",
  "kozím",
  "kočce",
  "koček",
  "kočko",
  "kočku",
  "kočky",
  "kočím",
  "koňmi",
  "koňmo",
  "koňům",
  "koňův",
  "kořal",
  "košem",
  "košic",
  "košil",
  "koštů",
  "košár",
  "košům",
  "koťat",
  "kožař",
  "kožek",
  "kožku",
  "kožky",
  "kožlí",
  "kraba",
  "krabi",
  "kraby",
  "krabí",
  "krabů",
  "krade",
  "kradl",
  "kradu",
  "kraje",
  "kraji",
  "krajt",
  "krajů",
  "krami",
  "krasu",
  "krasy",
  "krasů",
  "kraví",
  "krbem",
  "krbům",
  "kreml",
  "krepy",
  "krepů",
  "kripl",
  "krizi",
  "krizí",
  "krkem",
  "krkám",
  "krkům",
  "krmen",
  "krmil",
  "krmiv",
  "krmme",
  "krmná",
  "krmné",
  "krmte",
  "krmím",
  "krmíš",
  "krnov",
  "krnět",
  "kroje",
  "kroji",
  "krojů",
  "kroku",
  "kroky",
  "kroků",
  "kromé",
  "kropí",
  "krosu",
  "krosy",
  "krotí",
  "krovu",
  "krovy",
  "krovů",
  "krsek",
  "krtci",
  "krtil",
  "krtka",
  "krtky",
  "krtků",
  "krtčí",
  "kruhu",
  "kruhy",
  "kruhů",
  "krupá",
  "krupé",
  "krupý",
  "krutu",
  "kruty",
  "krutá",
  "kruté",
  "krutí",
  "krutě",
  "kručí",
  "krycí",
  "kryje",
  "kryji",
  "kryju",
  "kryjí",
  "kryla",
  "kryli",
  "krylo",
  "kryly",
  "krymu",
  "krypt",
  "kryry",
  "kryse",
  "kryso",
  "krysu",
  "krysy",
  "krysí",
  "kryta",
  "kryti",
  "kryto",
  "krytu",
  "kryty",
  "krytá",
  "kryté",
  "krytů",
  "krách",
  "krájí",
  "kráká",
  "kráké",
  "kráký",
  "krále",
  "králi",
  "králů",
  "krámu",
  "krámy",
  "krámě",
  "krámů",
  "krápe",
  "kráse",
  "krásu",
  "krásy",
  "krátí",
  "krávo",
  "krávu",
  "krávy",
  "krávě",
  "kráčí",
  "kréda",
  "krédu",
  "krédy",
  "krédě",
  "krého",
  "krému",
  "krémy",
  "krémů",
  "kréta",
  "krétu",
  "kréty",
  "krétě",
  "krčem",
  "krčil",
  "krčku",
  "krčky",
  "krčků",
  "krčme",
  "krčmu",
  "krčmy",
  "krčmě",
  "krčte",
  "krčíc",
  "krčím",
  "krčíš",
  "krůtu",
  "krůty",
  "krůtí",
  "která",
  "které",
  "který",
  "kteří",
  "kubou",
  "kubus",
  "kubův",
  "kudlu",
  "kudly",
  "kufru",
  "kufry",
  "kufrů",
  "kuješ",
  "kujme",
  "kujná",
  "kujné",
  "kujou",
  "kujón",
  "kukal",
  "kukel",
  "kukle",
  "kuklu",
  "kukly",
  "kuksu",
  "kukám",
  "kulce",
  "kulek",
  "kulem",
  "kulhá",
  "kulhé",
  "kulhý",
  "kulil",
  "kulis",
  "kulku",
  "kulky",
  "kulmu",
  "kulmy",
  "kulou",
  "kultu",
  "kulty",
  "kultů",
  "kulím",
  "kunou",
  "kunám",
  "kunín",
  "kupce",
  "kupci",
  "kupců",
  "kupek",
  "kupil",
  "kupku",
  "kupky",
  "kupme",
  "kupou",
  "kupte",
  "kupuj",
  "kupám",
  "kupím",
  "kupíš",
  "kupče",
  "kupčí",
  "kurdy",
  "kurdů",
  "kurem",
  "kurev",
  "kurií",
  "kurse",
  "kursu",
  "kursy",
  "kursů",
  "kurta",
  "kurtu",
  "kurty",
  "kurtě",
  "kurtů",
  "kurvo",
  "kurvu",
  "kurvy",
  "kurzu",
  "kurzy",
  "kurzů",
  "kusem",
  "kusou",
  "kusém",
  "kusým",
  "kusům",
  "kutal",
  "kuten",
  "kutil",
  "kutnu",
  "kutny",
  "kutná",
  "kutné",
  "kutní",
  "kutný",
  "kutně",
  "kutou",
  "kutru",
  "kutám",
  "kutém",
  "kutím",
  "kutým",
  "kuvér",
  "kučeř",
  "kuňká",
  "kuřat",
  "kuřim",
  "kuřiv",
  "kuřme",
  "kuřte",
  "kuším",
  "kvant",
  "kvapu",
  "kvapí",
  "kvart",
  "kvasu",
  "kvasí",
  "kvazi",
  "kveta",
  "kvete",
  "kvetl",
  "kvido",
  "kvint",
  "kvizu",
  "kvizy",
  "kvizů",
  "kvičí",
  "kvoká",
  "kváká",
  "kvéry",
  "kvílí",
  "kvízu",
  "kvízy",
  "kvízů",
  "kvóra",
  "kvótu",
  "kvóty",
  "kvótě",
  "květa",
  "květe",
  "květu",
  "květy",
  "květů",
  "kydal",
  "kydač",
  "kydne",
  "kydám",
  "kydáš",
  "kyjem",
  "kyjev",
  "kyjov",
  "kyjům",
  "kylie",
  "kyneš",
  "kynik",
  "kynou",
  "kynul",
  "kypru",
  "kyprá",
  "kypré",
  "kypím",
  "kypěl",
  "kypři",
  "kypří",
  "kyret",
  "kysat",
  "kysel",
  "kytar",
  "kytce",
  "kytek",
  "kytem",
  "kytic",
  "kytku",
  "kytky",
  "kyvet",
  "kyvná",
  "kyvné",
  "kyvně",
  "kyňme",
  "kyňte",
  "kácej",
  "kácel",
  "kácen",
  "kácet",
  "kácov",
  "kácím",
  "kácíš",
  "kádra",
  "kádru",
  "kádry",
  "kádrů",
  "kádím",
  "kájou",
  "kájov",
  "kájův",
  "kálel",
  "kálím",
  "kálíš",
  "kánoi",
  "kánoí",
  "káním",
  "kápem",
  "kápla",
  "kápli",
  "káplo",
  "káply",
  "kápne",
  "kápni",
  "kápnu",
  "kápou",
  "káral",
  "kárce",
  "kárku",
  "kárná",
  "kárné",
  "kárně",
  "károu",
  "kárám",
  "kárán",
  "káráš",
  "kátin",
  "kávou",
  "kázal",
  "kázni",
  "kázní",
  "kázně",
  "káčou",
  "káňat",
  "kážeš",
  "kážou",
  "kérku",
  "kérky",
  "kódem",
  "kóduj",
  "kódům",
  "kójou",
  "kójím",
  "kólie",
  "kómou",
  "kótou",
  "kótám",
  "kúrou",
  "kúrám",
  "kýble",
  "kýblu",
  "kýchl",
  "kýchá",
  "kýlem",
  "kýlní",
  "kýlou",
  "kýlům",
  "kýmže",
  "kýros",
  "kýtou",
  "kýtám",
  "kýval",
  "kývej",
  "kýveš",
  "kývla",
  "kývli",
  "kývlo",
  "kývly",
  "kývne",
  "kývni",
  "kývnu",
  "kývou",
  "kývám",
  "kývém",
  "kývým",
  "kýčem",
  "kýčům",
  "kňour",
  "kňučí",
  "křeky",
  "křenu",
  "křesl",
  "křesť",
  "křeče",
  "křeči",
  "křečí",
  "křeše",
  "křikl",
  "křiku",
  "křivd",
  "křivo",
  "křivá",
  "křivé",
  "křiví",
  "křivě",
  "křiče",
  "křičí",
  "křtil",
  "křtin",
  "křtím",
  "křtíš",
  "křtěn",
  "křupe",
  "křápu",
  "křápy",
  "křídu",
  "křídy",
  "křídě",
  "křísí",
  "kříže",
  "kříži",
  "kříží",
  "křížů",
  "kštic",
  "kšého",
  "kšému",
  "kšých",
  "kšýma",
  "kšými",
  "kůlem",
  "kůlen",
  "kůlnu",
  "kůlny",
  "kůlně",
  "kůlům",
  "kůrce",
  "kůrem",
  "kůrku",
  "kůrky",
  "kůrou",
  "kůrům",
  "labem",
  "labou",
  "lacet",
  "ladil",
  "ladin",
  "ladná",
  "ladné",
  "ladně",
  "ladou",
  "ladím",
  "ladíš",
  "laděn",
  "lagun",
  "lahve",
  "lahvi",
  "lahví",
  "laici",
  "laika",
  "laiku",
  "laiky",
  "laiků",
  "lajka",
  "lajny",
  "lajně",
  "lajos",
  "lakem",
  "lakom",
  "lakuj",
  "lakům",
  "lamel",
  "lamou",
  "lampu",
  "lampy",
  "lampě",
  "lamám",
  "landů",
  "lanek",
  "lanem",
  "lanka",
  "lanku",
  "lanky",
  "lanou",
  "lanům",
  "laosu",
  "lapal",
  "lapen",
  "lapil",
  "lapka",
  "lapky",
  "lapků",
  "lapne",
  "lapou",
  "lapám",
  "lapém",
  "lapím",
  "lapíš",
  "lapým",
  "larvu",
  "larvy",
  "larvě",
  "lasem",
  "lasic",
  "laská",
  "lasou",
  "latin",
  "latou",
  "latím",
  "latěk",
  "laufu",
  "laura",
  "lauru",
  "laury",
  "lauře",
  "lavic",
  "lavin",
  "lavor",
  "lavór",
  "laxně",
  "lazce",
  "lazur",
  "lačno",
  "lačná",
  "lačné",
  "lační",
  "lačně",
  "laďka",
  "laďme",
  "laďte",
  "laťce",
  "laťek",
  "laťku",
  "laťky",
  "lebce",
  "lebek",
  "lebku",
  "lebky",
  "lecco",
  "lecos",
  "ledař",
  "ledce",
  "ledem",
  "leden",
  "ledeč",
  "ledku",
  "ledky",
  "ledna",
  "lednu",
  "lední",
  "ledou",
  "leduj",
  "ledče",
  "ledům",
  "legem",
  "legii",
  "legií",
  "legur",
  "lehal",
  "lehce",
  "lehcí",
  "lehká",
  "lehké",
  "lehla",
  "lehli",
  "lehlo",
  "lehly",
  "lehlá",
  "lehlé",
  "lehne",
  "lehni",
  "lehnu",
  "lehou",
  "lehám",
  "lehém",
  "lehým",
  "lehčí",
  "leica",
  "lejna",
  "lejnu",
  "lejou",
  "lekal",
  "lekci",
  "lekcí",
  "lekla",
  "lekli",
  "leklo",
  "lekly",
  "leklá",
  "leklé",
  "lekne",
  "leknu",
  "lekou",
  "lekám",
  "lekém",
  "lekým",
  "lemem",
  "lemon",
  "lempl",
  "lemuj",
  "lenba",
  "lence",
  "lenci",
  "lenin",
  "lenka",
  "lenko",
  "lenku",
  "lenky",
  "lenní",
  "leona",
  "leone",
  "leoně",
  "leoše",
  "leoši",
  "lepem",
  "lepen",
  "lepil",
  "lepič",
  "lepku",
  "lepme",
  "lepou",
  "lepry",
  "lepte",
  "lepty",
  "leptá",
  "lepté",
  "leptý",
  "lepém",
  "lepím",
  "lepým",
  "lepší",
  "lesbu",
  "lesby",
  "leseb",
  "lesem",
  "leskl",
  "lesku",
  "lesky",
  "lesků",
  "lesná",
  "lesné",
  "lesný",
  "lesou",
  "lesík",
  "lesům",
  "letce",
  "letci",
  "letců",
  "letek",
  "letku",
  "letky",
  "letmá",
  "letmé",
  "letné",
  "letně",
  "letou",
  "letuj",
  "letém",
  "letím",
  "letíš",
  "letým",
  "letče",
  "letěl",
  "levná",
  "levné",
  "levní",
  "levně",
  "levou",
  "levém",
  "levín",
  "levým",
  "lezce",
  "lezci",
  "lezců",
  "lezeš",
  "lezla",
  "lezli",
  "lezlo",
  "lezly",
  "lezme",
  "lezou",
  "lezte",
  "lečem",
  "lečte",
  "lešná",
  "lešné",
  "lešný",
  "lešti",
  "leští",
  "leťme",
  "leťte",
  "ležel",
  "ležte",
  "ležím",
  "ležíš",
  "lhala",
  "lhali",
  "lhalo",
  "lhaly",
  "lhavá",
  "lhavé",
  "lhota",
  "lhotu",
  "lhoty",
  "lhotě",
  "lháře",
  "lháři",
  "lhářů",
  "lhůtu",
  "lhůty",
  "lhůtě",
  "liazu",
  "libel",
  "liber",
  "libka",
  "libni",
  "libní",
  "libor",
  "libou",
  "libru",
  "libry",
  "libuj",
  "libuň",
  "libye",
  "libyi",
  "libyí",
  "libáň",
  "libém",
  "libým",
  "libře",
  "lichá",
  "liché",
  "licna",
  "licnu",
  "licny",
  "licím",
  "lideč",
  "lidic",
  "lidka",
  "lidou",
  "ligou",
  "liješ",
  "lijou",
  "lijte",
  "lilii",
  "lilií",
  "lilku",
  "lilky",
  "limbu",
  "limou",
  "lince",
  "linda",
  "linde",
  "lindo",
  "lindu",
  "lindy",
  "lindě",
  "linek",
  "lineo",
  "linii",
  "linií",
  "linku",
  "linky",
  "linků",
  "linou",
  "linul",
  "linux",
  "lipna",
  "lipne",
  "lipno",
  "lipně",
  "lipov",
  "lirou",
  "lirám",
  "lisař",
  "lisem",
  "lisou",
  "listr",
  "listu",
  "listy",
  "listě",
  "listů",
  "lisuj",
  "lisům",
  "liter",
  "liteň",
  "litin",
  "litná",
  "litné",
  "litný",
  "litou",
  "litru",
  "litry",
  "litrů",
  "lituj",
  "litva",
  "litvu",
  "litvy",
  "litvě",
  "litém",
  "litím",
  "litým",
  "livia",
  "livie",
  "livii",
  "lizem",
  "lizka",
  "lizům",
  "liánu",
  "liány",
  "liáně",
  "lično",
  "lišce",
  "lišek",
  "lišen",
  "lišil",
  "lišku",
  "lišky",
  "lišme",
  "lišov",
  "lištu",
  "lišty",
  "liště",
  "lišíc",
  "liším",
  "lišíš",
  "liščí",
  "lišší",
  "ljuba",
  "ljubu",
  "ljubě",
  "lkala",
  "lkali",
  "lkalo",
  "lkaly",
  "lkavá",
  "lkavé",
  "lneme",
  "lnete",
  "lnová",
  "lnové",
  "lnuli",
  "lnáře",
  "lněme",
  "lněná",
  "lněné",
  "lnění",
  "lněte",
  "lobem",
  "lobeč",
  "lobuj",
  "lochu",
  "lodyh",
  "lodím",
  "loděk",
  "loděn",
  "logem",
  "login",
  "logon",
  "logou",
  "loguj",
  "logům",
  "lojou",
  "lokaj",
  "lokem",
  "loken",
  "lokla",
  "lokli",
  "loklo",
  "lokly",
  "lokne",
  "lokni",
  "loknu",
  "lokny",
  "lokně",
  "lokám",
  "lomem",
  "lomil",
  "lomná",
  "lomné",
  "lomím",
  "lomíš",
  "lomům",
  "lopat",
  "lopou",
  "lorda",
  "lorde",
  "lordi",
  "lordu",
  "lordy",
  "lordů",
  "losem",
  "losic",
  "losou",
  "lostí",
  "losuj",
  "losím",
  "losům",
  "losův",
  "lotem",
  "lotou",
  "lotra",
  "lotru",
  "lotry",
  "lotrů",
  "lotyš",
  "lotře",
  "lotři",
  "louce",
  "loudá",
  "loudé",
  "loudí",
  "loudý",
  "louhu",
  "louhy",
  "louhů",
  "louku",
  "louky",
  "louny",
  "loupe",
  "loupl",
  "loupí",
  "louvě",
  "louče",
  "louči",
  "loučí",
  "louži",
  "louží",
  "lovce",
  "lovci",
  "lovců",
  "lovem",
  "lovil",
  "lovka",
  "lovme",
  "lovná",
  "lovné",
  "lovte",
  "lovím",
  "lovíš",
  "lovče",
  "lovčí",
  "lovům",
  "loďař",
  "loďce",
  "loďku",
  "loďky",
  "loďmi",
  "ložem",
  "ložná",
  "ložné",
  "ložný",
  "lpíme",
  "lpíte",
  "lpějí",
  "lpěla",
  "lpěli",
  "lpělo",
  "lpěly",
  "lpěme",
  "lpěte",
  "lsass",
  "lskou",
  "lském",
  "lským",
  "lstná",
  "lstné",
  "lstva",
  "lubem",
  "lubná",
  "lubné",
  "lubný",
  "lubor",
  "lubos",
  "luboš",
  "lucce",
  "lucie",
  "lucii",
  "lucií",
  "lucka",
  "lucko",
  "lucku",
  "lucky",
  "lucká",
  "lucké",
  "ludva",
  "luděk",
  "lufou",
  "luftu",
  "luhem",
  "luhům",
  "lukem",
  "lukou",
  "lukov",
  "lukám",
  "lukáš",
  "lukům",
  "lulat",
  "lulce",
  "lulek",
  "luleč",
  "lulku",
  "lulky",
  "lumci",
  "lumka",
  "lumku",
  "lumky",
  "lumků",
  "lumpa",
  "lumpe",
  "lumpy",
  "lumpů",
  "lumír",
  "lunch",
  "lunet",
  "lunou",
  "lupem",
  "lupku",
  "lupky",
  "lupků",
  "lupla",
  "luplo",
  "lupne",
  "lupou",
  "lupám",
  "lupům",
  "lusek",
  "luskl",
  "lusku",
  "lusky",
  "luská",
  "luské",
  "luský",
  "lusků",
  "lutín",
  "luxem",
  "luxuj",
  "luzná",
  "luzné",
  "luzní",
  "luzou",
  "lučin",
  "luční",
  "lučík",
  "luďku",
  "luňák",
  "lušti",
  "luští",
  "lužby",
  "lužec",
  "lužná",
  "lužné",
  "lužný",
  "lvici",
  "lvicí",
  "lvová",
  "lvové",
  "lvích",
  "lvího",
  "lvíma",
  "lvími",
  "lvímu",
  "lycea",
  "lyceu",
  "lymfu",
  "lymfy",
  "lyrou",
  "lyrám",
  "lysic",
  "lysin",
  "lyská",
  "lyské",
  "lysou",
  "lysém",
  "lysým",
  "lyžař",
  "lyžin",
  "lyžuj",
  "lyžák",
  "lyžím",
  "láduj",
  "lágru",
  "lágry",
  "lágrů",
  "láhve",
  "láhvi",
  "láhví",
  "lákal",
  "lákem",
  "lákou",
  "lákám",
  "lákém",
  "lákým",
  "lálou",
  "lámal",
  "lámej",
  "lámeš",
  "lámou",
  "lámán",
  "lámův",
  "lánem",
  "lánov",
  "lánům",
  "lásce",
  "lásek",
  "lásko",
  "lásku",
  "lásky",
  "látal",
  "látce",
  "látek",
  "látku",
  "látky",
  "látám",
  "látán",
  "látáš",
  "lávce",
  "lávek",
  "lávku",
  "lávky",
  "lávou",
  "lázni",
  "lázní",
  "lázně",
  "láďou",
  "láďův",
  "láška",
  "léhou",
  "léhém",
  "léhým",
  "lékem",
  "lékům",
  "lénem",
  "lénou",
  "lénům",
  "lépou",
  "létal",
  "létej",
  "létem",
  "létou",
  "létám",
  "létém",
  "létým",
  "létům",
  "léčbu",
  "léčby",
  "léčbě",
  "léčce",
  "léčeb",
  "léček",
  "léčen",
  "léčil",
  "léčiv",
  "léčku",
  "léčky",
  "léčíc",
  "léčím",
  "léčíš",
  "líbal",
  "líbej",
  "líben",
  "líbil",
  "líbou",
  "líbám",
  "líbáš",
  "líbém",
  "líbím",
  "líbíš",
  "líbým",
  "lícem",
  "lícha",
  "líchy",
  "lícíc",
  "lícím",
  "lídin",
  "lídou",
  "lídra",
  "lídru",
  "lídry",
  "lídrů",
  "lídři",
  "líhne",
  "líhni",
  "líhní",
  "líhně",
  "líhou",
  "lílie",
  "límce",
  "límci",
  "límců",
  "línal",
  "línej",
  "línem",
  "línou",
  "líném",
  "líným",
  "línům",
  "lípou",
  "lípám",
  "lísal",
  "lísek",
  "lísku",
  "lísky",
  "lísou",
  "lísám",
  "lísém",
  "lísým",
  "lítal",
  "lítou",
  "lítám",
  "lítáš",
  "lítém",
  "lítým",
  "lízal",
  "lízat",
  "lízej",
  "lízin",
  "lízla",
  "lízli",
  "lízlo",
  "lízly",
  "lízou",
  "líčen",
  "líčil",
  "líčku",
  "líčíš",
  "lógru",
  "lýdie",
  "lýkem",
  "lýtek",
  "lýtka",
  "lýtku",
  "lýtky",
  "lůnem",
  "lůnou",
  "lůzou",
  "lůžek",
  "lůžka",
  "lůžku",
  "lůžky",
  "lžeme",
  "lžete",
  "lživá",
  "lživé",
  "lživě",
  "lžíci",
  "lžící",
  "macao",
  "machu",
  "machů",
  "macka",
  "madel",
  "madla",
  "madlu",
  "madly",
  "madon",
  "mafii",
  "mafií",
  "magda",
  "magdy",
  "magii",
  "magií",
  "mailu",
  "maily",
  "mailů",
  "makak",
  "makal",
  "maker",
  "maket",
  "makra",
  "makru",
  "makry",
  "makám",
  "makáš",
  "malbu",
  "malby",
  "malbě",
  "maleb",
  "malej",
  "maleč",
  "malin",
  "malou",
  "maltu",
  "malty",
  "maltě",
  "maluj",
  "malém",
  "malín",
  "malým",
  "malší",
  "mamce",
  "mamko",
  "mamku",
  "mamky",
  "mamou",
  "mamém",
  "mamým",
  "maneš",
  "manga",
  "manka",
  "manku",
  "manou",
  "mapce",
  "mapek",
  "mapku",
  "mapky",
  "mapou",
  "mapuj",
  "mapám",
  "marce",
  "marek",
  "maria",
  "marie",
  "marii",
  "mario",
  "marií",
  "marku",
  "marky",
  "marná",
  "marné",
  "marní",
  "marně",
  "marsu",
  "marsy",
  "marta",
  "marto",
  "martu",
  "marty",
  "martě",
  "maruš",
  "marxe",
  "marše",
  "maršů",
  "marži",
  "marží",
  "masce",
  "masek",
  "masem",
  "masku",
  "masky",
  "masnu",
  "masny",
  "masná",
  "masné",
  "masně",
  "masou",
  "masox",
  "masti",
  "mastí",
  "mastě",
  "masám",
  "masům",
  "matce",
  "matek",
  "matem",
  "mateš",
  "matic",
  "matko",
  "matku",
  "matky",
  "matná",
  "matné",
  "matně",
  "matou",
  "matěj",
  "maury",
  "maurů",
  "maxim",
  "mayův",
  "mazal",
  "mazač",
  "mazda",
  "mazdě",
  "mazej",
  "mazel",
  "mazem",
  "maziv",
  "mazlí",
  "mazná",
  "mazné",
  "mazán",
  "maček",
  "mačem",
  "mačet",
  "mačká",
  "mačké",
  "mačký",
  "maďar",
  "mařil",
  "mařka",
  "mařku",
  "mařky",
  "mařte",
  "mařím",
  "maříš",
  "mašin",
  "mašky",
  "mašli",
  "mašlí",
  "mažeš",
  "mažme",
  "mažou",
  "mažte",
  "mbyte",
  "mcely",
  "mdlob",
  "mdlou",
  "mdlém",
  "mdlým",
  "mechu",
  "mechy",
  "mechů",
  "mecký",
  "medek",
  "medem",
  "medií",
  "medka",
  "medne",
  "medná",
  "medné",
  "medák",
  "medúz",
  "megou",
  "meješ",
  "mekat",
  "mekce",
  "mekka",
  "mekky",
  "meleš",
  "meliv",
  "melou",
  "melte",
  "meltu",
  "menze",
  "menzu",
  "menzy",
  "menši",
  "menší",
  "merck",
  "merta",
  "metač",
  "metel",
  "meten",
  "meteš",
  "metle",
  "metli",
  "metlo",
  "metlu",
  "metly",
  "metod",
  "metou",
  "metra",
  "metru",
  "metry",
  "metrů",
  "metám",
  "metém",
  "metým",
  "mezci",
  "mezer",
  "mezil",
  "mezit",
  "mezka",
  "mezku",
  "mezky",
  "mezků",
  "mezná",
  "mezné",
  "mezně",
  "mezím",
  "mečel",
  "mečem",
  "mečtí",
  "mečím",
  "mečíš",
  "mečům",
  "mešit",
  "mešní",
  "meťme",
  "meťte",
  "mhách",
  "micky",
  "mihla",
  "mihli",
  "mihlo",
  "mihly",
  "mihne",
  "mikro",
  "mikád",
  "milan",
  "milce",
  "milic",
  "milos",
  "milou",
  "miloš",
  "miluj",
  "milém",
  "milín",
  "milým",
  "mimem",
  "mimin",
  "mimos",
  "mimou",
  "mimoň",
  "mimův",
  "minci",
  "mincí",
  "mineš",
  "minim",
  "minka",
  "minou",
  "minul",
  "minut",
  "minám",
  "minér",
  "mirce",
  "mirek",
  "mirem",
  "mirka",
  "mirko",
  "mirku",
  "mirky",
  "misce",
  "misek",
  "misii",
  "misií",
  "misku",
  "misky",
  "misou",
  "misím",
  "mitru",
  "mitry",
  "mixem",
  "mixuj",
  "mizel",
  "mizin",
  "mizme",
  "mizte",
  "mizíc",
  "mizím",
  "mizíš",
  "miřme",
  "miřte",
  "mladá",
  "mladé",
  "mladí",
  "mladě",
  "mlask",
  "mlatu",
  "mlaty",
  "mlecí",
  "mlela",
  "mleli",
  "mlelo",
  "mlely",
  "mletá",
  "mleté",
  "mlhou",
  "mlhám",
  "mloci",
  "mloka",
  "mloky",
  "mloků",
  "mločí",
  "mlsal",
  "mlsej",
  "mlsku",
  "mlsná",
  "mlsné",
  "mlsní",
  "mlsně",
  "mlsou",
  "mlsám",
  "mlsém",
  "mlsým",
  "mluvu",
  "mluvy",
  "mluví",
  "mluvě",
  "mládi",
  "mlátí",
  "mléce",
  "mléka",
  "mléku",
  "mléky",
  "mlíko",
  "mlýna",
  "mlýnu",
  "mlýny",
  "mlýně",
  "mlýnů",
  "mlčel",
  "mlčme",
  "mlčte",
  "mlčím",
  "mlčíš",
  "mlžem",
  "mlžil",
  "mlžná",
  "mlžné",
  "mlžte",
  "mlžům",
  "mlžův",
  "mneme",
  "mnete",
  "mniši",
  "mniší",
  "mnoha",
  "mnoho",
  "mnohá",
  "mnohé",
  "mnozí",
  "množí",
  "mnula",
  "mnuli",
  "mnulo",
  "mnuly",
  "mnutá",
  "mnuté",
  "mníci",
  "mněme",
  "mnění",
  "mocná",
  "mocné",
  "mocní",
  "mocně",
  "moder",
  "modle",
  "modli",
  "modlo",
  "modlu",
  "modly",
  "modlí",
  "modní",
  "modou",
  "modra",
  "modrá",
  "modré",
  "modře",
  "modři",
  "modří",
  "mohla",
  "mohli",
  "mohlo",
  "mohly",
  "mohou",
  "mohyl",
  "mojou",
  "mokem",
  "mokou",
  "mokra",
  "mokru",
  "mokry",
  "mokrá",
  "mokré",
  "mokvá",
  "mokří",
  "molem",
  "molou",
  "molům",
  "molův",
  "monet",
  "monou",
  "monče",
  "moona",
  "mopem",
  "mopéd",
  "mordu",
  "mordy",
  "mordů",
  "morem",
  "morku",
  "morou",
  "mostu",
  "mosty",
  "mostě",
  "mostů",
  "motal",
  "motem",
  "motet",
  "motou",
  "motyk",
  "motám",
  "motém",
  "motým",
  "mouce",
  "mouku",
  "mouky",
  "moula",
  "mouru",
  "mouše",
  "mozku",
  "mozky",
  "mozků",
  "močil",
  "močme",
  "močte",
  "močíc",
  "močím",
  "močíš",
  "mořem",
  "mořen",
  "mořil",
  "mořme",
  "mořte",
  "mořím",
  "moříš",
  "mošnu",
  "mošny",
  "mošně",
  "moštu",
  "mošty",
  "moštů",
  "možné",
  "možní",
  "možně",
  "mraku",
  "mraky",
  "mraků",
  "mravy",
  "mravů",
  "mrazí",
  "mračí",
  "mrcho",
  "mrchu",
  "mrchy",
  "mrdat",
  "mrhal",
  "mrhej",
  "mrhám",
  "mrháš",
  "mrkal",
  "mrkej",
  "mrkla",
  "mrkli",
  "mrklo",
  "mrkly",
  "mrkne",
  "mrkni",
  "mrknu",
  "mrkou",
  "mrkve",
  "mrkvi",
  "mrkví",
  "mrkám",
  "mrkáš",
  "mrkém",
  "mrkým",
  "mrože",
  "mroži",
  "mroží",
  "mrožů",
  "mrskl",
  "mrská",
  "mrské",
  "mrský",
  "mrtev",
  "mrtva",
  "mrtvi",
  "mrtvo",
  "mrtvá",
  "mrtvé",
  "mrtví",
  "mrtvě",
  "mručí",
  "mrvou",
  "mrzel",
  "mrzká",
  "mrzké",
  "mrzla",
  "mrzli",
  "mrzlo",
  "mrzly",
  "mrzne",
  "mrzni",
  "mrznu",
  "mrzuj",
  "mrzuť",
  "mrzíc",
  "mrzím",
  "mrzíš",
  "mrňat",
  "mrští",
  "msdos",
  "mstil",
  "mstná",
  "mstné",
  "mstou",
  "mstím",
  "mstíš",
  "mudrc",
  "mukla",
  "mukly",
  "mukou",
  "mukám",
  "mulda",
  "mulou",
  "multi",
  "mulám",
  "mumii",
  "mumií",
  "mumlá",
  "mumlé",
  "mumlý",
  "musea",
  "music",
  "musím",
  "musíš",
  "muzea",
  "muzei",
  "muzeu",
  "muzeí",
  "mučen",
  "mučil",
  "mučte",
  "mučím",
  "mučíš",
  "muřím",
  "mušce",
  "mušek",
  "muško",
  "mušku",
  "mušky",
  "mušli",
  "mušlí",
  "muším",
  "mužem",
  "mužná",
  "mužné",
  "mužně",
  "mužům",
  "mužův",
  "mycím",
  "mydlí",
  "myješ",
  "myjou",
  "myjte",
  "mykóz",
  "mylná",
  "mylné",
  "mylně",
  "myomy",
  "myrtu",
  "myrty",
  "myrtě",
  "mysem",
  "mysle",
  "mysql",
  "mysům",
  "mytou",
  "mytém",
  "mytím",
  "mytým",
  "myčce",
  "myček",
  "myčku",
  "myčky",
  "myčům",
  "myšce",
  "myšek",
  "myšku",
  "myšky",
  "myšmi",
  "myším",
  "mzdou",
  "mzdám",
  "máchl",
  "máchá",
  "máché",
  "máchý",
  "mágem",
  "mágou",
  "mágům",
  "mágův",
  "májem",
  "májku",
  "májky",
  "májou",
  "mákem",
  "mákli",
  "mákni",
  "mákům",
  "málně",
  "málou",
  "mámil",
  "mámin",
  "mámou",
  "mámím",
  "mámíš",
  "mánii",
  "mánií",
  "márám",
  "másel",
  "másla",
  "másle",
  "máslu",
  "másly",
  "mátla",
  "mátli",
  "mátlo",
  "mátly",
  "mátoh",
  "mátou",
  "mával",
  "mávej",
  "mávla",
  "mávli",
  "mávlo",
  "mávne",
  "mávni",
  "mávnu",
  "mávou",
  "mávám",
  "máváš",
  "máčej",
  "máčel",
  "máčet",
  "máčím",
  "máčíš",
  "mářin",
  "mářím",
  "mářův",
  "mášin",
  "média",
  "médii",
  "médiu",
  "médií",
  "míchu",
  "míchy",
  "míchá",
  "míché",
  "míchý",
  "míhal",
  "míjel",
  "míjím",
  "míjíš",
  "mílin",
  "mílou",
  "mílím",
  "mílův",
  "mínil",
  "mínky",
  "míním",
  "míníš",
  "míněn",
  "mírek",
  "mírem",
  "mírko",
  "mírku",
  "mírni",
  "mírná",
  "mírné",
  "mírní",
  "mírně",
  "mírou",
  "mírov",
  "mírám",
  "mísil",
  "mísit",
  "mísou",
  "místa",
  "místi",
  "místu",
  "místy",
  "místí",
  "místě",
  "mísám",
  "mísíš",
  "míval",
  "mívou",
  "mívám",
  "míváš",
  "mízou",
  "míčem",
  "míčku",
  "míčky",
  "míčků",
  "mířen",
  "mířil",
  "mířím",
  "míříš",
  "míšin",
  "míšků",
  "míšou",
  "míšův",
  "módem",
  "módní",
  "módně",
  "módou",
  "módům",
  "múzou",
  "múzám",
  "mýcen",
  "mýdel",
  "mýdla",
  "mýdle",
  "mýdlu",
  "mýdly",
  "mýlce",
  "mýlil",
  "mýlím",
  "mýlíš",
  "mýtem",
  "mýtil",
  "mýtin",
  "mýtná",
  "mýtné",
  "mýtní",
  "mýtný",
  "mýtou",
  "mýtím",
  "mýtíš",
  "mýtům",
  "mýval",
  "mývou",
  "mývém",
  "mývým",
  "měchu",
  "měchy",
  "měchů",
  "mědit",
  "mějme",
  "mějte",
  "měkce",
  "měkko",
  "měkká",
  "měkké",
  "měklo",
  "měkly",
  "měkne",
  "měkou",
  "měkčí",
  "mělce",
  "mělko",
  "mělká",
  "mělké",
  "mělou",
  "mělčí",
  "měnil",
  "měnit",
  "měnné",
  "měnou",
  "měnám",
  "měník",
  "měním",
  "měnín",
  "měníš",
  "měněn",
  "měrce",
  "měrek",
  "měrku",
  "měrky",
  "měrná",
  "měrné",
  "města",
  "městu",
  "městy",
  "městě",
  "mětem",
  "mětný",
  "měčín",
  "měďná",
  "měďné",
  "měďný",
  "měňte",
  "měřen",
  "měřil",
  "měřič",
  "měřme",
  "měřte",
  "měřák",
  "měřím",
  "měřín",
  "měříš",
  "měšce",
  "měšci",
  "měšců",
  "mříže",
  "mříži",
  "mříží",
  "mšemi",
  "mšeno",
  "mšici",
  "mšicí",
  "mších",
  "můrou",
  "můrám",
  "můzou",
  "můzám",
  "můžem",
  "můžeš",
  "můžou",
  "mžiku",
  "mžiká",
  "mžiké",
  "mžiký",
  "mžilo",
  "naber",
  "nabij",
  "nabil",
  "nabob",
  "nabyv",
  "nacpe",
  "nacpi",
  "nacpu",
  "nadej",
  "nadin",
  "nadme",
  "nadou",
  "nadra",
  "nadál",
  "nadán",
  "nadře",
  "nadřu",
  "nafoť",
  "naftu",
  "nafty",
  "naftě",
  "nahne",
  "nahnu",
  "nahou",
  "nahoď",
  "nahém",
  "nahým",
  "naiva",
  "najda",
  "najde",
  "najdi",
  "najed",
  "najet",
  "najev",
  "najez",
  "najme",
  "najmu",
  "najmě",
  "najím",
  "najíš",
  "nakdy",
  "nakoj",
  "nakrm",
  "nalaď",
  "nalej",
  "nalep",
  "nalij",
  "nalil",
  "nalič",
  "nalož",
  "namaž",
  "namiř",
  "namoč",
  "namoř",
  "namíš",
  "naměř",
  "nandá",
  "nanes",
  "nanos",
  "naomi",
  "napal",
  "napij",
  "napil",
  "napiš",
  "naplň",
  "napne",
  "napni",
  "napnu",
  "napoj",
  "napou",
  "napět",
  "napře",
  "naraz",
  "naruš",
  "narvu",
  "nasaď",
  "naser",
  "nasou",
  "nasuš",
  "nasyť",
  "nasál",
  "nasát",
  "natoč",
  "natra",
  "natěž",
  "natře",
  "natři",
  "natřu",
  "nauce",
  "nauku",
  "nauky",
  "nauru",
  "naučí",
  "naval",
  "navar",
  "navař",
  "navaž",
  "navez",
  "naveď",
  "naviň",
  "navoň",
  "navrš",
  "navál",
  "navát",
  "nazuj",
  "nazul",
  "nazve",
  "nazvi",
  "nazvu",
  "načal",
  "načne",
  "načnu",
  "načte",
  "načti",
  "naďou",
  "nařež",
  "nařiď",
  "nařkl",
  "našem",
  "našil",
  "našim",
  "našla",
  "našli",
  "našlo",
  "našly",
  "naším",
  "nažit",
  "nebal",
  "nebav",
  "nebdí",
  "nebem",
  "neber",
  "nebij",
  "nebil",
  "neboj",
  "nebol",
  "nebou",
  "neboř",
  "neboť",
  "nebuč",
  "nebuď",
  "nebyl",
  "nebál",
  "nebát",
  "nebít",
  "nebýt",
  "necek",
  "nechá",
  "nechť",
  "necpe",
  "necpi",
  "necpu",
  "nectí",
  "nedal",
  "nedat",
  "nedbá",
  "nedbé",
  "nedbý",
  "nedej",
  "nediv",
  "nedou",
  "nedrť",
  "nedrž",
  "nedám",
  "nedát",
  "nedáš",
  "nedým",
  "neděj",
  "neděl",
  "neděs",
  "nedře",
  "nedři",
  "nefoť",
  "nefuň",
  "nefér",
  "negra",
  "negři",
  "nehas",
  "nehaň",
  "nehla",
  "nehli",
  "nehlo",
  "nehly",
  "nehne",
  "nehni",
  "nehnu",
  "nehod",
  "nehol",
  "nehoň",
  "nehrň",
  "nehtu",
  "nehty",
  "nehtů",
  "nehuč",
  "nehyř",
  "nejde",
  "nejdu",
  "nejel",
  "nejet",
  "nejez",
  "nejsi",
  "nejso",
  "nejím",
  "nejít",
  "nejíš",
  "nekal",
  "nekoř",
  "nekrč",
  "nekuř",
  "nelam",
  "nelaď",
  "nelej",
  "nelep",
  "nelez",
  "nelic",
  "nelij",
  "nelin",
  "nelič",
  "nelne",
  "nelom",
  "nelou",
  "nelpí",
  "neluč",
  "nelze",
  "nelíš",
  "nelže",
  "nelži",
  "nelžu",
  "nemaj",
  "nemaž",
  "nemel",
  "nemiz",
  "nemiř",
  "nemlč",
  "nemne",
  "nemni",
  "nemnu",
  "nemou",
  "nemoč",
  "nemuč",
  "nemyl",
  "nemám",
  "nemáš",
  "nemém",
  "nemír",
  "nemít",
  "nemým",
  "nemýt",
  "neměj",
  "neměl",
  "neměň",
  "neměř",
  "nenes",
  "nenič",
  "nenos",
  "nenou",
  "nenoř",
  "nenuť",
  "neonu",
  "neony",
  "neonů",
  "neoře",
  "nepač",
  "neper",
  "nepij",
  "nepil",
  "nepiš",
  "neplň",
  "nepra",
  "neptá",
  "nepté",
  "neptý",
  "nepál",
  "nepít",
  "nepře",
  "nepři",
  "nepřu",
  "nerad",
  "neraď",
  "neroň",
  "nerud",
  "neruč",
  "neruš",
  "nervu",
  "nervy",
  "nervů",
  "neržá",
  "nesem",
  "nesen",
  "neser",
  "neseď",
  "neseš",
  "nesiř",
  "nesla",
  "nesli",
  "neslo",
  "nesly",
  "nesme",
  "nesmí",
  "nesní",
  "nesol",
  "nesou",
  "nespi",
  "nespr",
  "nespí",
  "neste",
  "nesuď",
  "nesuš",
  "nesvá",
  "nesvé",
  "nesví",
  "nesvý",
  "nesál",
  "nesát",
  "netaj",
  "netká",
  "netop",
  "netoč",
  "netín",
  "netěš",
  "netěž",
  "netře",
  "netři",
  "netřu",
  "neudá",
  "neudí",
  "neudý",
  "neuhl",
  "neumí",
  "neumě",
  "neučí",
  "nevař",
  "nevaž",
  "nevdá",
  "nevdé",
  "nevdý",
  "nevis",
  "neviň",
  "nevol",
  "nevoz",
  "nevoď",
  "nevrč",
  "nevrš",
  "nevyj",
  "nevyl",
  "nevím",
  "nevíš",
  "nevěd",
  "nevěr",
  "nevěs",
  "nevěř",
  "nevěš",
  "nevře",
  "nezdá",
  "nezdé",
  "nezdý",
  "nezel",
  "nezná",
  "nezné",
  "nezní",
  "nezný",
  "nezru",
  "nezul",
  "nezve",
  "nezvu",
  "nezře",
  "neónu",
  "neóny",
  "neúpí",
  "nečeš",
  "nečiň",
  "neční",
  "nečpí",
  "nečte",
  "nečti",
  "nečtu",
  "nečum",
  "neřaď",
  "neřeš",
  "neřež",
  "neřiď",
  "neřku",
  "neřve",
  "neřvi",
  "neřvu",
  "nešel",
  "nešil",
  "nešiď",
  "nešiř",
  "nešla",
  "nešli",
  "nešlo",
  "nešly",
  "nežel",
  "nežer",
  "nežeň",
  "nežil",
  "nežít",
  "nicků",
  "niger",
  "nikdo",
  "niklu",
  "nikol",
  "nikom",
  "nikon",
  "nikou",
  "nikáb",
  "nikým",
  "nimou",
  "ninin",
  "ninou",
  "niněr",
  "niobu",
  "nisou",
  "nitce",
  "nitek",
  "niter",
  "nitku",
  "nitky",
  "nitra",
  "nitru",
  "niveč",
  "nivní",
  "nivou",
  "nivám",
  "ničen",
  "ničil",
  "ničme",
  "ničte",
  "ničíc",
  "ničíš",
  "niťař",
  "nižší",
  "nných",
  "nnými",
  "noema",
  "nohám",
  "nokem",
  "nokia",
  "nokie",
  "nokii",
  "nokií",
  "nokům",
  "nonou",
  "norce",
  "norci",
  "norem",
  "norka",
  "norku",
  "norky",
  "norků",
  "normo",
  "normu",
  "normy",
  "normě",
  "norná",
  "norné",
  "norný",
  "norou",
  "norám",
  "norům",
  "nosem",
  "nosil",
  "nosič",
  "nosme",
  "nosná",
  "nosné",
  "nosně",
  "nosou",
  "noste",
  "ností",
  "nosák",
  "nosím",
  "nosíš",
  "nosům",
  "notce",
  "notná",
  "notné",
  "notně",
  "notou",
  "notuj",
  "notám",
  "nouzi",
  "nouzí",
  "novel",
  "novem",
  "novic",
  "novin",
  "novot",
  "novou",
  "novák",
  "novém",
  "novým",
  "noček",
  "nořil",
  "nořin",
  "noříc",
  "nořím",
  "noříš",
  "nošen",
  "nožce",
  "nožek",
  "nožku",
  "nožky",
  "nožné",
  "nprap",
  "nucen",
  "nudil",
  "nudli",
  "nudlí",
  "nudná",
  "nudné",
  "nudní",
  "nudný",
  "nudně",
  "nudou",
  "nudím",
  "nudíš",
  "nuget",
  "nulou",
  "nultá",
  "nulté",
  "nultý",
  "nuluj",
  "nulám",
  "nusle",
  "nutil",
  "nutno",
  "nutná",
  "nutné",
  "nutný",
  "nutně",
  "nutím",
  "nutíš",
  "nuzná",
  "nuzné",
  "nuzní",
  "nuzně",
  "nuťme",
  "nuťte",
  "nymfo",
  "nymfu",
  "nymfy",
  "nymfě",
  "nyvou",
  "nyvém",
  "nyvým",
  "náběr",
  "nádob",
  "nádrh",
  "náhle",
  "náhlá",
  "náhlé",
  "náhod",
  "nához",
  "nájmu",
  "nájmy",
  "nájmů",
  "nákaz",
  "náklo",
  "nálad",
  "námit",
  "námou",
  "nártu",
  "nárty",
  "nártů",
  "násad",
  "násil",
  "násob",
  "návar",
  "návou",
  "návsi",
  "návsí",
  "návém",
  "názvu",
  "názvy",
  "názvů",
  "náčin",
  "nářad",
  "nářku",
  "nářky",
  "nářků",
  "nésti",
  "níkům",
  "nízce",
  "nízko",
  "nízká",
  "nízké",
  "nízký",
  "nížin",
  "nónou",
  "nótou",
  "nótám",
  "nýdek",
  "nýtem",
  "nýtku",
  "nýtky",
  "nýtuj",
  "nýtům",
  "něhou",
  "někdo",
  "někom",
  "někým",
  "němce",
  "němci",
  "němců",
  "němec",
  "němek",
  "němka",
  "němko",
  "němku",
  "němky",
  "němou",
  "němém",
  "němým",
  "němče",
  "němčí",
  "něčem",
  "něčím",
  "něžná",
  "něžné",
  "něžní",
  "něžně",
  "nůším",
  "nůžek",
  "oasou",
  "obale",
  "obalu",
  "obaly",
  "obalí",
  "obalů",
  "obavu",
  "obavy",
  "obavě",
  "obcím",
  "obdař",
  "obdob",
  "obere",
  "oberu",
  "obhaj",
  "obita",
  "obitá",
  "obité",
  "objet",
  "objeď",
  "obleč",
  "obleť",
  "oblig",
  "obloh",
  "oblou",
  "oblož",
  "oblud",
  "oblém",
  "oblým",
  "obměn",
  "obměň",
  "obnaž",
  "obnov",
  "oboru",
  "obory",
  "oborů",
  "obovu",
  "oboře",
  "oboří",
  "obral",
  "obran",
  "obrat",
  "obrať",
  "obrem",
  "obrnu",
  "obrny",
  "obrní",
  "obrně",
  "obrob",
  "obrod",
  "obrou",
  "obrub",
  "obrus",
  "obryj",
  "obrán",
  "obrům",
  "obrův",
  "obsaď",
  "obská",
  "obské",
  "obský",
  "obsou",
  "obtoč",
  "obtěž",
  "obuch",
  "obuje",
  "obuji",
  "obuju",
  "obují",
  "obula",
  "obuli",
  "obulo",
  "obuly",
  "obuti",
  "obuty",
  "obutá",
  "obuté",
  "obuvy",
  "obvaž",
  "obviň",
  "obvyk",
  "obává",
  "obích",
  "obíhá",
  "obíhé",
  "obíhý",
  "obírá",
  "obíré",
  "obírý",
  "obých",
  "obývá",
  "obývé",
  "obývý",
  "občan",
  "občin",
  "oběda",
  "obědu",
  "obědy",
  "obědě",
  "obědů",
  "oběhl",
  "oběhu",
  "oběhy",
  "oběhů",
  "oběmi",
  "oběsí",
  "oběti",
  "obětí",
  "obřík",
  "obřím",
  "obšil",
  "obšit",
  "ocase",
  "ocasu",
  "ocasy",
  "ocasů",
  "ocele",
  "oceli",
  "ocelí",
  "ocení",
  "oceně",
  "ochot",
  "ochoč",
  "ochuť",
  "ocitl",
  "ocitá",
  "ocité",
  "ocitý",
  "octla",
  "octli",
  "octlo",
  "octly",
  "octne",
  "octnu",
  "octou",
  "ocúnu",
  "ocúny",
  "ocúnů",
  "odbil",
  "odbiv",
  "odboč",
  "odbyv",
  "oddal",
  "oddou",
  "oddra",
  "oddám",
  "oddán",
  "oddáš",
  "oddým",
  "odděl",
  "odeon",
  "odere",
  "odhal",
  "odhoz",
  "odhoď",
  "odhrň",
  "odjet",
  "odjeď",
  "odkap",
  "odkaž",
  "odlep",
  "odleť",
  "odlic",
  "odlij",
  "odlil",
  "odlič",
  "odliš",
  "odlož",
  "odluč",
  "odmoř",
  "odměn",
  "odměň",
  "odměř",
  "odnes",
  "odním",
  "odolá",
  "odpoj",
  "odpoč",
  "odral",
  "odraď",
  "odraž",
  "odrol",
  "odrou",
  "odruš",
  "odrýt",
  "odrůd",
  "odsaď",
  "odsup",
  "odsuď",
  "odsuň",
  "odsál",
  "odsát",
  "odteď",
  "odtoč",
  "odtuš",
  "odtál",
  "odulá",
  "odulé",
  "odvaž",
  "odvet",
  "odvez",
  "odveď",
  "odviň",
  "odvol",
  "odvoď",
  "odvál",
  "odvát",
  "odéru",
  "odérů",
  "odírá",
  "odívá",
  "odčiň",
  "oděna",
  "oděni",
  "oděno",
  "oděny",
  "oděná",
  "oděné",
  "oděru",
  "oděse",
  "oděvn",
  "oděvu",
  "oděvy",
  "oděvů",
  "odňal",
  "odňat",
  "odřel",
  "odřen",
  "odřeš",
  "odřou",
  "odťal",
  "odťat",
  "odťav",
  "ofilu",
  "ofinu",
  "ofiny",
  "ofotí",
  "ohava",
  "ohaře",
  "ohaři",
  "ohaří",
  "ohařů",
  "ohbím",
  "ohmem",
  "ohmův",
  "ohnat",
  "ohneš",
  "ohnou",
  "ohnul",
  "ohnut",
  "ohníč",
  "ohněm",
  "oholí",
  "ohonu",
  "ohony",
  "ohoto",
  "ohoří",
  "ohrad",
  "ohraď",
  "ohrne",
  "ohrnu",
  "ohybu",
  "ohyby",
  "ohybů",
  "ohání",
  "ohýbá",
  "ohýbé",
  "ohýbý",
  "ohňům",
  "ohřej",
  "ohřál",
  "ohřát",
  "ojede",
  "ojeli",
  "ojemi",
  "ojetá",
  "ojeté",
  "ojkou",
  "ojnic",
  "ojích",
  "okapy",
  "okapů",
  "okatá",
  "okaté",
  "okatě",
  "okaze",
  "oklik",
  "oknem",
  "oknou",
  "oknům",
  "okope",
  "okopu",
  "okopy",
  "okopů",
  "okovy",
  "okovů",
  "okrad",
  "okras",
  "okrem",
  "okruž",
  "oktáv",
  "okují",
  "okusí",
  "okách",
  "oldou",
  "oldův",
  "oleje",
  "oleji",
  "olejů",
  "olgou",
  "olila",
  "olilo",
  "olily",
  "olina",
  "oliny",
  "olivu",
  "olivy",
  "olivě",
  "olova",
  "olovu",
  "olovy",
  "oloví",
  "olymp",
  "olízl",
  "olším",
  "olžin",
  "omdlí",
  "omegu",
  "omegy",
  "ometá",
  "omeze",
  "omezí",
  "omlať",
  "omluv",
  "omotá",
  "omrač",
  "omrkl",
  "omrzl",
  "omrzí",
  "omská",
  "omské",
  "omský",
  "omyje",
  "omyji",
  "omyju",
  "omyjí",
  "omyla",
  "omyli",
  "omylo",
  "omylu",
  "omyly",
  "omylů",
  "omyta",
  "omyti",
  "omyto",
  "omyty",
  "omytá",
  "omyté",
  "omámí",
  "ománu",
  "omílá",
  "omítá",
  "omíté",
  "omítý",
  "omývá",
  "omývé",
  "omývý",
  "onaká",
  "onaké",
  "ondou",
  "ondra",
  "ondru",
  "ondry",
  "oniká",
  "onoho",
  "onomu",
  "onuci",
  "onucí",
  "oněch",
  "oněmi",
  "oněmí",
  "opadl",
  "opadá",
  "opadů",
  "opaku",
  "opaky",
  "opalu",
  "oparu",
  "opary",
  "oparů",
  "opata",
  "opate",
  "opatu",
  "opaty",
  "opatů",
  "opava",
  "opavu",
  "opavy",
  "opavě",
  "opaří",
  "opcím",
  "opekl",
  "operu",
  "opery",
  "opeče",
  "opeču",
  "opeře",
  "opeří",
  "opici",
  "opicí",
  "opiem",
  "opije",
  "opijí",
  "opila",
  "opile",
  "opili",
  "opilo",
  "opily",
  "opilá",
  "opilé",
  "opilí",
  "opise",
  "opisu",
  "opisy",
  "opisů",
  "opitá",
  "opité",
  "opiím",
  "oplať",
  "oplet",
  "oploť",
  "oponu",
  "opony",
  "oponě",
  "oporu",
  "opory",
  "opoře",
  "oprav",
  "opraš",
  "optal",
  "optat",
  "optej",
  "optim",
  "optou",
  "optém",
  "optým",
  "opuce",
  "opuku",
  "opuky",
  "opusu",
  "opusy",
  "opusť",
  "opájí",
  "opálu",
  "opály",
  "opálí",
  "opálů",
  "opáčí",
  "opéká",
  "opéké",
  "opéký",
  "opíjí",
  "opírá",
  "opíré",
  "opírý",
  "opíše",
  "opíší",
  "opěry",
  "opřel",
  "opřen",
  "opřou",
  "orají",
  "orala",
  "orali",
  "oralo",
  "oraly",
  "oraná",
  "orané",
  "orbis",
  "orbou",
  "orgii",
  "orgií",
  "orion",
  "orlem",
  "orlou",
  "orlím",
  "orlům",
  "orlův",
  "ornou",
  "orném",
  "orným",
  "orosí",
  "oruje",
  "orálu",
  "orává",
  "oráče",
  "oráči",
  "oráčů",
  "osadu",
  "osady",
  "osadí",
  "osadě",
  "osahá",
  "osami",
  "oschl",
  "oseje",
  "osejí",
  "oseká",
  "osela",
  "oseli",
  "oselo",
  "osely",
  "oseta",
  "oseto",
  "osety",
  "osetá",
  "oseté",
  "osevu",
  "osevy",
  "osevů",
  "osice",
  "osiku",
  "osiky",
  "osila",
  "osili",
  "osilo",
  "osily",
  "osiva",
  "osivu",
  "osivy",
  "osivě",
  "osiří",
  "oskar",
  "oslab",
  "oslav",
  "oslaď",
  "oslem",
  "oslní",
  "oslou",
  "oslov",
  "oslím",
  "oslům",
  "oslův",
  "osmaž",
  "osmia",
  "osmic",
  "osmin",
  "osmiu",
  "osmií",
  "osmou",
  "osmém",
  "osmým",
  "osměl",
  "osnov",
  "osobo",
  "osobu",
  "osoby",
  "osobě",
  "osolí",
  "osová",
  "osové",
  "osově",
  "osočí",
  "osrat",
  "ostnu",
  "ostny",
  "ostnů",
  "ostro",
  "ostrá",
  "ostré",
  "ostud",
  "ostře",
  "osude",
  "osudu",
  "osudy",
  "osudů",
  "osuší",
  "osviť",
  "osvoj",
  "osvěž",
  "osách",
  "osází",
  "otace",
  "otavu",
  "otavy",
  "otcem",
  "otcům",
  "otcův",
  "otekl",
  "otelí",
  "otepi",
  "otepí",
  "oteče",
  "otice",
  "otkou",
  "otmar",
  "otoku",
  "otoky",
  "otoků",
  "otopu",
  "otovi",
  "otočí",
  "otože",
  "otrav",
  "otrhá",
  "otrká",
  "otrle",
  "otrlá",
  "otrlé",
  "otrlí",
  "otrou",
  "otrub",
  "ottův",
  "otupí",
  "otylá",
  "otylé",
  "otylí",
  "otálí",
  "otáčí",
  "otáže",
  "otáži",
  "otáží",
  "otéci",
  "otéká",
  "otéké",
  "otéký",
  "otíka",
  "otírá",
  "otíré",
  "otírý",
  "otčím",
  "otěru",
  "otěže",
  "otěží",
  "otřel",
  "otřou",
  "oudit",
  "ouhou",
  "ouhým",
  "ouvou",
  "ouzký",
  "ouďme",
  "ouďte",
  "oušek",
  "ouška",
  "oušku",
  "oušky",
  "ovací",
  "ovane",
  "ovaru",
  "ovcím",
  "ovení",
  "ovine",
  "ovinu",
  "oviny",
  "ovitá",
  "ovité",
  "ovsou",
  "ováda",
  "ovádi",
  "ovády",
  "ovádů",
  "oválu",
  "ovály",
  "oválů",
  "ovány",
  "ovátá",
  "ováté",
  "ováže",
  "ováži",
  "ovíjí",
  "ovívá",
  "ovívé",
  "ovívý",
  "ových",
  "ovčím",
  "ovčín",
  "ověsí",
  "ověří",
  "oxide",
  "oxidu",
  "oxidy",
  "oxidů",
  "ozdob",
  "ozimy",
  "ozimá",
  "ozimé",
  "oznam",
  "označ",
  "ozobe",
  "ozonu",
  "ozube",
  "ozuby",
  "ozval",
  "ozveš",
  "ozvou",
  "ozvěn",
  "ozáří",
  "ozónu",
  "ozývá",
  "oázou",
  "oázám",
  "očaři",
  "očařů",
  "očeše",
  "očešu",
  "očitá",
  "očité",
  "očití",
  "očkem",
  "očkou",
  "očkuj",
  "očkům",
  "očnic",
  "očním",
  "ořeme",
  "ořete",
  "ořezu",
  "ořezy",
  "ořezů",
  "ořeže",
  "ořežu",
  "ořích",
  "ořízl",
  "ošatí",
  "ošidí",
  "ošila",
  "ošilo",
  "ošily",
  "ošulí",
  "ošálí",
  "ošívá",
  "oťupe",
  "ožehl",
  "oželí",
  "ožene",
  "oženy",
  "ožení",
  "ožere",
  "ožeru",
  "ožije",
  "ožiji",
  "ožijí",
  "ožila",
  "ožili",
  "ožilo",
  "ožily",
  "ožilá",
  "ožilé",
  "oživl",
  "oživí",
  "ožral",
  "ožírá",
  "ožívá",
  "pablb",
  "pachu",
  "pachy",
  "pachů",
  "packu",
  "packy",
  "packá",
  "packé",
  "packů",
  "pacov",
  "padal",
  "padej",
  "padla",
  "padli",
  "padlo",
  "padly",
  "padlá",
  "padlé",
  "padne",
  "padni",
  "padnu",
  "padou",
  "padrť",
  "padám",
  "padém",
  "paedr",
  "pagod",
  "pakou",
  "paktu",
  "pakty",
  "paktů",
  "pakůň",
  "palau",
  "palaš",
  "palbu",
  "palby",
  "palbě",
  "palce",
  "palci",
  "palců",
  "palem",
  "palet",
  "palic",
  "paliv",
  "palme",
  "palmu",
  "palmy",
  "palmě",
  "palná",
  "palné",
  "palní",
  "palte",
  "palub",
  "pampy",
  "paměť",
  "pandu",
  "pandy",
  "pandí",
  "pandě",
  "panen",
  "panic",
  "panin",
  "panno",
  "pannu",
  "panny",
  "panně",
  "panou",
  "panoš",
  "pantu",
  "panty",
  "pantů",
  "panuj",
  "panza",
  "papež",
  "papou",
  "papua",
  "papém",
  "papým",
  "pariz",
  "parku",
  "parky",
  "parků",
  "parmu",
  "parmy",
  "parmě",
  "parna",
  "parnu",
  "parná",
  "parné",
  "parně",
  "partu",
  "party",
  "partě",
  "partů",
  "paruk",
  "paryb",
  "pasek",
  "pasem",
  "paseš",
  "pasiv",
  "pasou",
  "paste",
  "pasti",
  "pastu",
  "pasty",
  "pastí",
  "pastě",
  "pasuj",
  "pasům",
  "patce",
  "patch",
  "patek",
  "patem",
  "pater",
  "patic",
  "patku",
  "patky",
  "patlá",
  "patou",
  "patra",
  "patrn",
  "patru",
  "patry",
  "patám",
  "patře",
  "patři",
  "patří",
  "pause",
  "pauze",
  "pauzu",
  "pauzy",
  "paušá",
  "paušé",
  "paušý",
  "pavel",
  "pavla",
  "pavle",
  "pavlo",
  "pavlu",
  "pavly",
  "pavím",
  "pačme",
  "pačte",
  "pařan",
  "pařbu",
  "pařby",
  "pařbě",
  "pařeb",
  "pařil",
  "paříš",
  "paříž",
  "pašuj",
  "pažbu",
  "pažby",
  "pažbě",
  "pažeb",
  "pecce",
  "pecek",
  "pechů",
  "pecku",
  "pecky",
  "pecmi",
  "pecnu",
  "pecny",
  "pecní",
  "pecnů",
  "pedel",
  "pegas",
  "pekel",
  "pekla",
  "pekle",
  "pekli",
  "peklu",
  "pekly",
  "pelet",
  "pemze",
  "pemzu",
  "pemzy",
  "pencí",
  "pendl",
  "penzi",
  "penzí",
  "peněž",
  "pepou",
  "pepsi",
  "pepík",
  "pepře",
  "pepři",
  "pepův",
  "perel",
  "perem",
  "pereš",
  "perle",
  "perlu",
  "perly",
  "perlí",
  "perme",
  "permu",
  "perná",
  "perné",
  "perně",
  "perou",
  "perry",
  "perte",
  "peruc",
  "perun",
  "perům",
  "pesem",
  "peset",
  "petic",
  "petou",
  "petra",
  "petro",
  "petru",
  "petry",
  "petře",
  "pevná",
  "pevné",
  "pevní",
  "pevně",
  "pexes",
  "pečen",
  "pečeš",
  "pečiv",
  "pečky",
  "pečme",
  "pečou",
  "pečte",
  "pečuj",
  "peřin",
  "peřím",
  "pešti",
  "phare",
  "piana",
  "picou",
  "piety",
  "pihel",
  "pihou",
  "pihám",
  "piješ",
  "pijme",
  "pijou",
  "pijte",
  "piket",
  "pikol",
  "pikou",
  "pilin",
  "pilku",
  "pilky",
  "pilná",
  "pilné",
  "pilní",
  "pilně",
  "pilon",
  "pilos",
  "pilou",
  "piluj",
  "pilyn",
  "pilys",
  "pilát",
  "pinem",
  "pingl",
  "pingu",
  "pingy",
  "pingů",
  "pinká",
  "pinké",
  "pinký",
  "pintu",
  "pinty",
  "pintě",
  "pinům",
  "pipet",
  "pitek",
  "pitev",
  "pitku",
  "pitky",
  "pitná",
  "pitné",
  "pitný",
  "pitou",
  "pitvu",
  "pitvy",
  "pitvá",
  "pitvé",
  "pitvý",
  "pitvě",
  "pitém",
  "pitím",
  "pitín",
  "pitým",
  "pivem",
  "pivka",
  "pivně",
  "pivou",
  "pivín",
  "pivům",
  "pizze",
  "pizzi",
  "pizzu",
  "pizzy",
  "piáno",
  "pičín",
  "pišme",
  "pište",
  "pižem",
  "pižlá",
  "pižma",
  "pižmu",
  "pižmy",
  "pižmě",
  "pkojf",
  "place",
  "plach",
  "placu",
  "plaku",
  "plane",
  "planá",
  "plané",
  "planě",
  "plasy",
  "plata",
  "platm",
  "platu",
  "platy",
  "platí",
  "plató",
  "platě",
  "platů",
  "plave",
  "plavu",
  "plavy",
  "plavá",
  "plavé",
  "plaví",
  "plavě",
  "plaza",
  "plazi",
  "plazy",
  "plazí",
  "plazů",
  "plaše",
  "plaší",
  "plece",
  "plecí",
  "pleje",
  "plejí",
  "plela",
  "pleli",
  "plelo",
  "plely",
  "plenu",
  "pleny",
  "pleně",
  "plesa",
  "plese",
  "plesu",
  "plesy",
  "plesů",
  "plete",
  "pleti",
  "pletl",
  "pletu",
  "pletá",
  "pleté",
  "plevy",
  "pleše",
  "pleši",
  "pleší",
  "plive",
  "plivl",
  "plivu",
  "plnil",
  "plniv",
  "plnou",
  "plném",
  "plním",
  "plníš",
  "plným",
  "plněn",
  "ploch",
  "plodu",
  "plody",
  "plodí",
  "plodů",
  "plomb",
  "plotu",
  "ploty",
  "plotě",
  "plotů",
  "plove",
  "ploše",
  "plsti",
  "plstí",
  "pltní",
  "pluhu",
  "pluhy",
  "pluhů",
  "pluje",
  "pluji",
  "pluju",
  "plují",
  "pluku",
  "pluky",
  "pluků",
  "plula",
  "pluli",
  "plulo",
  "pluly",
  "plusu",
  "plusy",
  "plusů",
  "pluta",
  "pluto",
  "plutu",
  "plutá",
  "pluté",
  "plymó",
  "plyne",
  "plynu",
  "plyny",
  "plynů",
  "plyše",
  "plzeň",
  "plzni",
  "plzní",
  "plzně",
  "pláce",
  "plácl",
  "plácá",
  "plácé",
  "plácý",
  "plála",
  "plálo",
  "plály",
  "pláme",
  "pláne",
  "pláni",
  "plánu",
  "plány",
  "plánů",
  "pláte",
  "plátu",
  "pláty",
  "plátů",
  "pláče",
  "pláči",
  "pláču",
  "pláčí",
  "pláčů",
  "plášť",
  "pláže",
  "pláži",
  "pláží",
  "plédu",
  "plédy",
  "plédů",
  "plého",
  "plému",
  "pléna",
  "plénu",
  "plény",
  "plíci",
  "plína",
  "plínu",
  "plíny",
  "plíží",
  "plých",
  "plýma",
  "plými",
  "plňte",
  "plžem",
  "plžní",
  "pnutá",
  "pnuté",
  "pobav",
  "pobij",
  "pobil",
  "pobyv",
  "poběž",
  "pociť",
  "pocti",
  "poctu",
  "pocty",
  "poctí",
  "poctě",
  "podal",
  "podař",
  "poddá",
  "poddé",
  "poddý",
  "podlá",
  "podlé",
  "podlí",
  "podob",
  "podol",
  "podou",
  "podrž",
  "podus",
  "podám",
  "podán",
  "podáš",
  "podém",
  "podým",
  "poeta",
  "pohad",
  "pohan",
  "pohla",
  "pohli",
  "pohlo",
  "pohly",
  "pohlť",
  "pohne",
  "pohni",
  "pohnu",
  "pohod",
  "pohoď",
  "pohře",
  "point",
  "pojda",
  "pojde",
  "pojeď",
  "pojil",
  "pojiv",
  "pojme",
  "pojmy",
  "pojmů",
  "pojou",
  "pojíš",
  "pokaz",
  "pokec",
  "pokoř",
  "pokru",
  "pokrč",
  "pokut",
  "pokyň",
  "pokál",
  "polap",
  "polce",
  "polda",
  "poldu",
  "poldy",
  "poldů",
  "polek",
  "polem",
  "polen",
  "polep",
  "polev",
  "polez",
  "polib",
  "polic",
  "polil",
  "polkl",
  "polku",
  "polky",
  "polná",
  "polné",
  "polný",
  "polně",
  "poloh",
  "polou",
  "polov",
  "polož",
  "polím",
  "pomal",
  "pomaž",
  "pomiň",
  "pomlk",
  "pomni",
  "pomní",
  "pomoz",
  "pomož",
  "pompu",
  "pomra",
  "pomum",
  "poměř",
  "pomům",
  "poniž",
  "ponku",
  "ponoř",
  "ponte",
  "poníž",
  "popal",
  "popem",
  "poper",
  "popil",
  "popiš",
  "poptá",
  "popře",
  "popři",
  "popřu",
  "popům",
  "popův",
  "porad",
  "poraz",
  "poraď",
  "porci",
  "porcí",
  "porna",
  "pornu",
  "porok",
  "porot",
  "porto",
  "portu",
  "porty",
  "portů",
  "poruč",
  "poruš",
  "porve",
  "porvu",
  "posaď",
  "posel",
  "poser",
  "posez",
  "poseč",
  "poseď",
  "posla",
  "posle",
  "poslu",
  "posly",
  "poslů",
  "pospí",
  "posti",
  "postu",
  "posty",
  "postí",
  "postů",
  "posuď",
  "posuň",
  "potem",
  "potil",
  "potká",
  "potké",
  "potký",
  "potop",
  "potou",
  "potra",
  "potím",
  "potíš",
  "potěš",
  "potře",
  "potři",
  "potřu",
  "pouce",
  "pouhá",
  "pouhé",
  "poulí",
  "pouta",
  "pouti",
  "poutu",
  "pouty",
  "poutá",
  "pouté",
  "poutí",
  "poutý",
  "poutě",
  "pouzí",
  "pouče",
  "poučí",
  "povah",
  "povař",
  "považ",
  "povol",
  "povál",
  "povím",
  "povíš",
  "pověr",
  "pověs",
  "pověz",
  "pověř",
  "pozic",
  "pozná",
  "pozné",
  "pozný",
  "pozou",
  "pozve",
  "pozvi",
  "pozvu",
  "pozře",
  "pozři",
  "pozřu",
  "poémy",
  "počal",
  "počká",
  "počké",
  "počký",
  "počne",
  "počnu",
  "počtu",
  "počty",
  "počtů",
  "pořez",
  "pořež",
  "pořiď",
  "pošla",
  "pošle",
  "pošli",
  "pošlo",
  "pošly",
  "pošlá",
  "pošlé",
  "pošto",
  "poštu",
  "pošty",
  "poště",
  "požeň",
  "požil",
  "požit",
  "požít",
  "pplpk",
  "pprap",
  "ppruh",
  "prace",
  "prací",
  "praga",
  "praha",
  "prahl",
  "praku",
  "praky",
  "praků",
  "prala",
  "prali",
  "pralo",
  "praly",
  "praná",
  "prané",
  "pravd",
  "pravá",
  "pravé",
  "praví",
  "pravě",
  "praxi",
  "praxí",
  "praze",
  "prašť",
  "praže",
  "praží",
  "prcek",
  "prchl",
  "prchá",
  "prché",
  "prchý",
  "prcka",
  "prcku",
  "prcky",
  "prcků",
  "prdem",
  "prdím",
  "prdíš",
  "prdět",
  "presl",
  "presu",
  "prhou",
  "primu",
  "primy",
  "primě",
  "princ",
  "print",
  "prion",
  "prior",
  "prius",
  "prken",
  "prkna",
  "prknu",
  "prkny",
  "prkně",
  "prodá",
  "prodé",
  "prodý",
  "prohl",
  "proje",
  "prosa",
  "prost",
  "prosu",
  "prosí",
  "prouč",
  "prouď",
  "prsař",
  "prsem",
  "prskl",
  "prská",
  "prsti",
  "prstu",
  "prsty",
  "prstí",
  "prstě",
  "prstů",
  "prsům",
  "prudí",
  "pruhu",
  "pruhy",
  "pruhů",
  "prute",
  "prutu",
  "pruty",
  "prutů",
  "pruží",
  "prvku",
  "prvky",
  "prvků",
  "prvně",
  "prvou",
  "prvém",
  "prvým",
  "prysk",
  "pryže",
  "pryži",
  "pryží",
  "przní",
  "prácí",
  "prámu",
  "prámy",
  "práno",
  "práva",
  "právu",
  "právy",
  "práče",
  "práše",
  "práší",
  "prému",
  "próze",
  "prózu",
  "prózy",
  "prých",
  "prýma",
  "prými",
  "prčic",
  "psala",
  "psali",
  "psalo",
  "psaly",
  "psaná",
  "psané",
  "psavá",
  "psavé",
  "psoty",
  "pspad",
  "psych",
  "psána",
  "psáno",
  "psány",
  "psáná",
  "psáné",
  "psáný",
  "psáry",
  "psích",
  "psíci",
  "psího",
  "psíka",
  "psíky",
  "psíků",
  "psíma",
  "psími",
  "psímu",
  "ptají",
  "ptala",
  "ptali",
  "ptalo",
  "ptaly",
  "ptačí",
  "ptení",
  "ptáci",
  "ptáka",
  "ptáku",
  "ptáky",
  "ptáků",
  "ptáme",
  "ptáte",
  "ptého",
  "ptému",
  "ptých",
  "ptýma",
  "ptými",
  "puchu",
  "pudem",
  "pudla",
  "pudly",
  "pudlí",
  "pudlů",
  "pudru",
  "pudry",
  "pudrů",
  "puget",
  "pukal",
  "pukem",
  "pukla",
  "pukli",
  "puklo",
  "pukly",
  "puklá",
  "puklé",
  "pukne",
  "puknu",
  "pukou",
  "pukém",
  "pukým",
  "pulce",
  "pulci",
  "pulců",
  "pulsu",
  "pulsy",
  "pulsů",
  "pultu",
  "pulty",
  "pultě",
  "pultů",
  "pulzu",
  "pulzy",
  "pulzů",
  "pumou",
  "pumpu",
  "pumpy",
  "pumpě",
  "pumám",
  "puncu",
  "puncy",
  "punku",
  "punče",
  "punči",
  "pupku",
  "pupky",
  "pusou",
  "pustl",
  "pusto",
  "pustá",
  "pusté",
  "pustí",
  "pustý",
  "pustě",
  "putny",
  "putně",
  "putyk",
  "pučel",
  "pučem",
  "pušce",
  "pušek",
  "puško",
  "pušku",
  "pušky",
  "pvari",
  "pychu",
  "pyjem",
  "pykal",
  "pykej",
  "pykou",
  "pykám",
  "pykém",
  "pykým",
  "pylem",
  "pylům",
  "pysku",
  "pysky",
  "pysků",
  "pyšni",
  "pyšná",
  "pyšné",
  "pyšní",
  "pyšně",
  "pyžam",
  "pyžmo",
  "páchl",
  "páchá",
  "páché",
  "páchý",
  "pádel",
  "pádem",
  "pádil",
  "pádla",
  "pádle",
  "pádlu",
  "pádly",
  "pádná",
  "pádné",
  "pádně",
  "pádím",
  "pádíš",
  "pádům",
  "pájce",
  "pájek",
  "pájel",
  "pájku",
  "pájky",
  "pájím",
  "pájíš",
  "pákou",
  "pákám",
  "pálce",
  "pálek",
  "pálil",
  "pálku",
  "pálky",
  "pálím",
  "pálíš",
  "pánve",
  "pánvi",
  "pánví",
  "pánův",
  "pápou",
  "pápém",
  "pápým",
  "páral",
  "pária",
  "párii",
  "párií",
  "párku",
  "párky",
  "párků",
  "párou",
  "pásce",
  "pásem",
  "pásku",
  "pásky",
  "pásků",
  "pásla",
  "pásli",
  "páslo",
  "pásly",
  "pásma",
  "pásmu",
  "pásmy",
  "pásům",
  "páter",
  "pátka",
  "pátku",
  "pátky",
  "pátků",
  "pátou",
  "pátrá",
  "pátré",
  "pátrý",
  "pátém",
  "pátým",
  "pávek",
  "pávem",
  "pávou",
  "pávům",
  "páčce",
  "páček",
  "páčil",
  "páčku",
  "páčky",
  "páčím",
  "páčíš",
  "pářeš",
  "pářil",
  "pářou",
  "pášeš",
  "páťák",
  "pérek",
  "pérem",
  "pérka",
  "pérky",
  "pérům",
  "pétin",
  "péťův",
  "pícek",
  "píchl",
  "píchá",
  "píché",
  "píchý",
  "pícky",
  "pídil",
  "pídím",
  "píkou",
  "pílou",
  "pípal",
  "pípla",
  "pípne",
  "pípni",
  "pípot",
  "pípou",
  "pípák",
  "pípám",
  "pípém",
  "pípým",
  "pírka",
  "pírky",
  "písem",
  "písku",
  "písky",
  "píská",
  "píské",
  "píský",
  "písků",
  "písma",
  "písmu",
  "písmy",
  "písmě",
  "písni",
  "písní",
  "písně",
  "pístu",
  "písty",
  "pístů",
  "pítka",
  "píšem",
  "píšeš",
  "píšou",
  "pódia",
  "pódii",
  "pódiu",
  "pódií",
  "pólem",
  "pólům",
  "pórek",
  "pórem",
  "pórku",
  "pórky",
  "pórům",
  "pózou",
  "pózuj",
  "pózám",
  "pýchu",
  "pýchy",
  "pýrem",
  "pýřil",
  "pýřím",
  "pýříš",
  "pějou",
  "pěkná",
  "pěkné",
  "pěkní",
  "pěkně",
  "pěnic",
  "pěnil",
  "pěnou",
  "pěsti",
  "pěstí",
  "pětce",
  "pětek",
  "pětic",
  "pětin",
  "pětko",
  "pětku",
  "pětky",
  "pěvce",
  "pěvci",
  "pěvců",
  "pěvče",
  "pěčín",
  "pěšce",
  "pěšci",
  "pěšců",
  "pěšin",
  "pěším",
  "přaná",
  "přané",
  "přaný",
  "předl",
  "předu",
  "předá",
  "předý",
  "přeje",
  "přeji",
  "přeju",
  "přejí",
  "přela",
  "přeli",
  "přelo",
  "přely",
  "přeme",
  "přemi",
  "přeni",
  "přeno",
  "přené",
  "přete",
  "přeuč",
  "přidá",
  "přidé",
  "přijď",
  "přiuč",
  "přivá",
  "přivé",
  "přála",
  "přáli",
  "přálo",
  "přály",
  "přáni",
  "přáno",
  "přáti",
  "přídi",
  "přídí",
  "přídě",
  "přímá",
  "přímé",
  "přímí",
  "přímě",
  "příze",
  "přízi",
  "přízí",
  "příčí",
  "půdně",
  "půdou",
  "půdám",
  "půjde",
  "půjdu",
  "půjčí",
  "půlce",
  "půlek",
  "půlen",
  "půlil",
  "půlku",
  "půlky",
  "půlou",
  "půlím",
  "půlíš",
  "působ",
  "půstu",
  "půsty",
  "půstů",
  "půtce",
  "půtek",
  "půtku",
  "půtky",
  "quasi",
  "rabou",
  "racci",
  "racka",
  "racku",
  "racky",
  "racků",
  "racčí",
  "radan",
  "radce",
  "radek",
  "radia",
  "radil",
  "radim",
  "radio",
  "radiu",
  "radií",
  "radka",
  "radko",
  "radku",
  "radky",
  "radní",
  "radou",
  "raduj",
  "raduň",
  "radám",
  "radím",
  "radíš",
  "radúz",
  "raděj",
  "radši",
  "rafik",
  "raftu",
  "raftů",
  "rajce",
  "rakem",
  "raket",
  "rakou",
  "rakve",
  "rakvi",
  "rakví",
  "rakům",
  "rakův",
  "ramen",
  "rampu",
  "rampy",
  "rampě",
  "ranců",
  "rande",
  "randí",
  "ranil",
  "ranku",
  "ranky",
  "ranná",
  "ranné",
  "ranný",
  "ranně",
  "ranou",
  "raném",
  "raník",
  "raním",
  "raníš",
  "raným",
  "ranče",
  "ranči",
  "rančů",
  "raněn",
  "rapem",
  "raptů",
  "rarit",
  "rasem",
  "rasou",
  "raste",
  "rastu",
  "rasám",
  "rasův",
  "ratou",
  "rautu",
  "rauty",
  "rautů",
  "rauše",
  "razii",
  "razil",
  "razit",
  "razií",
  "razím",
  "račte",
  "račím",
  "raďme",
  "raďte",
  "rašil",
  "ražbu",
  "ražby",
  "ražbě",
  "ražeb",
  "rceme",
  "rcete",
  "rdram",
  "rděla",
  "rdělo",
  "rděly",
  "rebel",
  "regia",
  "rehka",
  "rehky",
  "rehků",
  "rejdu",
  "rejdy",
  "rejdí",
  "rejdě",
  "rejdů",
  "rejem",
  "rejou",
  "rekah",
  "rekou",
  "rekův",
  "remcá",
  "rentu",
  "renty",
  "rentě",
  "repra",
  "reptá",
  "repté",
  "reptý",
  "rerum",
  "reset",
  "restu",
  "resty",
  "restů",
  "retná",
  "retné",
  "reveň",
  "rezek",
  "rezií",
  "rezne",
  "reálu",
  "režii",
  "režií",
  "režná",
  "režné",
  "rican",
  "rikši",
  "rimem",
  "rimou",
  "ringu",
  "ringy",
  "ringů",
  "riová",
  "riové",
  "riskl",
  "risku",
  "ritou",
  "rival",
  "rizik",
  "rizot",
  "rmenu",
  "rmutu",
  "rmuty",
  "robil",
  "robin",
  "rocku",
  "rodea",
  "rodem",
  "rodeo",
  "rodeu",
  "rodil",
  "rodin",
  "rodič",
  "rodná",
  "rodné",
  "rodím",
  "rodíš",
  "rodům",
  "rohan",
  "rohem",
  "rohle",
  "rohou",
  "rohům",
  "rojem",
  "rojil",
  "rojou",
  "rojům",
  "rokli",
  "roklí",
  "rokou",
  "rokům",
  "rolbu",
  "rolby",
  "rolbě",
  "rolet",
  "rolex",
  "rolky",
  "rolní",
  "rolou",
  "roluj",
  "rolád",
  "rolím",
  "roman",
  "romce",
  "romek",
  "romem",
  "romeo",
  "romka",
  "romku",
  "romky",
  "romům",
  "rondó",
  "ronil",
  "ronov",
  "roním",
  "roníš",
  "ropná",
  "ropné",
  "ropní",
  "ropou",
  "ropák",
  "rorýs",
  "roseč",
  "rosná",
  "rosné",
  "rosou",
  "rosta",
  "roste",
  "rosťa",
  "rotná",
  "rotné",
  "rotní",
  "rotný",
  "rotou",
  "rotuj",
  "rotám",
  "roubu",
  "rouby",
  "roubí",
  "roubů",
  "rouch",
  "rouhá",
  "rouna",
  "rounu",
  "rouně",
  "roupu",
  "roupy",
  "roupů",
  "rouru",
  "roury",
  "routu",
  "routy",
  "rouře",
  "rouše",
  "roven",
  "rovin",
  "rovna",
  "rovni",
  "rovno",
  "rovnu",
  "rovny",
  "rovná",
  "rovné",
  "rovní",
  "rovně",
  "rozdá",
  "rozdý",
  "rozet",
  "ročně",
  "ročov",
  "roďte",
  "roštu",
  "rošty",
  "roštů",
  "rošád",
  "rožeň",
  "rožni",
  "rožná",
  "rožné",
  "rožní",
  "rožný",
  "rožně",
  "rožňů",
  "rtech",
  "rtuti",
  "rtutí",
  "rtutě",
  "rtyně",
  "rubal",
  "rubem",
  "ruble",
  "rublu",
  "rubly",
  "rublů",
  "rubou",
  "rubém",
  "rubým",
  "ruchu",
  "ruchy",
  "ruchů",
  "rudla",
  "rudli",
  "rudlo",
  "rudly",
  "rudne",
  "rudnu",
  "rudná",
  "rudné",
  "rudní",
  "rudou",
  "rudám",
  "rudém",
  "rudým",
  "rudší",
  "rudův",
  "rugby",
  "ruhou",
  "ruhém",
  "ruhým",
  "ruinu",
  "ruiny",
  "ruině",
  "rujná",
  "rujné",
  "rukou",
  "rukám",
  "rulet",
  "rulou",
  "rulám",
  "rumem",
  "rumun",
  "rumům",
  "rundu",
  "runou",
  "rupií",
  "rupla",
  "rupli",
  "ruplo",
  "ruply",
  "rupne",
  "rusce",
  "rusek",
  "rusem",
  "ruska",
  "rusko",
  "rusku",
  "rusky",
  "ruská",
  "ruské",
  "rusou",
  "ruste",
  "rusuv",
  "rusém",
  "rusým",
  "rusům",
  "rusův",
  "rutin",
  "ručce",
  "ručej",
  "ručen",
  "ručil",
  "ručku",
  "ručky",
  "ručme",
  "ručně",
  "ručte",
  "ručíc",
  "ručím",
  "ručíš",
  "rušen",
  "rušic",
  "rušil",
  "rušme",
  "rušno",
  "rušná",
  "rušné",
  "rušně",
  "rušte",
  "ruští",
  "ruším",
  "rušíš",
  "rvala",
  "rvali",
  "rvalo",
  "rvaly",
  "rvaná",
  "rvané",
  "rvavá",
  "rvavé",
  "rvete",
  "rváče",
  "rváči",
  "rváčů",
  "rvěte",
  "rybař",
  "rybce",
  "rybek",
  "rybku",
  "rybky",
  "rybná",
  "rybné",
  "rybný",
  "rybou",
  "rybám",
  "rybím",
  "rydel",
  "rydla",
  "rydlu",
  "rydly",
  "ryješ",
  "ryjou",
  "rykem",
  "rylou",
  "rynku",
  "rypná",
  "rypné",
  "rypný",
  "rysce",
  "rysek",
  "rysem",
  "rysku",
  "rysky",
  "rysou",
  "rysům",
  "rysův",
  "rytce",
  "rytci",
  "rytců",
  "rytin",
  "rytmu",
  "rytmy",
  "rytmů",
  "rytou",
  "rytém",
  "rytím",
  "rytíř",
  "rytým",
  "ryzku",
  "ryzím",
  "ryčel",
  "ryčná",
  "ryčné",
  "ryčně",
  "ryčím",
  "ryčíš",
  "ryška",
  "rzivá",
  "rzivé",
  "rádce",
  "rádci",
  "rádců",
  "rádia",
  "rádii",
  "rádiu",
  "rádií",
  "rádou",
  "rádža",
  "ráfku",
  "ráfky",
  "ráfků",
  "ráhen",
  "ráhna",
  "ráhnu",
  "ráhny",
  "ráhně",
  "rájci",
  "rájec",
  "rájem",
  "rájou",
  "rájům",
  "rámce",
  "rámců",
  "rámem",
  "rámku",
  "rámky",
  "rámků",
  "rámou",
  "rámům",
  "ránem",
  "ránou",
  "ránům",
  "rátit",
  "rátán",
  "rázná",
  "rázné",
  "rázní",
  "rázně",
  "rázům",
  "ráčci",
  "ráčil",
  "ráčka",
  "ráčku",
  "ráčky",
  "ráčků",
  "ráčím",
  "rážím",
  "rémou",
  "révou",
  "ríšův",
  "róbou",
  "róbám",
  "rútův",
  "rýhou",
  "rýhám",
  "rýmem",
  "rýmou",
  "rýmům",
  "rýnem",
  "rýnou",
  "rýpal",
  "rýpat",
  "rýpeš",
  "rýpla",
  "rýpli",
  "rýplo",
  "rýply",
  "rýpne",
  "rýpni",
  "rýpnu",
  "rýpou",
  "rýsek",
  "rýsuj",
  "rýčem",
  "rýčům",
  "rýžku",
  "rýžuj",
  "růstu",
  "růsty",
  "růstů",
  "různá",
  "různé",
  "různí",
  "různě",
  "růžic",
  "růžku",
  "růžky",
  "růžků",
  "růžné",
  "růžím",
  "ržené",
  "ržála",
  "ržáli",
  "ržálo",
  "ržály",
  "sacím",
  "sadbu",
  "sadby",
  "sadbě",
  "sadem",
  "sadou",
  "sadov",
  "sadám",
  "sahal",
  "sahou",
  "sahám",
  "sahém",
  "sahým",
  "saješ",
  "sajte",
  "sajtu",
  "sakem",
  "sakou",
  "sakru",
  "sakur",
  "salda",
  "saldu",
  "saldy",
  "saldě",
  "salmu",
  "salta",
  "saltu",
  "salty",
  "salut",
  "salvu",
  "salvy",
  "salvě",
  "sambu",
  "samby",
  "samce",
  "samci",
  "samců",
  "samic",
  "samoa",
  "samot",
  "samčí",
  "sanic",
  "sanyo",
  "saním",
  "sarce",
  "sarka",
  "sarky",
  "saska",
  "sasko",
  "sasku",
  "saská",
  "saské",
  "saský",
  "sasův",
  "satan",
  "satir",
  "satyr",
  "saunu",
  "sauny",
  "sauně",
  "savce",
  "savci",
  "savců",
  "savou",
  "savém",
  "savým",
  "savčí",
  "sazbu",
  "sazby",
  "sazbě",
  "sazeb",
  "sazím",
  "sašin",
  "sašou",
  "sašův",
  "sbalí",
  "sbitá",
  "sbité",
  "sbliž",
  "sboru",
  "sbory",
  "sborů",
  "sbíhá",
  "sbírá",
  "sbíré",
  "sbírý",
  "sběhl",
  "sběhy",
  "sběru",
  "sběry",
  "sběrů",
  "scart",
  "scedí",
  "schla",
  "schne",
  "schyl",
  "scott",
  "scénu",
  "scény",
  "scéně",
  "sdram",
  "sdílí",
  "sdírá",
  "sdělí",
  "seato",
  "seber",
  "secam",
  "secím",
  "sedal",
  "sedej",
  "sedel",
  "sedil",
  "sediu",
  "sedla",
  "sedle",
  "sedli",
  "sedlu",
  "sedly",
  "sedlá",
  "sedlé",
  "sedmi",
  "sedmo",
  "sedmá",
  "sedmé",
  "sedmí",
  "sedmý",
  "sedne",
  "sedni",
  "sednu",
  "sedou",
  "sedra",
  "sedám",
  "sedíc",
  "sedím",
  "sedíš",
  "seděl",
  "seděn",
  "sedře",
  "sedři",
  "sedřu",
  "sehla",
  "sehne",
  "sehni",
  "sehnu",
  "seiko",
  "sejda",
  "sejde",
  "sejdi",
  "sejfu",
  "sejfy",
  "sejfů",
  "sejme",
  "sejmi",
  "sejmu",
  "sejou",
  "sekal",
  "sekci",
  "sekcí",
  "sekej",
  "sekem",
  "seker",
  "sekla",
  "sekli",
  "seklo",
  "sekly",
  "sekne",
  "sekni",
  "seknu",
  "sekou",
  "sektu",
  "sekty",
  "sektě",
  "sektů",
  "sekám",
  "sekán",
  "sekáč",
  "sekém",
  "sekým",
  "sekům",
  "selat",
  "selky",
  "selou",
  "selže",
  "semel",
  "semen",
  "semil",
  "semkl",
  "senem",
  "senes",
  "senná",
  "senné",
  "senou",
  "senům",
  "sepiš",
  "sepne",
  "sepni",
  "sepnu",
  "sepsi",
  "sepsí",
  "sereš",
  "serou",
  "serve",
  "servi",
  "servu",
  "sesul",
  "sesuň",
  "setbu",
  "setby",
  "setbě",
  "setem",
  "setin",
  "setká",
  "setmí",
  "setne",
  "setni",
  "setou",
  "setra",
  "setém",
  "setím",
  "setým",
  "setře",
  "setři",
  "setřu",
  "setům",
  "sevře",
  "sevři",
  "sevřu",
  "sexem",
  "sextu",
  "sexty",
  "sextě",
  "sezve",
  "sezón",
  "sečeš",
  "sečnu",
  "sečny",
  "sečná",
  "sečné",
  "sečně",
  "sečou",
  "sečte",
  "sečti",
  "sečtu",
  "seďme",
  "seďte",
  "seňor",
  "seřaď",
  "seřiď",
  "seřve",
  "seřvu",
  "sešij",
  "sešil",
  "sešla",
  "sešle",
  "sešli",
  "sešlo",
  "sešly",
  "sešlá",
  "sešlé",
  "sešlí",
  "sežer",
  "sežeň",
  "sfing",
  "sfinx",
  "sfárá",
  "sféru",
  "sféry",
  "shift",
  "shnil",
  "shodl",
  "shodu",
  "shody",
  "shodí",
  "shodě",
  "shonu",
  "shopu",
  "shopy",
  "shopů",
  "shozů",
  "shoří",
  "shrbí",
  "shrne",
  "shrnu",
  "shyby",
  "shybů",
  "shání",
  "shází",
  "shýbl",
  "shýbá",
  "shýbé",
  "shýbý",
  "sibir",
  "sibiř",
  "sichr",
  "signa",
  "silem",
  "silic",
  "silná",
  "silné",
  "silní",
  "silně",
  "silvo",
  "silvu",
  "silvy",
  "silvě",
  "silům",
  "simon",
  "simča",
  "sinic",
  "sinou",
  "siném",
  "siným",
  "sipvz",
  "sirek",
  "sirem",
  "siric",
  "sirku",
  "sirky",
  "sirná",
  "sirné",
  "sirob",
  "sirot",
  "sirou",
  "sirém",
  "sirén",
  "sirým",
  "situa",
  "sitům",
  "sivou",
  "sivém",
  "sivým",
  "sivěn",
  "siříš",
  "sjede",
  "sjela",
  "sjeli",
  "sjelo",
  "sjely",
  "sjetá",
  "sjeté",
  "skací",
  "skaná",
  "skané",
  "skenu",
  "skeny",
  "skenů",
  "skeče",
  "skeči",
  "skečů",
  "skice",
  "skici",
  "skicu",
  "skifu",
  "skify",
  "skini",
  "skinu",
  "skiny",
  "skinů",
  "sklem",
  "skliď",
  "sklop",
  "sklou",
  "skloň",
  "sklům",
  "skobo",
  "skobu",
  "skoby",
  "skobě",
  "skoku",
  "skoky",
  "skoků",
  "skolí",
  "skont",
  "skonu",
  "skoná",
  "skonč",
  "skope",
  "skopl",
  "skosí",
  "skota",
  "skoty",
  "skotů",
  "skout",
  "skočí",
  "skryj",
  "skryl",
  "skrčí",
  "skunk",
  "skučí",
  "skvrn",
  "skvěl",
  "skácí",
  "skále",
  "skálu",
  "skály",
  "skáze",
  "skáče",
  "skáču",
  "skáčí",
  "ského",
  "skému",
  "skóre",
  "ských",
  "skýma",
  "skými",
  "skýtá",
  "skýté",
  "skýtý",
  "skývu",
  "skývy",
  "skývě",
  "skřín",
  "slabo",
  "slabá",
  "slabé",
  "slabí",
  "slabý",
  "slabě",
  "sladu",
  "sladí",
  "slaná",
  "slané",
  "slaní",
  "slaně",
  "slapy",
  "slapů",
  "slaví",
  "sledi",
  "sledu",
  "sledy",
  "sledě",
  "sledů",
  "slepá",
  "slepé",
  "slepí",
  "slepě",
  "sletu",
  "slety",
  "sletí",
  "sletů",
  "slevu",
  "slevy",
  "sleví",
  "slevě",
  "sleze",
  "slezl",
  "slezu",
  "sleďů",
  "slibu",
  "sliby",
  "slibů",
  "slide",
  "slije",
  "slijí",
  "slila",
  "slili",
  "slilo",
  "slily",
  "slinu",
  "sliny",
  "sliní",
  "slipů",
  "slitá",
  "slité",
  "slizu",
  "slizy",
  "sloce",
  "slohu",
  "slohy",
  "slohů",
  "sloje",
  "sloji",
  "slojí",
  "sloku",
  "sloky",
  "slona",
  "slone",
  "sloni",
  "slonu",
  "slony",
  "slonů",
  "slotu",
  "sloty",
  "slotě",
  "slotů",
  "slova",
  "slovu",
  "slovy",
  "slově",
  "sloze",
  "složí",
  "sluce",
  "sluha",
  "sluho",
  "sluhu",
  "sluhy",
  "sluhů",
  "sluje",
  "sluji",
  "sluku",
  "sluky",
  "slumů",
  "sluní",
  "slupl",
  "slučí",
  "sluší",
  "slyší",
  "slzel",
  "slzet",
  "slzná",
  "slzné",
  "slzou",
  "slzám",
  "slzím",
  "slzíš",
  "slábl",
  "sládl",
  "sládě",
  "slámu",
  "slámy",
  "slámě",
  "slávo",
  "slávu",
  "slávy",
  "slávě",
  "slétl",
  "slétá",
  "slévá",
  "slévé",
  "slévý",
  "slézá",
  "slézé",
  "slézý",
  "slíbí",
  "slídu",
  "slídy",
  "slídí",
  "slídě",
  "slínu",
  "slívu",
  "slíže",
  "slížu",
  "smavá",
  "smavé",
  "smaže",
  "smažu",
  "smaží",
  "smekl",
  "smeká",
  "smeké",
  "smeký",
  "smete",
  "smetl",
  "smetu",
  "smetá",
  "smeče",
  "smeči",
  "smečí",
  "smlsl",
  "smoce",
  "smogu",
  "smoly",
  "smotá",
  "smočí",
  "smrdí",
  "smrku",
  "smrky",
  "smrká",
  "smrků",
  "smrže",
  "smrži",
  "smržů",
  "smsek",
  "smsku",
  "smsky",
  "smyje",
  "smyji",
  "smyjí",
  "smyku",
  "smyky",
  "smyků",
  "smyla",
  "smyli",
  "smylo",
  "smyly",
  "smytá",
  "smyté",
  "smála",
  "smáli",
  "smálo",
  "smály",
  "smázl",
  "smáčí",
  "smíme",
  "smíru",
  "smíry",
  "smírů",
  "smísí",
  "smíte",
  "smíří",
  "smýkl",
  "smýká",
  "smývá",
  "smýčí",
  "směje",
  "směji",
  "směju",
  "smějí",
  "směla",
  "směle",
  "směli",
  "smělo",
  "směly",
  "smělá",
  "smělé",
  "smělí",
  "směnu",
  "směny",
  "směně",
  "směre",
  "směru",
  "směry",
  "směrů",
  "směsi",
  "směsí",
  "smůle",
  "smůlu",
  "smůly",
  "snahu",
  "snahy",
  "snaze",
  "snaše",
  "snaže",
  "snaží",
  "snech",
  "snese",
  "snesl",
  "snesu",
  "snila",
  "snili",
  "snilo",
  "snily",
  "snivá",
  "snivé",
  "snoba",
  "snobi",
  "snoby",
  "snobů",
  "snopu",
  "snopy",
  "snopů",
  "snová",
  "snové",
  "snově",
  "snáze",
  "snáře",
  "snářů",
  "snáší",
  "snídá",
  "sníme",
  "snímá",
  "sníte",
  "sníží",
  "snědl",
  "snědá",
  "snědé",
  "snědí",
  "sněmu",
  "sněmy",
  "sněmů",
  "sněná",
  "sněné",
  "sněte",
  "sněti",
  "snětí",
  "sněží",
  "sobce",
  "sobci",
  "sobců",
  "sobek",
  "sobem",
  "sober",
  "sobig",
  "sobot",
  "sobou",
  "sobím",
  "sobče",
  "sobům",
  "sobův",
  "socho",
  "sochu",
  "sochy",
  "socka",
  "sodná",
  "sodné",
  "sodný",
  "sodou",
  "sofie",
  "sofii",
  "sofií",
  "sofou",
  "sojce",
  "sojek",
  "sojku",
  "sojky",
  "sojou",
  "sojuz",
  "sojčí",
  "sokem",
  "soklu",
  "sokly",
  "soklů",
  "sokou",
  "sokyň",
  "sokům",
  "sokův",
  "solař",
  "solid",
  "solil",
  "solná",
  "solné",
  "solní",
  "solou",
  "solte",
  "soluň",
  "solíš",
  "sondu",
  "sondy",
  "sondě",
  "songu",
  "songy",
  "songů",
  "sonin",
  "sonát",
  "sopce",
  "sopek",
  "sopku",
  "sopky",
  "soptí",
  "sortu",
  "sorty",
  "sortě",
  "sosen",
  "sosnu",
  "sosny",
  "sosně",
  "soudu",
  "soudy",
  "soudí",
  "soudů",
  "souká",
  "souké",
  "souký",
  "soulu",
  "soust",
  "souše",
  "souši",
  "souší",
  "souží",
  "sovou",
  "sovám",
  "sovím",
  "soňou",
  "sošce",
  "sošek",
  "sošku",
  "sošky",
  "sošná",
  "sošné",
  "spadl",
  "spadu",
  "spadá",
  "spadů",
  "spala",
  "spali",
  "spalo",
  "spaly",
  "spamu",
  "spamy",
  "spamů",
  "sparc",
  "spase",
  "spasí",
  "spavá",
  "spavé",
  "spekl",
  "spikl",
  "spinu",
  "spiny",
  "spise",
  "spisu",
  "spisy",
  "spisů",
  "splaš",
  "splať",
  "spleť",
  "split",
  "splní",
  "splou",
  "splát",
  "splém",
  "splým",
  "splňu",
  "spodu",
  "spoje",
  "spoji",
  "spojí",
  "spojů",
  "spole",
  "sponu",
  "spony",
  "sponě",
  "sporo",
  "sporu",
  "spory",
  "sporá",
  "sporé",
  "sporů",
  "spotu",
  "spoty",
  "spotř",
  "spotů",
  "spoře",
  "spoří",
  "sprav",
  "sprch",
  "správ",
  "sprše",
  "spusť",
  "spádu",
  "spády",
  "spádů",
  "spájí",
  "spále",
  "spálu",
  "spály",
  "spálí",
  "spání",
  "spáru",
  "spáry",
  "spárů",
  "spáse",
  "spásu",
  "spásy",
  "spásá",
  "spásé",
  "spásý",
  "spává",
  "spáče",
  "spáči",
  "spáčů",
  "spáře",
  "spáří",
  "spéci",
  "spéká",
  "spéké",
  "spéký",
  "spílá",
  "spíme",
  "spíná",
  "spíné",
  "spíný",
  "spíte",
  "spíše",
  "spíži",
  "spíží",
  "spóry",
  "spěje",
  "spěju",
  "spějí",
  "spěla",
  "spěli",
  "spělo",
  "spěly",
  "spěte",
  "srabu",
  "srabů",
  "srala",
  "srali",
  "sralo",
  "sraly",
  "srazu",
  "srazy",
  "srazí",
  "srazů",
  "srbek",
  "srbem",
  "srbeč",
  "srbku",
  "srbky",
  "srbou",
  "srbuv",
  "srbům",
  "srdce",
  "srdci",
  "srdcí",
  "srhou",
  "srkal",
  "srkej",
  "srkou",
  "srkám",
  "srkáš",
  "srkém",
  "srkým",
  "srnce",
  "srnci",
  "srnců",
  "srnek",
  "srnku",
  "srnky",
  "srnou",
  "srnám",
  "srním",
  "srnčí",
  "srotí",
  "srpem",
  "srpen",
  "srpku",
  "srpky",
  "srpna",
  "srpnu",
  "srpům",
  "srsti",
  "srstí",
  "srubu",
  "sruby",
  "srubů",
  "srázu",
  "srázy",
  "srázů",
  "sráči",
  "sráží",
  "sršel",
  "sršni",
  "sršní",
  "sršně",
  "srším",
  "sršňi",
  "sršňů",
  "stahu",
  "stahy",
  "stahů",
  "stala",
  "stali",
  "stall",
  "stalo",
  "staly",
  "stalá",
  "stalé",
  "stalý",
  "stana",
  "stane",
  "stanu",
  "stany",
  "stanů",
  "stara",
  "staro",
  "stará",
  "staré",
  "stati",
  "statí",
  "statě",
  "staví",
  "stačí",
  "staře",
  "staří",
  "stech",
  "stehu",
  "stehy",
  "stehů",
  "stekl",
  "stela",
  "stele",
  "stenu",
  "steny",
  "stepi",
  "stepu",
  "stepí",
  "stern",
  "sterá",
  "steré",
  "sterý",
  "stezk",
  "steče",
  "stihl",
  "stlač",
  "stluč",
  "stlán",
  "stlát",
  "stoce",
  "stoda",
  "stohu",
  "stohy",
  "stohů",
  "stoje",
  "stoji",
  "stojí",
  "stoku",
  "stoky",
  "stoln",
  "stone",
  "stopl",
  "stopu",
  "stopy",
  "stopě",
  "stoup",
  "stočí",
  "strak",
  "stran",
  "strav",
  "straň",
  "straš",
  "stref",
  "strhl",
  "strhá",
  "strká",
  "strké",
  "strký",
  "strmá",
  "strmé",
  "strmí",
  "strmě",
  "strne",
  "strnu",
  "strní",
  "strof",
  "strou",
  "strpí",
  "strum",
  "strun",
  "strýc",
  "strčí",
  "strže",
  "strži",
  "strží",
  "stude",
  "studu",
  "study",
  "studí",
  "stuhu",
  "stuhy",
  "stulí",
  "stupe",
  "stupu",
  "stuze",
  "stvoř",
  "stvrď",
  "stvůr",
  "stydl",
  "stydí",
  "styku",
  "styky",
  "styků",
  "style",
  "stylu",
  "styly",
  "stylů",
  "stáda",
  "stádu",
  "stády",
  "stádě",
  "stáhl",
  "stáje",
  "stáji",
  "stájí",
  "stála",
  "stále",
  "stáli",
  "stálo",
  "stály",
  "stálá",
  "stálé",
  "stálí",
  "stáno",
  "stání",
  "stárl",
  "stáru",
  "státu",
  "státy",
  "státě",
  "států",
  "stává",
  "stáze",
  "stáčí",
  "stáňa",
  "stáže",
  "stáži",
  "stáží",
  "stéci",
  "stého",
  "stéká",
  "stéké",
  "stéký",
  "stéle",
  "stélu",
  "stély",
  "stému",
  "sténá",
  "sténé",
  "sténý",
  "stíhá",
  "stíhé",
  "stíhý",
  "stíne",
  "stínu",
  "stíny",
  "stíná",
  "stíné",
  "stíní",
  "stíný",
  "stínů",
  "stírá",
  "stíré",
  "stírý",
  "stíží",
  "stóra",
  "stých",
  "stýká",
  "stýma",
  "stými",
  "stěnu",
  "stěny",
  "stěně",
  "stěru",
  "stěry",
  "stěrů",
  "střel",
  "střev",
  "střeš",
  "stůně",
  "stůňu",
  "sucha",
  "suchu",
  "suchá",
  "suché",
  "suchý",
  "sudem",
  "sudet",
  "sudou",
  "sudém",
  "sudím",
  "sudým",
  "sudům",
  "suitu",
  "suitě",
  "sukem",
  "suken",
  "sukna",
  "sukni",
  "sukní",
  "sumce",
  "sumci",
  "sumců",
  "sumer",
  "sumou",
  "sumám",
  "sumčí",
  "sundá",
  "sundé",
  "sundý",
  "suneš",
  "sunou",
  "sunul",
  "supem",
  "supla",
  "supou",
  "supím",
  "supíš",
  "supěl",
  "supům",
  "surfu",
  "sutan",
  "sutin",
  "suvná",
  "suvné",
  "suvně",
  "suďme",
  "suďte",
  "suňte",
  "sušen",
  "sušil",
  "sušme",
  "sušte",
  "suším",
  "sušíš",
  "sušší",
  "suťmi",
  "sužuj",
  "svahu",
  "svahy",
  "svahů",
  "svalu",
  "svaly",
  "svalí",
  "svalů",
  "svaru",
  "svary",
  "svarů",
  "svatá",
  "svaté",
  "svatí",
  "svazu",
  "svazy",
  "svazů",
  "svačí",
  "svaří",
  "svaťa",
  "svede",
  "sveho",
  "svere",
  "sveze",
  "svezl",
  "svezu",
  "svine",
  "svini",
  "svinu",
  "sviny",
  "sviní",
  "svisl",
  "svisu",
  "svitl",
  "svitu",
  "svišť",
  "svlaž",
  "svleč",
  "svodu",
  "svody",
  "svodů",
  "svolá",
  "svolí",
  "svory",
  "svozu",
  "svozy",
  "svozů",
  "svrbí",
  "svrhl",
  "svádí",
  "sváru",
  "sváry",
  "svárů",
  "sváču",
  "sváří",
  "sváže",
  "sváži",
  "svážu",
  "sváží",
  "svého",
  "svému",
  "svící",
  "svíjí",
  "svírá",
  "svíré",
  "svírý",
  "svíti",
  "svítá",
  "svíté",
  "svítí",
  "svítý",
  "svých",
  "svýma",
  "svými",
  "svědí",
  "svědč",
  "svěsí",
  "světa",
  "světe",
  "světu",
  "světy",
  "světí",
  "světě",
  "světů",
  "svěří",
  "svěže",
  "svěží",
  "swapo",
  "swapy",
  "sycen",
  "sykla",
  "sykli",
  "syklo",
  "sykly",
  "sykne",
  "sykou",
  "sykém",
  "sykým",
  "sylva",
  "sylvo",
  "sylvu",
  "sylvy",
  "sylvě",
  "synka",
  "synku",
  "synky",
  "synků",
  "synod",
  "synou",
  "synův",
  "sypal",
  "sypat",
  "sypej",
  "sypeš",
  "sypká",
  "sypké",
  "sypků",
  "sypme",
  "sypná",
  "sypné",
  "sypou",
  "sypte",
  "sypán",
  "syrií",
  "syrou",
  "syrém",
  "syrým",
  "sysla",
  "sysly",
  "syslí",
  "syslů",
  "sytil",
  "sytou",
  "sytém",
  "sytím",
  "sytíš",
  "sytým",
  "syčel",
  "syčme",
  "syčte",
  "syčím",
  "syčíš",
  "syřan",
  "syťme",
  "syťte",
  "sádek",
  "sádel",
  "sádla",
  "sádle",
  "sádlu",
  "sádly",
  "sádru",
  "sádry",
  "sádře",
  "ságou",
  "ságám",
  "sáhla",
  "sáhli",
  "sáhlo",
  "sáhly",
  "sáhne",
  "sáhni",
  "sáhnu",
  "sálal",
  "sálem",
  "sálou",
  "sálém",
  "sálým",
  "sálům",
  "sámky",
  "sáním",
  "sáněk",
  "sápal",
  "sápat",
  "sápeš",
  "sápou",
  "sárou",
  "sátou",
  "sátém",
  "sátým",
  "sávou",
  "sávův",
  "sázce",
  "sázej",
  "sázek",
  "sázel",
  "sázen",
  "sázku",
  "sázky",
  "sázím",
  "sázíš",
  "sáčku",
  "sáčky",
  "sáčků",
  "sářin",
  "ségry",
  "sémou",
  "sépii",
  "sépií",
  "sérem",
  "sérii",
  "sérií",
  "sérou",
  "sérům",
  "sídel",
  "sídla",
  "sídle",
  "sídlu",
  "sídly",
  "sídlí",
  "sílil",
  "sílou",
  "sílím",
  "sílíš",
  "síním",
  "sípal",
  "sípat",
  "sípeš",
  "sípou",
  "sípěl",
  "síria",
  "sírou",
  "sítek",
  "sítem",
  "sítka",
  "sítku",
  "sítou",
  "sítím",
  "sítěk",
  "sítům",
  "sívat",
  "síňce",
  "síťař",
  "síťce",
  "síťek",
  "síťku",
  "síťky",
  "sójou",
  "sólem",
  "sólům",
  "súdán",
  "sýcem",
  "sýcům",
  "sýcův",
  "sýkor",
  "sýpce",
  "sýpek",
  "sýpku",
  "sýpky",
  "sýrem",
  "sýrie",
  "sýrii",
  "sýrií",
  "sýrou",
  "sýrům",
  "sývin",
  "sývou",
  "sýčci",
  "sýčka",
  "sýčku",
  "sýčky",
  "sýčků",
  "sýřit",
  "sčítá",
  "sčíté",
  "sčítý",
  "sňala",
  "sňali",
  "sňalo",
  "sňata",
  "sňato",
  "sňatu",
  "sňaty",
  "sňatá",
  "sňaté",
  "sňatý",
  "sříci",
  "sříct",
  "sťala",
  "sťali",
  "sťalo",
  "sťaly",
  "sťata",
  "sťati",
  "sťato",
  "sťatá",
  "sťaté",
  "sťatý",
  "sůvou",
  "sžije",
  "sžiji",
  "sžijí",
  "sžila",
  "sžili",
  "sžilo",
  "sžily",
  "sžitá",
  "sžité",
  "sžírá",
  "sžívá",
  "tabel",
  "tabla",
  "table",
  "tagem",
  "tagům",
  "tahal",
  "tahej",
  "tahem",
  "tahou",
  "tahám",
  "taháš",
  "tahém",
  "tahým",
  "tahům",
  "tajem",
  "tajen",
  "tajgu",
  "tajgy",
  "tajil",
  "tajná",
  "tajné",
  "tajní",
  "tajně",
  "tajze",
  "tajím",
  "tajíš",
  "takou",
  "taktu",
  "takty",
  "taktů",
  "takás",
  "takém",
  "takés",
  "takým",
  "tamní",
  "tamně",
  "tamta",
  "tamti",
  "tamto",
  "tamtu",
  "tamté",
  "tance",
  "tanci",
  "tanců",
  "tanga",
  "tangu",
  "tanku",
  "tanky",
  "tanků",
  "tanou",
  "taném",
  "taným",
  "tanči",
  "tančí",
  "tapet",
  "tarou",
  "tasil",
  "tasov",
  "tasím",
  "tasíš",
  "tatam",
  "tatar",
  "tater",
  "tatra",
  "tatru",
  "tatry",
  "tatře",
  "tavbu",
  "tavby",
  "tavbě",
  "tavců",
  "taven",
  "tavil",
  "tavme",
  "tavná",
  "tavné",
  "tavte",
  "tavím",
  "tavíš",
  "taxis",
  "taxou",
  "taxám",
  "tašce",
  "tašek",
  "tašku",
  "tašky",
  "taťka",
  "taťko",
  "taťku",
  "taťky",
  "tažen",
  "tažič",
  "tažná",
  "tažné",
  "tažní",
  "teamu",
  "teamů",
  "tedou",
  "tekla",
  "teklo",
  "tekly",
  "tekou",
  "telat",
  "telci",
  "telou",
  "telče",
  "telči",
  "temen",
  "temna",
  "temnu",
  "temná",
  "temné",
  "temní",
  "temně",
  "tempa",
  "templ",
  "tempu",
  "tempy",
  "temže",
  "temži",
  "tenat",
  "tence",
  "tencí",
  "tenká",
  "tenké",
  "tenký",
  "tenzi",
  "tenzí",
  "tenčí",
  "tepal",
  "tepee",
  "tepem",
  "tepen",
  "tepeš",
  "tepla",
  "teple",
  "teplu",
  "teplá",
  "teplé",
  "teplí",
  "tepnu",
  "tepny",
  "tepně",
  "tepou",
  "tepán",
  "tepém",
  "tepým",
  "teras",
  "terka",
  "ternu",
  "terou",
  "terče",
  "terči",
  "terčů",
  "tesal",
  "tesat",
  "tesco",
  "tesin",
  "tesla",
  "tesle",
  "teslu",
  "tesly",
  "tesou",
  "testo",
  "testu",
  "testy",
  "testů",
  "tesém",
  "tesým",
  "tetin",
  "tetky",
  "tetou",
  "tetuj",
  "tetám",
  "tevní",
  "texas",
  "texte",
  "textu",
  "texty",
  "textů",
  "tezím",
  "tečce",
  "teček",
  "tečen",
  "tečko",
  "tečku",
  "tečky",
  "tečnu",
  "tečny",
  "tečná",
  "tečné",
  "tečně",
  "tečou",
  "tečuj",
  "teďka",
  "thlic",
  "théby",
  "tibet",
  "tibor",
  "ticha",
  "tichu",
  "tichy",
  "tichá",
  "tiché",
  "tichý",
  "tikal",
  "tikem",
  "tikou",
  "tikém",
  "tikým",
  "tipem",
  "tipne",
  "tipou",
  "tipuj",
  "tipům",
  "tisem",
  "tiskl",
  "tisku",
  "tisky",
  "tiská",
  "tiské",
  "tisků",
  "tisou",
  "tisém",
  "tisým",
  "tisům",
  "titam",
  "titru",
  "titry",
  "titrů",
  "tišen",
  "tišil",
  "tiším",
  "tišíš",
  "tišší",
  "tkací",
  "tkají",
  "tkala",
  "tkali",
  "tkalo",
  "tkaly",
  "tkaná",
  "tkané",
  "tkvěl",
  "tkáme",
  "tkáni",
  "tkání",
  "tkáně",
  "tkáte",
  "tkého",
  "tkému",
  "tkých",
  "tkýma",
  "tkými",
  "tlaku",
  "tlaky",
  "tlaků",
  "tlamu",
  "tlamy",
  "tlamě",
  "tlapu",
  "tlapy",
  "tlapě",
  "tlače",
  "tlačí",
  "tlecí",
  "tlejí",
  "tlela",
  "tlelo",
  "tlely",
  "tlemí",
  "tlivá",
  "tlivé",
  "tlumí",
  "tlupu",
  "tlupy",
  "tlupě",
  "tluče",
  "tluču",
  "tmavo",
  "tmavá",
  "tmavé",
  "tmaví",
  "tmavě",
  "tmelu",
  "tmely",
  "tmelů",
  "tmách",
  "tného",
  "tních",
  "tního",
  "tnění",
  "toefl",
  "tohot",
  "tokal",
  "tokem",
  "tokia",
  "tokio",
  "tokiu",
  "tokům",
  "tolec",
  "tomas",
  "tomou",
  "tomáš",
  "tomův",
  "tonak",
  "tonda",
  "tondo",
  "tondu",
  "tondy",
  "toneš",
  "tonga",
  "tonic",
  "tonik",
  "tonka",
  "tonou",
  "tonul",
  "toník",
  "tools",
  "topen",
  "topil",
  "topiv",
  "topná",
  "topné",
  "topte",
  "topím",
  "topíš",
  "torem",
  "toren",
  "tornu",
  "torny",
  "torně",
  "torza",
  "torzi",
  "torzu",
  "torzy",
  "torzí",
  "tosti",
  "total",
  "totam",
  "touho",
  "touhu",
  "touhy",
  "toulá",
  "touze",
  "touží",
  "točen",
  "točic",
  "točil",
  "točme",
  "točno",
  "točnu",
  "točny",
  "točná",
  "točné",
  "točný",
  "točně",
  "točte",
  "točím",
  "točíš",
  "točům",
  "trafa",
  "tramp",
  "trapu",
  "trase",
  "trasu",
  "trasy",
  "trati",
  "tratí",
  "tratě",
  "trefu",
  "trefy",
  "trefí",
  "trefě",
  "tresť",
  "trhal",
  "trhej",
  "trhem",
  "trhla",
  "trhli",
  "trhlo",
  "trhly",
  "trhlá",
  "trhlé",
  "trhlí",
  "trhne",
  "trhni",
  "trhnu",
  "trhou",
  "trhám",
  "trhán",
  "trháš",
  "trhém",
  "trhým",
  "trhům",
  "trial",
  "triem",
  "trika",
  "triku",
  "triky",
  "triků",
  "tripu",
  "tripy",
  "tripů",
  "triím",
  "trkal",
  "trkač",
  "trkla",
  "trkli",
  "trklo",
  "trkly",
  "trkne",
  "trkni",
  "trknu",
  "trkám",
  "trkáš",
  "trnce",
  "trnek",
  "trnem",
  "trneš",
  "trnku",
  "trnky",
  "trnou",
  "trnov",
  "trnul",
  "trním",
  "trnům",
  "troch",
  "troju",
  "trojí",
  "troky",
  "trole",
  "troll",
  "trolí",
  "tropí",
  "tropů",
  "trotl",
  "troše",
  "trpce",
  "trpká",
  "trpké",
  "trpme",
  "trpná",
  "trpné",
  "trpně",
  "trpte",
  "trpím",
  "trpín",
  "trpíš",
  "trpčí",
  "trpěl",
  "trpěn",
  "trsem",
  "trsám",
  "trsáš",
  "trsům",
  "truby",
  "trubě",
  "trucu",
  "trudy",
  "trunk",
  "trupu",
  "trupy",
  "trupů",
  "trusu",
  "truňk",
  "trval",
  "trvej",
  "trvou",
  "trvám",
  "trván",
  "trváš",
  "trvém",
  "trvým",
  "trámu",
  "trámy",
  "trámů",
  "trápí",
  "trávu",
  "trávy",
  "tráví",
  "trávě",
  "trému",
  "trémy",
  "trémě",
  "tróji",
  "trčel",
  "trčíc",
  "trčím",
  "trčíš",
  "trůne",
  "trůnu",
  "trůny",
  "trůní",
  "trůně",
  "trůnů",
  "tržbu",
  "tržby",
  "tržbě",
  "tržeb",
  "tržná",
  "tržné",
  "tržně",
  "tubou",
  "tubám",
  "tuctu",
  "tucty",
  "tuctů",
  "tuhla",
  "tuhli",
  "tuhly",
  "tuhne",
  "tuhnu",
  "tuhou",
  "tuhém",
  "tuhým",
  "tukem",
  "tukům",
  "tuleň",
  "tulil",
  "tunik",
  "tunis",
  "tunou",
  "tunám",
  "tupce",
  "tupci",
  "tupců",
  "tupen",
  "tupil",
  "tupou",
  "tupém",
  "tupím",
  "tupíš",
  "tupým",
  "turci",
  "turem",
  "turka",
  "turks",
  "turku",
  "turky",
  "turků",
  "turči",
  "turům",
  "tutat",
  "tutlá",
  "tutlé",
  "tutlý",
  "tuzex",
  "tuček",
  "tučná",
  "tučné",
  "tučně",
  "tuňák",
  "tušen",
  "tušil",
  "tušme",
  "tušte",
  "tušíc",
  "tuším",
  "tušíš",
  "tužbu",
  "tužby",
  "tužbě",
  "tužce",
  "tužeb",
  "tužek",
  "tužen",
  "tužku",
  "tužky",
  "tužme",
  "tužte",
  "tužší",
  "tvaru",
  "tvary",
  "tvarů",
  "tvora",
  "tvore",
  "tvoru",
  "tvory",
  "tvorů",
  "tvoře",
  "tvoři",
  "tvoří",
  "tvrdl",
  "tvrdo",
  "tvrdá",
  "tvrdé",
  "tvrdí",
  "tvrdě",
  "tvrze",
  "tvrzi",
  "tvrzí",
  "tváře",
  "tváři",
  "tváří",
  "tvýma",
  "twain",
  "tyfem",
  "tygra",
  "tygru",
  "tygry",
  "tygrů",
  "tygři",
  "tygří",
  "tykal",
  "tykej",
  "tykou",
  "tykve",
  "tykvi",
  "tykví",
  "tykám",
  "tykém",
  "tykým",
  "tyluv",
  "typem",
  "typou",
  "typům",
  "tyrse",
  "tytou",
  "tytém",
  "tytým",
  "tyátr",
  "tyčce",
  "tyček",
  "tyčil",
  "tyčko",
  "tyčku",
  "tyčky",
  "tyčíc",
  "tyčím",
  "tyčíš",
  "tácem",
  "tácku",
  "tácky",
  "tácků",
  "tácům",
  "tágem",
  "tágou",
  "táhel",
  "táhla",
  "táhle",
  "táhli",
  "táhlu",
  "táhly",
  "táhlá",
  "táhlé",
  "táhne",
  "táhni",
  "táhnu",
  "tálín",
  "tánin",
  "táním",
  "tápal",
  "tápat",
  "tápej",
  "tápeš",
  "tápou",
  "tárou",
  "tátou",
  "tátům",
  "tátův",
  "tázal",
  "tázán",
  "táňou",
  "tážou",
  "témat",
  "témou",
  "tíhli",
  "tíhlo",
  "tíhly",
  "tíhne",
  "tíhnu",
  "tíhou",
  "tíkat",
  "tílka",
  "tílku",
  "tínou",
  "tíném",
  "tíným",
  "típat",
  "típek",
  "típla",
  "típli",
  "típlo",
  "típly",
  "típne",
  "típám",
  "tísni",
  "tísní",
  "tísně",
  "tížen",
  "tížil",
  "tížíc",
  "tížím",
  "tížíš",
  "tógou",
  "tónem",
  "tónin",
  "tónům",
  "tórou",
  "tújou",
  "túrou",
  "túrám",
  "týkal",
  "týlem",
  "týlou",
  "týmem",
  "týmům",
  "týmže",
  "týnce",
  "týnci",
  "týnec",
  "týnou",
  "týral",
  "týrou",
  "týrám",
  "týrán",
  "týrém",
  "týrým",
  "týřov",
  "těkal",
  "těkou",
  "těkám",
  "těkáš",
  "těkém",
  "těkým",
  "tělem",
  "těles",
  "tělou",
  "tělák",
  "tělům",
  "těmou",
  "těrky",
  "těsni",
  "těsno",
  "těsná",
  "těsné",
  "těsní",
  "těsně",
  "těsta",
  "těstu",
  "těsty",
  "těstě",
  "tětiv",
  "těšil",
  "těšme",
  "těšte",
  "těšíc",
  "těším",
  "těšín",
  "těšíš",
  "těžbu",
  "těžby",
  "těžbě",
  "těžce",
  "těžcí",
  "těžeb",
  "těžen",
  "těžil",
  "těžkl",
  "těžko",
  "těžká",
  "těžké",
  "těžký",
  "těžme",
  "těžně",
  "těžte",
  "těžíc",
  "těžím",
  "těžíš",
  "těžší",
  "třeba",
  "tředí",
  "třela",
  "třeli",
  "třelo",
  "třely",
  "třeme",
  "třeni",
  "třená",
  "třené",
  "třepe",
  "třepí",
  "třese",
  "třesy",
  "třete",
  "třetí",
  "třešť",
  "třtin",
  "tříbí",
  "třídu",
  "třídy",
  "třídí",
  "třídě",
  "třímá",
  "tůním",
  "tůněk",
  "tůrou",
  "tůňky",
  "ubalí",
  "ubere",
  "uberu",
  "ubije",
  "ubiju",
  "ubijí",
  "ubila",
  "ubili",
  "ubilo",
  "ubily",
  "ubita",
  "ubiti",
  "ubito",
  "ubity",
  "ubitá",
  "ubité",
  "ubliž",
  "ubodá",
  "ubohá",
  "ubohé",
  "uboze",
  "ubozí",
  "ubral",
  "ubrat",
  "ubrán",
  "ubude",
  "ubyde",
  "ubyla",
  "ubyli",
  "ubylo",
  "ubyly",
  "ubyto",
  "ubíhá",
  "ubíhé",
  "ubíhý",
  "ubíjí",
  "ubírá",
  "ubíré",
  "ubírý",
  "ubývá",
  "ubývé",
  "ubývý",
  "uběhl",
  "uběhá",
  "uběhé",
  "uběhý",
  "ucelí",
  "uchem",
  "uchop",
  "uchou",
  "uchům",
  "ucpal",
  "ucpou",
  "ucpán",
  "uctil",
  "uctit",
  "uctím",
  "uctěn",
  "ucukl",
  "uculí",
  "ucítí",
  "udají",
  "udala",
  "udali",
  "udalo",
  "udaly",
  "udaná",
  "udané",
  "udeře",
  "udeří",
  "udici",
  "udicí",
  "udila",
  "udili",
  "udilo",
  "udily",
  "udiví",
  "udolá",
  "udrou",
  "udrží",
  "udusá",
  "udusí",
  "udála",
  "událo",
  "udály",
  "udáme",
  "udána",
  "udáno",
  "udánu",
  "udány",
  "udáte",
  "udává",
  "udáví",
  "udílí",
  "udíme",
  "udíte",
  "udých",
  "udýma",
  "udými",
  "udělá",
  "udělé",
  "udělí",
  "udělý",
  "uefou",
  "ufony",
  "uhasl",
  "uhasí",
  "uhlou",
  "uhlím",
  "uhnat",
  "uhneš",
  "uhnij",
  "uhnil",
  "uhnou",
  "uhnul",
  "uhodl",
  "uhodí",
  "uhoří",
  "uhrab",
  "uhraď",
  "uhrou",
  "uhrál",
  "uhyne",
  "uhádl",
  "uhájí",
  "uhání",
  "uhýbá",
  "uhýbé",
  "uhýbý",
  "uhřát",
  "ujala",
  "ujali",
  "ujalo",
  "ujaly",
  "ujatá",
  "ujaté",
  "ujdeš",
  "ujdou",
  "ujede",
  "ujela",
  "ujeli",
  "ujelo",
  "ujely",
  "ujeto",
  "ujetá",
  "ujeté",
  "ujkou",
  "ujmeš",
  "ujmou",
  "ujídá",
  "ujídé",
  "ujídý",
  "ujímá",
  "ukecá",
  "ukliď",
  "ukloň",
  "ukopl",
  "ukroj",
  "ukroč",
  "ukryj",
  "ukryl",
  "ukryt",
  "ukutá",
  "ukuté",
  "ukájí",
  "ukápl",
  "ukáže",
  "ukáži",
  "ukážu",
  "ukáží",
  "ulehl",
  "ulehá",
  "uleje",
  "uleju",
  "ulekl",
  "uletí",
  "uleví",
  "ulici",
  "ulicí",
  "ulije",
  "ulila",
  "ulili",
  "ulilo",
  "ulily",
  "ulitu",
  "ulity",
  "ulitá",
  "ulité",
  "ulitě",
  "ulomí",
  "uloví",
  "uloží",
  "ulpěl",
  "uléhá",
  "uléhé",
  "uléhý",
  "ulétl",
  "ulétá",
  "umane",
  "umaže",
  "umaži",
  "umažu",
  "umele",
  "umetá",
  "umeté",
  "umetý",
  "umisť",
  "umlkl",
  "umlká",
  "umlké",
  "umlký",
  "umlčí",
  "umnou",
  "umném",
  "umným",
  "umoří",
  "umožň",
  "umrlá",
  "umrlé",
  "umrou",
  "umrzl",
  "umučí",
  "umyje",
  "umyji",
  "umyju",
  "umyjí",
  "umyla",
  "umyli",
  "umylo",
  "umyly",
  "umyta",
  "umyti",
  "umyto",
  "umyty",
  "umytá",
  "umyté",
  "umíme",
  "umíní",
  "umírá",
  "umíré",
  "umírý",
  "umíte",
  "umývá",
  "umývé",
  "umývý",
  "umějí",
  "uměla",
  "uměle",
  "uměli",
  "umělo",
  "uměly",
  "umělá",
  "umělé",
  "umělí",
  "umřel",
  "umřem",
  "umřeš",
  "umřou",
  "unaví",
  "unese",
  "unesl",
  "unesu",
  "uniat",
  "unikl",
  "uniká",
  "uniké",
  "uniký",
  "union",
  "unixu",
  "unixy",
  "unixů",
  "unylá",
  "unylé",
  "unáší",
  "upadl",
  "upadá",
  "upaží",
  "upekl",
  "upeče",
  "upeču",
  "upije",
  "upiji",
  "upijí",
  "upila",
  "upili",
  "upilo",
  "upily",
  "upito",
  "upitá",
  "upité",
  "upjal",
  "upleť",
  "upneš",
  "upnou",
  "upnul",
  "upnut",
  "uprav",
  "upraž",
  "upros",
  "upusť",
  "upálí",
  "upáře",
  "upářu",
  "upéci",
  "upíjí",
  "upíná",
  "upíné",
  "upíný",
  "upíra",
  "upíre",
  "upíry",
  "upírá",
  "upíré",
  "upírý",
  "upírů",
  "upíři",
  "upíří",
  "upíše",
  "upíší",
  "upřed",
  "upřel",
  "upřen",
  "upřou",
  "urale",
  "uralo",
  "uralu",
  "uraly",
  "uranu",
  "urazí",
  "urguj",
  "urnou",
  "urnám",
  "urodí",
  "uroní",
  "urval",
  "urveš",
  "urvou",
  "urván",
  "uráčí",
  "uráží",
  "určen",
  "určil",
  "určuj",
  "určíc",
  "určím",
  "určíš",
  "usadí",
  "usedl",
  "usedá",
  "usedé",
  "usedý",
  "usekl",
  "uskoč",
  "usmaž",
  "usmiř",
  "usmrť",
  "usmál",
  "usměj",
  "usneš",
  "usnou",
  "usnul",
  "usním",
  "uspal",
  "uspoř",
  "uspán",
  "uspím",
  "uspíš",
  "usrat",
  "usrkl",
  "ustal",
  "ustaň",
  "ustel",
  "ustup",
  "ustůj",
  "ususe",
  "usuší",
  "usíná",
  "utahá",
  "utají",
  "utaví",
  "utekl",
  "uteče",
  "uteču",
  "utiší",
  "utkal",
  "utkej",
  "utkne",
  "utkou",
  "utkví",
  "utkám",
  "utkán",
  "utkáš",
  "utlač",
  "utneš",
  "utnou",
  "utnul",
  "utnut",
  "utone",
  "utonu",
  "utopí",
  "utrať",
  "utrhl",
  "utrou",
  "utrpí",
  "utrží",
  "utuží",
  "utvor",
  "utvoř",
  "utáhl",
  "utápí",
  "utéci",
  "utíká",
  "utíké",
  "utíký",
  "utírá",
  "utíré",
  "utírý",
  "utýrá",
  "utýré",
  "utýrý",
  "utěší",
  "utřel",
  "utřen",
  "utřeš",
  "utřou",
  "uvadl",
  "uvadá",
  "uvalí",
  "uvaří",
  "uvede",
  "uveze",
  "uvezl",
  "uvezu",
  "uvidí",
  "uvitá",
  "uvité",
  "uvolí",
  "uvrhl",
  "uvykl",
  "uvádí",
  "uvázl",
  "uváže",
  "uváži",
  "uvážu",
  "uváží",
  "uvítá",
  "uvíté",
  "uvítý",
  "uvízl",
  "uvěří",
  "uzdou",
  "uzemí",
  "uzená",
  "uzené",
  "uziju",
  "uzlem",
  "uzlin",
  "uzlům",
  "uzmul",
  "uznal",
  "uznat",
  "uznej",
  "uznou",
  "uznám",
  "uznán",
  "uznáš",
  "uzrál",
  "uzřel",
  "uzříš",
  "učena",
  "učeni",
  "učeny",
  "učená",
  "učené",
  "učeně",
  "učeše",
  "učešu",
  "učila",
  "učili",
  "učilo",
  "učily",
  "učiní",
  "učiva",
  "učivu",
  "učivy",
  "učivě",
  "učnic",
  "učněm",
  "učtou",
  "učíce",
  "učíme",
  "učíte",
  "učívá",
  "učňům",
  "učňův",
  "uďtou",
  "uřeže",
  "uřkla",
  "uřkli",
  "uřklo",
  "uřkly",
  "uřkne",
  "uřkni",
  "uřknu",
  "uříci",
  "uříct",
  "uřídí",
  "uřízl",
  "ušatá",
  "ušaté",
  "ušatí",
  "ušaře",
  "ušije",
  "ušijí",
  "ušila",
  "ušili",
  "ušilo",
  "ušily",
  "ušita",
  "ušito",
  "ušity",
  "ušitá",
  "ušité",
  "uškrť",
  "ušlou",
  "ušlém",
  "ušlým",
  "ušním",
  "ušpiň",
  "uštkl",
  "uštve",
  "uštvi",
  "uštvu",
  "ušáku",
  "uťala",
  "uťali",
  "uťalo",
  "uťaly",
  "uťata",
  "uťaty",
  "uťatá",
  "uťaté",
  "uťatý",
  "užasl",
  "užije",
  "užiji",
  "užiju",
  "užijí",
  "užila",
  "užili",
  "užilo",
  "užily",
  "užita",
  "užito",
  "užity",
  "užitá",
  "užité",
  "uživí",
  "užírá",
  "užíré",
  "užírý",
  "užívá",
  "užším",
  "vacov",
  "vadil",
  "vadla",
  "vadlo",
  "vadly",
  "vadne",
  "vadná",
  "vadné",
  "vadně",
  "vadou",
  "vadám",
  "vadím",
  "vadíš",
  "vakem",
  "vakua",
  "vakuu",
  "vakům",
  "valda",
  "valem",
  "valeč",
  "valil",
  "valná",
  "valné",
  "valně",
  "valte",
  "valut",
  "valvo",
  "valím",
  "valíš",
  "valše",
  "vanda",
  "vandu",
  "vandy",
  "vanou",
  "vanul",
  "vanám",
  "varem",
  "varen",
  "varia",
  "varnu",
  "varny",
  "varná",
  "varné",
  "varný",
  "varně",
  "varuj",
  "varům",
  "vasek",
  "vasil",
  "vasku",
  "vatel",
  "vater",
  "vatou",
  "vatru",
  "vatry",
  "vatře",
  "vazal",
  "vazbu",
  "vazby",
  "vazbě",
  "vazeb",
  "vazem",
  "vazká",
  "vazké",
  "vazký",
  "vazná",
  "vazné",
  "vazům",
  "vačce",
  "vaček",
  "vačic",
  "vačku",
  "vačky",
  "vařen",
  "vařil",
  "vařme",
  "vařte",
  "vařím",
  "vaříš",
  "vašek",
  "vašem",
  "vašim",
  "vaška",
  "vašku",
  "vaším",
  "važme",
  "važná",
  "važné",
  "važný",
  "važte",
  "vbitá",
  "vbité",
  "vbíhá",
  "vběhl",
  "vcítí",
  "vdají",
  "vdala",
  "vdalo",
  "vdaly",
  "vdaná",
  "vdané",
  "vdovu",
  "vdovy",
  "vdově",
  "vdáme",
  "vdáte",
  "vdává",
  "vdých",
  "vdýma",
  "vdými",
  "vděku",
  "vděčí",
  "vecpe",
  "vecpi",
  "vecpu",
  "veder",
  "vedet",
  "vedeš",
  "vedla",
  "vedli",
  "vedlo",
  "vedly",
  "vedou",
  "vedra",
  "vedru",
  "vedry",
  "vegas",
  "vegou",
  "vejce",
  "vejci",
  "vejcí",
  "vejda",
  "vejde",
  "vejdi",
  "vekou",
  "vekám",
  "velcí",
  "veleb",
  "velel",
  "velim",
  "velič",
  "veliš",
  "velko",
  "velká",
  "velké",
  "velký",
  "velmi",
  "velni",
  "velte",
  "velím",
  "vemen",
  "vemeš",
  "vemou",
  "vemte",
  "venca",
  "venda",
  "venek",
  "venčí",
  "vepiš",
  "vepře",
  "vepři",
  "vepřo",
  "vepřů",
  "verba",
  "vervu",
  "vervy",
  "vervě",
  "verzi",
  "verzí",
  "verča",
  "verše",
  "verši",
  "veršu",
  "veršů",
  "vesla",
  "veslu",
  "vesly",
  "vestu",
  "vesty",
  "vestě",
  "vesuv",
  "vetem",
  "vetou",
  "vetra",
  "vetuj",
  "vetře",
  "vetům",
  "vevát",
  "vezeš",
  "vezla",
  "vezli",
  "vezlo",
  "vezly",
  "vezme",
  "vezmi",
  "vezou",
  "vezte",
  "večer",
  "večeř",
  "večír",
  "veďme",
  "veďte",
  "vešla",
  "vešli",
  "vešlo",
  "vešly",
  "vešlá",
  "vešlé",
  "vhodí",
  "vhoze",
  "vhání",
  "videa",
  "videc",
  "videm",
  "video",
  "videu",
  "videí",
  "vidin",
  "vidlí",
  "vidmo",
  "vidou",
  "vidíš",
  "vidče",
  "viděn",
  "vidět",
  "vidům",
  "viješ",
  "vijme",
  "vijte",
  "viklá",
  "vikou",
  "vilce",
  "vilda",
  "vilek",
  "vilku",
  "vilky",
  "vilma",
  "vilmě",
  "vilná",
  "vilné",
  "vilně",
  "vilou",
  "vilám",
  "vilém",
  "vilík",
  "vinci",
  "vineš",
  "vinic",
  "vinil",
  "vinna",
  "vinni",
  "vinno",
  "vinná",
  "vinné",
  "vinně",
  "vinul",
  "vinut",
  "viním",
  "viníš",
  "viněn",
  "vinět",
  "viole",
  "violu",
  "violy",
  "virat",
  "virem",
  "virou",
  "viróz",
  "virům",
  "visel",
  "visem",
  "visit",
  "visko",
  "visou",
  "vistu",
  "visty",
  "vistě",
  "visíc",
  "visím",
  "visíš",
  "vitou",
  "vitém",
  "vitím",
  "vitým",
  "vizit",
  "vizím",
  "viďte",
  "viňte",
  "višní",
  "višně",
  "vjede",
  "vjela",
  "vjeli",
  "vjelo",
  "vjely",
  "vjemu",
  "vjemy",
  "vjemů",
  "vjetá",
  "vjeté",
  "vkroč",
  "vkusu",
  "vlahá",
  "vlahé",
  "vlaje",
  "vlají",
  "vlaku",
  "vlaky",
  "vlaků",
  "vlase",
  "vlasu",
  "vlasy",
  "vlasů",
  "vlavá",
  "vlavé",
  "vleke",
  "vleku",
  "vleky",
  "vleků",
  "vlepí",
  "vlety",
  "vletí",
  "vleze",
  "vlezl",
  "vlezu",
  "vleče",
  "vleču",
  "vlhce",
  "vlhka",
  "vlhku",
  "vlhká",
  "vlhké",
  "vlhla",
  "vlhli",
  "vlhlo",
  "vlhly",
  "vlhne",
  "vlhou",
  "vlhém",
  "vlhým",
  "vlhčí",
  "vlije",
  "vlijí",
  "vlila",
  "vlili",
  "vlilo",
  "vlily",
  "vlitá",
  "vlité",
  "vlivu",
  "vlivy",
  "vlivů",
  "vlkem",
  "vlkou",
  "vlkoš",
  "vlkům",
  "vlkův",
  "vlnce",
  "vlnek",
  "vlnil",
  "vlnku",
  "vlnky",
  "vlnou",
  "vlnám",
  "vlohu",
  "vlohy",
  "vloze",
  "vloží",
  "vlysu",
  "vlysy",
  "vlysů",
  "vládl",
  "vládu",
  "vlády",
  "vládá",
  "vládě",
  "vláhu",
  "vláhy",
  "vláká",
  "vlála",
  "vlálo",
  "vlály",
  "vláze",
  "vláčí",
  "vláďa",
  "vláďu",
  "vláří",
  "vléci",
  "vlétl",
  "vlévá",
  "vlézá",
  "vlčat",
  "vlčic",
  "vlčka",
  "vlčků",
  "vlčím",
  "vmete",
  "vmetl",
  "vmetu",
  "vmísí",
  "vnady",
  "vnese",
  "vnesl",
  "vnesu",
  "vnikl",
  "vniku",
  "vniká",
  "vniké",
  "vniký",
  "vniků",
  "vnitř",
  "vnore",
  "vnoří",
  "vnuci",
  "vnuka",
  "vnukl",
  "vnuku",
  "vnuky",
  "vnuká",
  "vnuké",
  "vnuký",
  "vnuků",
  "vnutí",
  "vnáší",
  "vního",
  "vnímá",
  "vodce",
  "vodil",
  "vodku",
  "vodky",
  "vodná",
  "vodné",
  "vodný",
  "vodou",
  "vodám",
  "vodím",
  "vodíš",
  "vojen",
  "vojnu",
  "vojny",
  "vojně",
  "vojou",
  "vojta",
  "vojto",
  "vojtu",
  "vojty",
  "vojům",
  "volal",
  "volbu",
  "volby",
  "volbě",
  "voleb",
  "volen",
  "volhy",
  "volil",
  "volič",
  "volme",
  "volna",
  "volnu",
  "volny",
  "volná",
  "volné",
  "volní",
  "volně",
  "volou",
  "volte",
  "voltu",
  "volty",
  "voltů",
  "volva",
  "volvo",
  "volze",
  "volák",
  "volám",
  "voláš",
  "volém",
  "volím",
  "volým",
  "vonná",
  "vonné",
  "voníš",
  "voněl",
  "vorek",
  "vorem",
  "vorku",
  "vorky",
  "vorům",
  "vosku",
  "vosky",
  "vosků",
  "vosou",
  "vosám",
  "vosím",
  "votic",
  "vousu",
  "vousy",
  "vousů",
  "vozba",
  "vozil",
  "vozka",
  "vozky",
  "vozte",
  "vozím",
  "vozíš",
  "voďme",
  "voďte",
  "vpadl",
  "vpijí",
  "vpili",
  "vpisy",
  "vpitá",
  "vpité",
  "vplul",
  "vplyv",
  "vprav",
  "vpusť",
  "vpáde",
  "vpádu",
  "vpády",
  "vpádů",
  "vpálí",
  "vpíjí",
  "vpůli",
  "vrací",
  "vraha",
  "vrahu",
  "vrahy",
  "vrahů",
  "vraku",
  "vraky",
  "vraků",
  "vraná",
  "vrané",
  "vraní",
  "vraty",
  "vrazi",
  "vrazí",
  "vražd",
  "vrbno",
  "vrbou",
  "vrbám",
  "vrchu",
  "vrchy",
  "vrchů",
  "vrhal",
  "vrhej",
  "vrhem",
  "vrhla",
  "vrhli",
  "vrhlo",
  "vrhly",
  "vrhne",
  "vrhni",
  "vrhnu",
  "vrhou",
  "vrhám",
  "vrhán",
  "vrháš",
  "vrhém",
  "vrhým",
  "vrhům",
  "vrkal",
  "vrkám",
  "vrkáš",
  "vrněl",
  "vrnět",
  "vrtal",
  "vrtač",
  "vrtej",
  "vrtem",
  "vrtne",
  "vrtná",
  "vrtné",
  "vrtní",
  "vrtou",
  "vrtám",
  "vrtán",
  "vrtát",
  "vrtém",
  "vrtíc",
  "vrtím",
  "vrtým",
  "vrtěl",
  "vrtům",
  "vrubu",
  "vruby",
  "vrubů",
  "vrutu",
  "vruty",
  "vrutů",
  "vryje",
  "vryji",
  "vryjí",
  "vryla",
  "vryli",
  "vrylo",
  "vryly",
  "vrypu",
  "vrypy",
  "vrypů",
  "vryty",
  "vrytá",
  "vryté",
  "vrzal",
  "vrzem",
  "vránu",
  "vrány",
  "vráně",
  "vrásy",
  "vrátí",
  "vráťa",
  "vráží",
  "vrývá",
  "vrčel",
  "vrčeň",
  "vrčte",
  "vrčím",
  "vrčíš",
  "vršce",
  "vršen",
  "vršil",
  "vršku",
  "vršky",
  "vršků",
  "vršme",
  "vršte",
  "vršíc",
  "vrším",
  "vršíš",
  "vržen",
  "vržou",
  "vsadí",
  "vsají",
  "vsetá",
  "vseté",
  "vskoč",
  "vstaň",
  "vstrč",
  "vsune",
  "vsunu",
  "vsype",
  "vsypu",
  "vsákl",
  "vsála",
  "vsáli",
  "vsálo",
  "vsály",
  "vsátá",
  "vsáté",
  "vsází",
  "vsích",
  "vsítí",
  "vtahů",
  "vteče",
  "vtipu",
  "vtipy",
  "vtipů",
  "vtoku",
  "vtoky",
  "vtoků",
  "vtrhl",
  "vtáhl",
  "vtéci",
  "vtéká",
  "vtéké",
  "vtéký",
  "vtírá",
  "vtíré",
  "vtírý",
  "vtělí",
  "vulvě",
  "vuřtu",
  "vuřty",
  "vuřtů",
  "vybal",
  "vybav",
  "vyber",
  "vybil",
  "vyboč",
  "vybra",
  "vybyv",
  "vyceň",
  "vyclí",
  "vycpe",
  "vycpi",
  "vycpu",
  "vydal",
  "vydej",
  "vyder",
  "vydou",
  "vydru",
  "vydry",
  "vydrž",
  "vydám",
  "vydán",
  "vydáš",
  "vydým",
  "vyděl",
  "vyděs",
  "vydří",
  "vyfoť",
  "vyhli",
  "vyhly",
  "vyhne",
  "vyhni",
  "vyhnu",
  "vyhol",
  "vyhov",
  "vyhoď",
  "vyhrň",
  "vyhub",
  "vyjda",
  "vyjde",
  "vyjdi",
  "vyjet",
  "vyjeď",
  "vyjme",
  "vyjmi",
  "vykal",
  "vykej",
  "vykou",
  "vykrm",
  "vykuk",
  "vykuř",
  "vykám",
  "vykáš",
  "vykém",
  "vykým",
  "vylaď",
  "vylez",
  "vyleč",
  "vyleť",
  "vylij",
  "vylil",
  "vylič",
  "vylom",
  "vylov",
  "vylož",
  "vyluč",
  "vyléč",
  "vymaž",
  "vymez",
  "vymeť",
  "vymkl",
  "vymoz",
  "vymoč",
  "vymra",
  "vymyj",
  "vymyl",
  "vyměň",
  "vyměř",
  "vymře",
  "vyndá",
  "vyndé",
  "vyndý",
  "vynes",
  "vynoř",
  "vynuť",
  "vyořu",
  "vypal",
  "vyper",
  "vypij",
  "vypil",
  "vypiš",
  "vypla",
  "vypli",
  "vyplo",
  "vyply",
  "vyplň",
  "vypne",
  "vypni",
  "vypnu",
  "vypoj",
  "vyptá",
  "vypuď",
  "vyraz",
  "vyrob",
  "vyros",
  "vyruš",
  "vyrve",
  "vyrvu",
  "vyryl",
  "vyryt",
  "vysaj",
  "vysaď",
  "vysní",
  "vysol",
  "vyspi",
  "vyspí",
  "vysuň",
  "vysuš",
  "vysál",
  "vysát",
  "vytkl",
  "vytoč",
  "vytra",
  "vytuš",
  "vytyč",
  "vytím",
  "vytěž",
  "vytře",
  "vytřu",
  "vyudí",
  "vyučí",
  "vyval",
  "vyvař",
  "vyvaž",
  "vyved",
  "vyvez",
  "vyveď",
  "vyvol",
  "vyvoď",
  "vyvát",
  "vyvěs",
  "vyzná",
  "vyzné",
  "vyzní",
  "vyzný",
  "vyzou",
  "vyzuj",
  "vyzul",
  "vyzve",
  "vyzvi",
  "vyzvu",
  "vyzám",
  "vyčká",
  "vyčte",
  "vyčti",
  "vyčtu",
  "vyňal",
  "vyňat",
  "vyřaď",
  "vyřeš",
  "vyřiď",
  "vyřkl",
  "vyřve",
  "vyšil",
  "vyšit",
  "vyšla",
  "vyšle",
  "vyšli",
  "vyšlo",
  "vyšly",
  "vyšlá",
  "vyšlé",
  "vyšší",
  "vyťal",
  "vyťat",
  "vyťav",
  "vyžer",
  "vyžil",
  "vyžít",
  "vzala",
  "vzali",
  "vzalo",
  "vzaly",
  "vzata",
  "vzati",
  "vzato",
  "vzaty",
  "vzatá",
  "vzaté",
  "vzatí",
  "vzbuď",
  "vzdal",
  "vzdej",
  "vzdou",
  "vzdul",
  "vzdál",
  "vzdám",
  "vzdát",
  "vzdáš",
  "vzdém",
  "vzkaž",
  "vzleť",
  "vzmuž",
  "vznes",
  "vzore",
  "vzory",
  "vzorů",
  "vzpaž",
  "vzplá",
  "vzplé",
  "vzplý",
  "vzpěr",
  "vztyč",
  "vzíti",
  "vzývá",
  "vzňal",
  "váben",
  "vábil",
  "vábná",
  "vábné",
  "vábně",
  "vábím",
  "vábíš",
  "vádou",
  "vágně",
  "váhal",
  "váhej",
  "váhou",
  "váhám",
  "váháš",
  "váhém",
  "váhým",
  "válce",
  "válci",
  "válců",
  "válej",
  "válek",
  "válel",
  "válem",
  "válku",
  "války",
  "válou",
  "válím",
  "válíš",
  "válčí",
  "vámou",
  "vánic",
  "vánku",
  "vánky",
  "vánoc",
  "váním",
  "vápna",
  "vápně",
  "várce",
  "várku",
  "várky",
  "várně",
  "vázal",
  "vázla",
  "vázli",
  "vázlo",
  "vázly",
  "vázne",
  "vázou",
  "vázám",
  "vázán",
  "váčku",
  "váčky",
  "váčků",
  "vášni",
  "vášní",
  "vášně",
  "vážce",
  "vážek",
  "vážen",
  "vážeš",
  "vážil",
  "vážko",
  "vážku",
  "vážky",
  "vážná",
  "vážné",
  "vážní",
  "vážně",
  "vážou",
  "vážíc",
  "vážím",
  "vážíš",
  "vébou",
  "védou",
  "védám",
  "vénou",
  "véček",
  "véčka",
  "véčku",
  "véčky",
  "vídal",
  "vídeň",
  "vídni",
  "vídní",
  "vídně",
  "vídou",
  "vídám",
  "vídáš",
  "vídém",
  "vídým",
  "víkem",
  "víkům",
  "vílou",
  "vílám",
  "vínek",
  "vínem",
  "vínka",
  "vínku",
  "vínou",
  "vínům",
  "vírem",
  "vírou",
  "vírům",
  "vísce",
  "vísku",
  "vísky",
  "víská",
  "vítal",
  "vítej",
  "vítek",
  "vítem",
  "vítka",
  "vítku",
  "vítou",
  "vítám",
  "vítán",
  "vítém",
  "vítým",
  "vítěz",
  "vítův",
  "vízem",
  "vízou",
  "vízům",
  "víček",
  "víčka",
  "víčku",
  "víčky",
  "vířil",
  "vířím",
  "víříš",
  "víťův",
  "vížce",
  "vížky",
  "vótum",
  "výbav",
  "výděl",
  "výher",
  "výhni",
  "výhní",
  "výhně",
  "výhod",
  "výhru",
  "výhry",
  "výhře",
  "výkmu",
  "výkok",
  "výlep",
  "výloh",
  "výluk",
  "výměn",
  "výnor",
  "výrem",
  "výrob",
  "výrou",
  "výrům",
  "výsad",
  "výsaz",
  "výsky",
  "výská",
  "výspu",
  "výspy",
  "výspě",
  "výsyp",
  "výtce",
  "výtek",
  "výtku",
  "výtky",
  "výtoň",
  "výuce",
  "výuku",
  "výuky",
  "vývrh",
  "vývěv",
  "výzev",
  "výzvu",
  "výzvy",
  "výzvě",
  "výčtu",
  "výčty",
  "výčtů",
  "výšce",
  "výšek",
  "výšin",
  "výško",
  "výšku",
  "výšky",
  "včele",
  "včelu",
  "včely",
  "včelí",
  "včetn",
  "včleň",
  "věcem",
  "věcmi",
  "věcná",
  "věcné",
  "věcní",
  "věcně",
  "věcov",
  "věcím",
  "vědce",
  "vědci",
  "vědců",
  "věder",
  "vědmy",
  "vědmě",
  "vědná",
  "vědné",
  "vědný",
  "vědou",
  "vědra",
  "vědru",
  "vědry",
  "vědám",
  "vědče",
  "věděl",
  "vějou",
  "vějít",
  "věkem",
  "věkům",
  "věnce",
  "věnci",
  "věnců",
  "věnem",
  "věnná",
  "věnné",
  "věnuj",
  "věrce",
  "věren",
  "věrka",
  "věrky",
  "věrna",
  "věrni",
  "věrno",
  "věrnu",
  "věrny",
  "věrná",
  "věrné",
  "věrní",
  "věrně",
  "věrou",
  "věsme",
  "věsou",
  "věste",
  "větna",
  "větná",
  "větné",
  "větně",
  "větou",
  "větrá",
  "větré",
  "větrý",
  "větve",
  "větvi",
  "větví",
  "větám",
  "větří",
  "větši",
  "větší",
  "vězel",
  "vězen",
  "vězeň",
  "vězni",
  "vězná",
  "vězné",
  "vězní",
  "vězný",
  "vězně",
  "vězte",
  "vězím",
  "vězíš",
  "vězňů",
  "věčna",
  "věčná",
  "věčné",
  "věční",
  "věčně",
  "věřil",
  "věřin",
  "věřme",
  "věřte",
  "věříc",
  "věřím",
  "věříš",
  "věšel",
  "věšen",
  "věšet",
  "věšme",
  "věšte",
  "věští",
  "věším",
  "věšín",
  "věšíš",
  "věžná",
  "věžné",
  "věžní",
  "věžný",
  "věžím",
  "vřadí",
  "vřavu",
  "vřavy",
  "vřavě",
  "vředu",
  "vředy",
  "vředů",
  "vřela",
  "vřele",
  "vřelo",
  "vřely",
  "vřelá",
  "vřelé",
  "vřelí",
  "vřené",
  "vřesu",
  "vřete",
  "vřítí",
  "všade",
  "všema",
  "všije",
  "všiji",
  "všijí",
  "všila",
  "všili",
  "všilo",
  "všily",
  "všiml",
  "všitá",
  "všité",
  "všivá",
  "všivé",
  "všímá",
  "vťatá",
  "vťaté",
  "vťatý",
  "vůdce",
  "vůdci",
  "vůdců",
  "vůdče",
  "vůdčí",
  "vůlou",
  "vůním",
  "vždyť",
  "vžene",
  "vžije",
  "vžiju",
  "vžijí",
  "vžila",
  "vžili",
  "vžilo",
  "vžily",
  "vžito",
  "vžitá",
  "vžité",
  "vžitě",
  "vžívá",
  "wales",
  "warez",
  "wattu",
  "watty",
  "wattů",
  "webem",
  "weber",
  "webík",
  "webům",
  "widou",
  "wimax",
  "winfs",
  "winfx",
  "winnt",
  "winxp",
  "wordu",
  "wordů",
  "wormu",
  "wormů",
  "xaver",
  "xboxu",
  "xboxy",
  "xboxů",
  "xenix",
  "xhtml",
  "yahoo",
  "yardu",
  "yardy",
  "yardů",
  "yetti",
  "yorku",
  "yveta",
  "yveto",
  "yvetu",
  "yvety",
  "yvetě",
  "yvona",
  "yšují",
  "zabal",
  "zabav",
  "zaber",
  "zabij",
  "zabil",
  "zabme",
  "zaboč",
  "zabte",
  "zabuč",
  "zabuš",
  "zacpe",
  "zacpi",
  "zadal",
  "zadej",
  "zadku",
  "zadky",
  "zadků",
  "zadou",
  "zadra",
  "zadrž",
  "zadul",
  "zadus",
  "zadám",
  "zadán",
  "zadáš",
  "zadém",
  "zadým",
  "zadře",
  "zadřu",
  "zahaj",
  "zahal",
  "zahlť",
  "zahne",
  "zahni",
  "zahnu",
  "zahou",
  "zahoď",
  "zahrň",
  "zahém",
  "zahým",
  "zairu",
  "zajda",
  "zajde",
  "zajdi",
  "zajet",
  "zajez",
  "zaječ",
  "zajeď",
  "zajme",
  "zajmi",
  "zajím",
  "zajíš",
  "zakal",
  "zakaž",
  "zalej",
  "zalep",
  "zalez",
  "zalij",
  "zalil",
  "zalkl",
  "zalká",
  "zalom",
  "založ",
  "zalže",
  "zamaž",
  "zameč",
  "zameť",
  "zamiř",
  "zamkl",
  "zamlč",
  "zaměň",
  "zaměř",
  "zanes",
  "zaora",
  "zaorá",
  "zapal",
  "zapij",
  "zapil",
  "zapiš",
  "zapla",
  "zaplá",
  "zaplé",
  "zaplý",
  "zaplň",
  "zapne",
  "zapni",
  "zapnu",
  "zapoj",
  "zapěj",
  "zapře",
  "zapři",
  "zapřu",
  "zaraz",
  "zardí",
  "zaros",
  "zaruč",
  "zaryj",
  "zaryl",
  "zaráz",
  "zasaď",
  "zasej",
  "zasní",
  "zasou",
  "zaspí",
  "zasut",
  "zasuň",
  "zasyp",
  "zasyč",
  "zatci",
  "zatec",
  "zatkl",
  "zatmí",
  "zatne",
  "zatni",
  "zatop",
  "zatoč",
  "zatra",
  "zauce",
  "zauče",
  "zaučí",
  "zaval",
  "zavaď",
  "zavař",
  "zavaž",
  "zavdá",
  "zavel",
  "zavez",
  "zaveď",
  "zaviň",
  "zavrč",
  "završ",
  "zavrť",
  "zavyl",
  "zavál",
  "zavát",
  "zavíš",
  "zavěs",
  "zavře",
  "zavři",
  "zavřu",
  "zazdá",
  "zazdé",
  "zazdí",
  "zazdý",
  "zazle",
  "zazní",
  "zaúpí",
  "začal",
  "začas",
  "začat",
  "začlo",
  "začly",
  "začne",
  "začni",
  "začnu",
  "začte",
  "zařaď",
  "zařiď",
  "zařve",
  "zařvi",
  "zařvu",
  "zašij",
  "zašil",
  "zašit",
  "zašla",
  "zašle",
  "zašli",
  "zašlo",
  "zašly",
  "zašlá",
  "zašlé",
  "zaťal",
  "zaťat",
  "zaťav",
  "zažeň",
  "zažij",
  "zažil",
  "zažit",
  "zažít",
  "zbaví",
  "zbije",
  "zbiju",
  "zbijí",
  "zbila",
  "zbili",
  "zbilo",
  "zbily",
  "zbita",
  "zbiti",
  "zbito",
  "zbity",
  "zbitá",
  "zbité",
  "zblil",
  "zblou",
  "zboří",
  "zbrkl",
  "zbude",
  "zbudí",
  "zbyde",
  "zbyla",
  "zbyle",
  "zbyli",
  "zbylo",
  "zbyly",
  "zbylá",
  "zbylé",
  "zbylí",
  "zbytý",
  "zbývá",
  "zbývé",
  "zbývý",
  "zběha",
  "zběhl",
  "zběhy",
  "zběhů",
  "zbělá",
  "zbělé",
  "zbělý",
  "zbůch",
  "zcizí",
  "zdaje",
  "zdají",
  "zdaní",
  "zdaru",
  "zdary",
  "zdaří",
  "zdena",
  "zdeno",
  "zdenu",
  "zdeny",
  "zdeně",
  "zdiby",
  "zdice",
  "zdicí",
  "zdila",
  "zdili",
  "zdilo",
  "zdily",
  "zdiva",
  "zdive",
  "zdivu",
  "zdivy",
  "zdivě",
  "zdnet",
  "zdobí",
  "zdolá",
  "zdrav",
  "zdraž",
  "zdrhl",
  "zdrhá",
  "zdrtí",
  "zdráv",
  "zdrže",
  "zdrži",
  "zdrží",
  "zduří",
  "zdála",
  "zdálo",
  "zdály",
  "zdáme",
  "zdáte",
  "zdáti",
  "zdává",
  "zdého",
  "zdému",
  "zdíce",
  "zdíša",
  "zdých",
  "zdýma",
  "zdými",
  "zdědí",
  "zděná",
  "zděné",
  "zděsí",
  "zděře",
  "zděří",
  "zeber",
  "zebou",
  "zebru",
  "zebry",
  "zebře",
  "zebří",
  "zední",
  "zedum",
  "zejou",
  "zelen",
  "zelná",
  "zelné",
  "zelím",
  "zeman",
  "zemin",
  "zemra",
  "zemím",
  "zemře",
  "zemři",
  "zemřu",
  "zenat",
  "zeptá",
  "zepté",
  "zeptý",
  "zetlí",
  "zetor",
  "zetou",
  "zetěm",
  "zevně",
  "zezou",
  "zeťům",
  "zeťův",
  "zhasl",
  "zhatí",
  "zhltl",
  "zhltá",
  "zhojí",
  "zhubl",
  "zhyne",
  "zhynu",
  "zháší",
  "zické",
  "zilog",
  "zimou",
  "zimám",
  "zinin",
  "zinku",
  "zinou",
  "zipem",
  "zipům",
  "zisku",
  "zisky",
  "zisků",
  "zitin",
  "zitou",
  "zitům",
  "zjeho",
  "zjevu",
  "zjevy",
  "zjeví",
  "zjevů",
  "zježí",
  "zjihl",
  "zkalí",
  "zkazí",
  "zkosí",
  "zkrať",
  "zkrmí",
  "zkroť",
  "zkumu",
  "zkusí",
  "zkáze",
  "zkázu",
  "zkázy",
  "zkřiž",
  "zlata",
  "zlatu",
  "zlaty",
  "zlatá",
  "zlaté",
  "zlatí",
  "zlatě",
  "zlech",
  "zlezl",
  "zlije",
  "zlila",
  "zlili",
  "zlilo",
  "zlily",
  "zlitá",
  "zlité",
  "zlobo",
  "zlobr",
  "zlobu",
  "zloby",
  "zlobí",
  "zlobě",
  "zlome",
  "zlomu",
  "zlomy",
  "zlomí",
  "zlomů",
  "zlost",
  "zlotá",
  "zloté",
  "zlotý",
  "zláká",
  "zláké",
  "zláký",
  "zláme",
  "zlámu",
  "zlého",
  "zlému",
  "zlíbí",
  "zlína",
  "zlínu",
  "zlíně",
  "zlých",
  "zlýma",
  "zlými",
  "zmaru",
  "zmate",
  "zmaří",
  "zmelu",
  "zmere",
  "zmiji",
  "zmijí",
  "zmizl",
  "zmizí",
  "zmlať",
  "zmlkl",
  "zmohl",
  "zmohu",
  "zmokl",
  "zmoří",
  "zmraz",
  "zmrzl",
  "zmrší",
  "zmáhá",
  "zmáhé",
  "zmáhý",
  "zmála",
  "zmámí",
  "zmáčí",
  "zmíní",
  "zmírá",
  "zmíré",
  "zmírý",
  "zmítá",
  "zmíté",
  "zmítý",
  "zmýlí",
  "změkl",
  "změmy",
  "změnu",
  "změny",
  "změní",
  "změně",
  "změti",
  "změtí",
  "změří",
  "zmůže",
  "zmůžu",
  "znají",
  "znaku",
  "znaky",
  "znaků",
  "znala",
  "znali",
  "znalo",
  "znaly",
  "znalá",
  "znalé",
  "znalí",
  "znače",
  "značí",
  "znivá",
  "znivé",
  "zničí",
  "známa",
  "známe",
  "známi",
  "známo",
  "známu",
  "známy",
  "známá",
  "známé",
  "známí",
  "známě",
  "znáte",
  "zného",
  "znému",
  "zníme",
  "zníte",
  "zníti",
  "zných",
  "znýma",
  "znými",
  "znějí",
  "zněla",
  "zněle",
  "zněli",
  "znělo",
  "zněly",
  "znělá",
  "znělé",
  "zněni",
  "zněné",
  "zobal",
  "zobat",
  "zobej",
  "zobem",
  "zobeš",
  "zobne",
  "zobou",
  "zobém",
  "zobým",
  "zofie",
  "zofin",
  "zojin",
  "zojou",
  "zoomu",
  "zoomy",
  "zoomů",
  "zoral",
  "zorej",
  "zorka",
  "zorná",
  "zorné",
  "zorný",
  "zorou",
  "zorám",
  "zorán",
  "zoráš",
  "zoufá",
  "zoufé",
  "zoufý",
  "zouvá",
  "zovat",
  "zořin",
  "zpila",
  "zpili",
  "zpilo",
  "zpily",
  "zpitá",
  "zpité",
  "zploď",
  "zpotí",
  "zprav",
  "zpráv",
  "zpíjí",
  "zpívá",
  "zpívé",
  "zpívý",
  "zpění",
  "zpěvu",
  "zpěvy",
  "zpěvů",
  "způso",
  "zrací",
  "zradu",
  "zrady",
  "zradí",
  "zradě",
  "zraje",
  "zrají",
  "zraku",
  "zraky",
  "zrale",
  "zralo",
  "zraly",
  "zralá",
  "zralé",
  "zralí",
  "zraní",
  "zračí",
  "zreje",
  "zrnek",
  "zrnem",
  "zrnin",
  "zrnka",
  "zrnku",
  "zrnky",
  "zrnou",
  "zrním",
  "zrněl",
  "zrnům",
  "zrodu",
  "zrodí",
  "zrudl",
  "zruse",
  "zruče",
  "zruší",
  "zryje",
  "zryji",
  "zryla",
  "zryli",
  "zrylo",
  "zryly",
  "zrytá",
  "zryté",
  "zrzce",
  "zrzku",
  "zrzky",
  "zrála",
  "zráli",
  "zrálo",
  "zrály",
  "zrůdo",
  "zrůdu",
  "zrůdy",
  "zrůdě",
  "zteči",
  "ztečí",
  "ztiší",
  "ztlum",
  "ztluč",
  "ztmav",
  "ztrať",
  "ztrát",
  "ztuhl",
  "ztupí",
  "ztuží",
  "ztéci",
  "ztíží",
  "ztěží",
  "zubem",
  "zubil",
  "zubne",
  "zubná",
  "zubné",
  "zubra",
  "zubry",
  "zubrů",
  "zubři",
  "zubří",
  "zubům",
  "zuješ",
  "zujme",
  "zujou",
  "zujte",
  "zulou",
  "zumbu",
  "zumby",
  "zumbě",
  "zutou",
  "zutém",
  "zutým",
  "zuzce",
  "zuzka",
  "zuzko",
  "zuzku",
  "zuzky",
  "zuřil",
  "zuřím",
  "zuříš",
  "zužme",
  "zužte",
  "zvací",
  "zvadl",
  "zvala",
  "zvali",
  "zvalo",
  "zvaly",
  "zvaná",
  "zvané",
  "zvaně",
  "zvedl",
  "zvedá",
  "zvedé",
  "zvedý",
  "zveme",
  "zvete",
  "zvlní",
  "zvole",
  "zvolá",
  "zvolé",
  "zvolí",
  "zvolý",
  "zvonu",
  "zvony",
  "zvoní",
  "zvonů",
  "zvorá",
  "zvrať",
  "zvrhl",
  "zvrtl",
  "zvuku",
  "zvuky",
  "zvuků",
  "zvykl",
  "zvyku",
  "zvyky",
  "zvyká",
  "zvyků",
  "zvána",
  "zváni",
  "zváno",
  "zvání",
  "zváží",
  "zvící",
  "zvíme",
  "zvíte",
  "zvíří",
  "zvýší",
  "zvěda",
  "zvěde",
  "zvědi",
  "zvědu",
  "zvědy",
  "zvědí",
  "zvědů",
  "zvěře",
  "zvěři",
  "zvěří",
  "zvůli",
  "zábav",
  "zábla",
  "zábli",
  "záblo",
  "zábly",
  "zácpu",
  "zácpy",
  "zácpě",
  "zádní",
  "zádou",
  "zádím",
  "zádům",
  "záhad",
  "záhul",
  "zájem",
  "zájme",
  "zájmy",
  "zájmů",
  "zákys",
  "zálež",
  "zálib",
  "záloh",
  "zálud",
  "zámku",
  "zámky",
  "zámků",
  "záměn",
  "záruk",
  "zásad",
  "zásob",
  "zátce",
  "zátek",
  "zátku",
  "zátky",
  "zátor",
  "závad",
  "závaz",
  "závor",
  "závoď",
  "závět",
  "závěť",
  "zářil",
  "zářná",
  "zářné",
  "záříc",
  "zářím",
  "záříš",
  "zášti",
  "zídce",
  "zídek",
  "zídku",
  "zídky",
  "zíral",
  "zírou",
  "zírám",
  "zírém",
  "zírým",
  "získá",
  "získé",
  "získý",
  "zítek",
  "zíval",
  "zívej",
  "zívla",
  "zívne",
  "zívou",
  "zívám",
  "zívém",
  "zívým",
  "zónou",
  "zónám",
  "zúroč",
  "zúžen",
  "zúžil",
  "zúžit",
  "zúžím",
  "zúžíš",
  "zýval",
  "zčeří",
  "zředí",
  "zřela",
  "zřeli",
  "zřelo",
  "zřely",
  "zřená",
  "zřené",
  "zříce",
  "zříci",
  "zříct",
  "zřídl",
  "zřídí",
  "zříká",
  "zříké",
  "zříký",
  "zřítí",
  "zželí",
  "áriím",
  "átriu",
  "átrií",
  "ážiem",
  "érami",
  "érách",
  "éteru",
  "íránu",
  "íráně",
  "ódami",
  "ódium",
  "ódách",
  "úboru",
  "úbory",
  "úborů",
  "úbočí",
  "úbyte",
  "úbytě",
  "úběru",
  "úchop",
  "úchyl",
  "úctou",
  "údaje",
  "údaji",
  "údaju",
  "údajů",
  "údech",
  "údere",
  "úderu",
  "údery",
  "úderů",
  "údivu",
  "údobí",
  "údolí",
  "údová",
  "údové",
  "údový",
  "údělu",
  "úděly",
  "údělů",
  "úděsu",
  "úhlem",
  "úhlou",
  "úhlům",
  "úhonu",
  "úhony",
  "úhoně",
  "úhoru",
  "úhory",
  "úhorů",
  "úhozu",
  "úhozy",
  "úhozů",
  "úhoře",
  "úhoři",
  "úhoří",
  "úhořů",
  "úhrad",
  "úhrnu",
  "úhrny",
  "úhrnů",
  "úhynu",
  "úhyny",
  "úhynů",
  "újmou",
  "újmám",
  "úkapu",
  "úkazu",
  "úkazy",
  "úkazů",
  "úkole",
  "úkolu",
  "úkoly",
  "úkolů",
  "úkonu",
  "úkony",
  "úkonů",
  "úkosu",
  "úkosy",
  "úkosů",
  "úlech",
  "úleku",
  "úleků",
  "úletu",
  "úlety",
  "úletů",
  "úlevu",
  "úlevy",
  "úlevě",
  "úlice",
  "úlohu",
  "úlohy",
  "úloze",
  "úmluv",
  "úmoru",
  "úmrti",
  "úmrtí",
  "úměru",
  "úměry",
  "úměře",
  "únavu",
  "únavy",
  "únavě",
  "úniku",
  "úniky",
  "úniků",
  "února",
  "únoru",
  "únosu",
  "únosy",
  "únosů",
  "úpade",
  "úpadu",
  "úpalu",
  "úpaly",
  "úpatí",
  "úpice",
  "úpisu",
  "úpisy",
  "úpisů",
  "úplat",
  "úplná",
  "úplné",
  "úplní",
  "úplný",
  "úplně",
  "úponu",
  "úpony",
  "úponů",
  "úprav",
  "úprku",
  "úprky",
  "úpská",
  "úpské",
  "úpský",
  "úpící",
  "úpíme",
  "úpíte",
  "úpějí",
  "úpěla",
  "úpěli",
  "úpělo",
  "úpěly",
  "úpění",
  "úraze",
  "úrazu",
  "úrazy",
  "úrazů",
  "úrodu",
  "úrody",
  "úrodě",
  "úroku",
  "úroky",
  "úroků",
  "úročí",
  "úseku",
  "úseky",
  "úseků",
  "úseče",
  "úseči",
  "úsečí",
  "úsilí",
  "úsluh",
  "úsobí",
  "úspor",
  "ústil",
  "ústit",
  "ústní",
  "ústně",
  "ústou",
  "ústím",
  "ústům",
  "úsude",
  "úterá",
  "úteré",
  "úterý",
  "útesu",
  "útesy",
  "útesů",
  "útkou",
  "útlou",
  "útlém",
  "útlým",
  "útoku",
  "útoky",
  "útoků",
  "útočí",
  "útrap",
  "útrat",
  "útrob",
  "útrum",
  "útěku",
  "útěky",
  "útěků",
  "útěše",
  "úvahu",
  "úvahy",
  "úvalu",
  "úvaly",
  "úvalů",
  "úvaze",
  "úvazu",
  "úvazy",
  "úvazů",
  "úvodu",
  "úvody",
  "úvodí",
  "úvodů",
  "úvozu",
  "úvrať",
  "úvěru",
  "úvěry",
  "úvěrů",
  "území",
  "úzkou",
  "úzkém",
  "úzkým",
  "účast",
  "účaří",
  "účelu",
  "účely",
  "účelů",
  "účesu",
  "účesy",
  "účesů",
  "účine",
  "účiny",
  "účtem",
  "účtuj",
  "účtům",
  "úřadu",
  "úřady",
  "úřadě",
  "úřadů",
  "úštěk",
  "úžasu",
  "úžehu",
  "úžehy",
  "úžehů",
  "úžeji",
  "úžená",
  "úžené",
  "úžení",
  "úžený",
  "úžice",
  "úžinu",
  "úžiny",
  "úžině",
  "úžlab",
  "čacká",
  "čacké",
  "čadca",
  "čadci",
  "čajem",
  "čajům",
  "čakov",
  "čapce",
  "čapek",
  "čapku",
  "čapky",
  "čapím",
  "čaruj",
  "časná",
  "časné",
  "časně",
  "časou",
  "častá",
  "časté",
  "častí",
  "častý",
  "časuj",
  "časům",
  "čebín",
  "čecha",
  "čechu",
  "čechy",
  "čechů",
  "čedok",
  "čehou",
  "čehém",
  "čehým",
  "čejku",
  "čejky",
  "čekal",
  "čekej",
  "čekou",
  "čekám",
  "čekáš",
  "čekém",
  "čekým",
  "čelem",
  "čelen",
  "čelil",
  "čelná",
  "čelné",
  "čelně",
  "čelte",
  "čelím",
  "čelíš",
  "čelům",
  "čenda",
  "čeněk",
  "čepce",
  "čepci",
  "čepců",
  "čepem",
  "čepic",
  "čepku",
  "čepům",
  "černa",
  "černi",
  "černá",
  "černé",
  "černí",
  "černý",
  "černě",
  "čerpá",
  "čerpé",
  "čerpý",
  "čerta",
  "čerte",
  "čerti",
  "čertu",
  "čerty",
  "čertí",
  "čertů",
  "červa",
  "červe",
  "červi",
  "červu",
  "červy",
  "červí",
  "červů",
  "česal",
  "česat",
  "česka",
  "česko",
  "česky",
  "česká",
  "české",
  "česla",
  "česlo",
  "česly",
  "česna",
  "česnu",
  "četbu",
  "četby",
  "četbě",
  "četla",
  "četli",
  "četlo",
  "četly",
  "četná",
  "četné",
  "četní",
  "četně",
  "četou",
  "četám",
  "čečna",
  "čeňka",
  "čeřil",
  "čeřím",
  "čeříš",
  "češce",
  "češek",
  "češeš",
  "češka",
  "češku",
  "češky",
  "češme",
  "češou",
  "češte",
  "čeští",
  "čichu",
  "čichá",
  "čiché",
  "čichý",
  "čidel",
  "čidla",
  "čidle",
  "čidlu",
  "čidly",
  "čihař",
  "čikov",
  "čilou",
  "čilém",
  "čilým",
  "čince",
  "činek",
  "činem",
  "činil",
  "činku",
  "činky",
  "činni",
  "činno",
  "činná",
  "činné",
  "činní",
  "činou",
  "činím",
  "činíš",
  "činěn",
  "činům",
  "činži",
  "činží",
  "čipem",
  "čipům",
  "čirou",
  "čirém",
  "čirým",
  "čista",
  "čisti",
  "čisto",
  "čistá",
  "čisté",
  "čistí",
  "čistý",
  "čistě",
  "čiňme",
  "čiňte",
  "čišel",
  "čkyně",
  "člena",
  "člene",
  "členi",
  "členu",
  "členy",
  "člení",
  "členě",
  "členů",
  "člunu",
  "čluny",
  "člunů",
  "článk",
  "čmouh",
  "čmárá",
  "čnějí",
  "čněla",
  "čněli",
  "čněly",
  "čoklů",
  "čolci",
  "čolka",
  "čolky",
  "čolků",
  "čoudu",
  "čouhá",
  "čočce",
  "čoček",
  "čočku",
  "čočky",
  "čpavá",
  "čpavé",
  "čpějí",
  "črtal",
  "črtou",
  "čskos",
  "čslou",
  "čsvts",
  "čteme",
  "čtena",
  "čteno",
  "čteny",
  "čtená",
  "čtené",
  "čtete",
  "čtiva",
  "čtivu",
  "čtivá",
  "čtivé",
  "čtivě",
  "čtové",
  "čtuje",
  "čtyry",
  "čtěme",
  "čtěte",
  "čubko",
  "čubku",
  "čubou",
  "čubčí",
  "čumil",
  "čumíš",
  "čuměl",
  "čural",
  "čvrté",
  "čápem",
  "čápic",
  "čápou",
  "čápům",
  "čárce",
  "čárek",
  "čárku",
  "čárky",
  "čárou",
  "čárám",
  "části",
  "částk",
  "částí",
  "čéšce",
  "čéšek",
  "čéšku",
  "čéšky",
  "číhal",
  "číhej",
  "číhou",
  "číhám",
  "číháš",
  "číhém",
  "číhým",
  "čílil",
  "číměř",
  "čínou",
  "čípku",
  "čípky",
  "čípků",
  "čírce",
  "čírek",
  "čírem",
  "čírku",
  "čírky",
  "čísel",
  "čísla",
  "čísle",
  "čísli",
  "číslu",
  "čísly",
  "čísti",
  "čítal",
  "čítou",
  "čítám",
  "čítém",
  "čítým",
  "číňan",
  "číšky",
  "číším",
  "čížci",
  "čížka",
  "čížky",
  "čížků",
  "čůrou",
  "čůrám",
  "čůráš",
  "ďasou",
  "ďasův",
  "ďobat",
  "ďábel",
  "ďábla",
  "ďáble",
  "ďábli",
  "ďáblu",
  "ďábly",
  "ďáblů",
  "ňader",
  "ňadra",
  "ňadro",
  "ňadru",
  "ňadry",
  "ňafat",
  "ňouma",
  "ňumou",
  "ňumův",
  "řadem",
  "řadil",
  "řadit",
  "řadou",
  "řadám",
  "řadím",
  "řasou",
  "řasám",
  "řazen",
  "řaďte",
  "řecka",
  "řecko",
  "řecku",
  "řecky",
  "řecká",
  "řecké",
  "ředil",
  "ředím",
  "ředíš",
  "ředěn",
  "řehoř",
  "řehtá",
  "řehté",
  "řehtý",
  "řekem",
  "řekla",
  "řekli",
  "řeklo",
  "řekly",
  "řekne",
  "řekni",
  "řekou",
  "řekyň",
  "řekáh",
  "řekám",
  "řekům",
  "řekův",
  "řenče",
  "řepce",
  "řepku",
  "řepky",
  "řepná",
  "řepné",
  "řepou",
  "řepín",
  "řevem",
  "řezal",
  "řezat",
  "řezby",
  "řezbě",
  "řezem",
  "řeziv",
  "řezná",
  "řezné",
  "řezně",
  "řezán",
  "řezáč",
  "řečem",
  "řečma",
  "řečmi",
  "řečni",
  "řečná",
  "řečné",
  "řeční",
  "řečtí",
  "řečík",
  "řečím",
  "řeďte",
  "řešen",
  "řešet",
  "řešil",
  "řešme",
  "řešte",
  "řešíc",
  "řeším",
  "řešíš",
  "řežbu",
  "řežby",
  "řežeš",
  "řežme",
  "řežou",
  "řežte",
  "řidší",
  "řinou",
  "řinul",
  "řinčí",
  "řitka",
  "řiďme",
  "řiďte",
  "řvala",
  "řvali",
  "řvalo",
  "řvaly",
  "řvaná",
  "řvané",
  "řvavá",
  "řvavé",
  "řveme",
  "řvete",
  "řvěme",
  "řvěte",
  "řácká",
  "řácké",
  "řádce",
  "řádem",
  "řádil",
  "řádko",
  "řádku",
  "řádky",
  "řádků",
  "řádná",
  "řádné",
  "řádní",
  "řádně",
  "řádou",
  "řádím",
  "řádům",
  "řídce",
  "řídil",
  "řídká",
  "řídké",
  "řídly",
  "řídne",
  "řídím",
  "řídíš",
  "říhal",
  "říhne",
  "říjen",
  "říjet",
  "říjmy",
  "říjmů",
  "října",
  "říjnu",
  "říjná",
  "říjné",
  "říjou",
  "říkal",
  "říkej",
  "říkám",
  "říkáš",
  "říman",
  "římem",
  "římov",
  "římse",
  "římsu",
  "římsy",
  "řípou",
  "řítil",
  "řítím",
  "řítíš",
  "řízen",
  "řízku",
  "řízky",
  "řízků",
  "řízla",
  "řízli",
  "řízlo",
  "řízly",
  "řízne",
  "řízni",
  "říznu",
  "řízná",
  "řízné",
  "řízně",
  "řízou",
  "řízám",
  "říčan",
  "říčce",
  "říček",
  "říčku",
  "říčky",
  "říčná",
  "říčné",
  "říčně",
  "říším",
  "šablo",
  "šachu",
  "šachy",
  "šachů",
  "šafov",
  "šahal",
  "šahat",
  "šajdá",
  "šajdé",
  "šajdý",
  "šalbě",
  "šalup",
  "šamoa",
  "šanci",
  "šancí",
  "šarmu",
  "šarmů",
  "šarád",
  "šarži",
  "šarží",
  "šatem",
  "šaten",
  "šatil",
  "šatnu",
  "šatny",
  "šatní",
  "šatně",
  "šatou",
  "šatov",
  "šatím",
  "šatíš",
  "šatům",
  "šavli",
  "šavlí",
  "šašci",
  "šaška",
  "šaško",
  "šašky",
  "šašků",
  "šedin",
  "šedou",
  "šedák",
  "šedém",
  "šedým",
  "šedší",
  "šejch",
  "šejka",
  "šejky",
  "šejků",
  "šekem",
  "šekům",
  "šelem",
  "šelfu",
  "šelfy",
  "šelfů",
  "šelmo",
  "šelmu",
  "šelmy",
  "šelmí",
  "šelmě",
  "šenku",
  "šenou",
  "šenov",
  "šeném",
  "šeným",
  "šeptl",
  "šeptá",
  "šepté",
  "šeptý",
  "šerem",
  "šerif",
  "šermu",
  "šerou",
  "šerpu",
  "šerpy",
  "šerpě",
  "šerpů",
  "šerém",
  "šerým",
  "šesti",
  "šestá",
  "šesté",
  "šestí",
  "šestý",
  "šetře",
  "šetři",
  "šetří",
  "šeřil",
  "šibal",
  "šicím",
  "šidil",
  "šidím",
  "šidíš",
  "šifer",
  "šifru",
  "šifry",
  "šifře",
  "šiješ",
  "šijte",
  "šikan",
  "šikem",
  "šikla",
  "šikli",
  "šiklo",
  "šikly",
  "šikmo",
  "šikmá",
  "šikmé",
  "šikmě",
  "šikne",
  "šikné",
  "šikům",
  "šilhá",
  "šilhé",
  "šilhý",
  "šimla",
  "šimon",
  "šineš",
  "šinou",
  "šintó",
  "šinul",
  "šipce",
  "šipek",
  "šipku",
  "šipky",
  "širou",
  "širém",
  "širým",
  "širší",
  "šitou",
  "šitém",
  "šitím",
  "šitým",
  "šizen",
  "šičce",
  "šiček",
  "šičku",
  "šičky",
  "šiřme",
  "šiřte",
  "šišce",
  "šišek",
  "šišku",
  "šišky",
  "šišlá",
  "škodu",
  "škody",
  "škodí",
  "škodě",
  "škole",
  "školn",
  "školu",
  "školy",
  "školí",
  "škota",
  "škrtl",
  "škrtu",
  "škrty",
  "škrtá",
  "škrtí",
  "škrtý",
  "škrtů",
  "škrče",
  "škrčí",
  "škube",
  "škubl",
  "škuby",
  "škump",
  "škvír",
  "škytl",
  "škytá",
  "škále",
  "škálu",
  "škály",
  "škáru",
  "škáry",
  "škáře",
  "šlach",
  "šlapa",
  "šlape",
  "šlapu",
  "šlaše",
  "šlehl",
  "šlehu",
  "šlehy",
  "šlehá",
  "šlehé",
  "šlehý",
  "šlemi",
  "šlová",
  "šlové",
  "šluky",
  "šluků",
  "šlápl",
  "šlích",
  "šmouh",
  "šmíru",
  "šmíře",
  "šnečí",
  "šohaj",
  "šokem",
  "šokům",
  "šonov",
  "šosať",
  "šosem",
  "šosům",
  "šotci",
  "šotem",
  "šotka",
  "šotku",
  "šotky",
  "šotků",
  "šotům",
  "šoufl",
  "šoupe",
  "šoupl",
  "šoupá",
  "šoupé",
  "šoupý",
  "šourá",
  "šoust",
  "špeha",
  "špehy",
  "špehů",
  "špeku",
  "špeky",
  "špeků",
  "špici",
  "špicl",
  "špicí",
  "špiní",
  "špitl",
  "špitá",
  "špité",
  "špitý",
  "špión",
  "šplhu",
  "šplhá",
  "šplhé",
  "šplhý",
  "šponu",
  "špony",
  "šprta",
  "šprti",
  "šprty",
  "šprtá",
  "šprté",
  "šprtý",
  "špulí",
  "špády",
  "špásu",
  "špínu",
  "špíny",
  "špíně",
  "špízu",
  "špízy",
  "špízů",
  "šrotu",
  "šroty",
  "šrámu",
  "šrámy",
  "šrámů",
  "štaci",
  "štice",
  "štiku",
  "štiky",
  "štičí",
  "štkal",
  "štkát",
  "štoky",
  "štole",
  "štolu",
  "štoly",
  "štosu",
  "štosy",
  "štuku",
  "štval",
  "štvou",
  "štván",
  "štvát",
  "štábu",
  "štáby",
  "štábů",
  "štípe",
  "štípl",
  "štípu",
  "štíra",
  "štíru",
  "štíry",
  "štírů",
  "štítu",
  "štíty",
  "štítí",
  "štítě",
  "štítů",
  "štíři",
  "štíří",
  "štěkl",
  "štěku",
  "štěky",
  "štěká",
  "štěké",
  "štěký",
  "štěků",
  "štěná",
  "štěné",
  "štěný",
  "štěpu",
  "štěpy",
  "štěpí",
  "štěpů",
  "štětu",
  "štětí",
  "šuhaj",
  "šukal",
  "šukej",
  "šukám",
  "šukáš",
  "šumem",
  "šumná",
  "šumné",
  "šumím",
  "šumíš",
  "šuměl",
  "šumům",
  "šunce",
  "šunek",
  "šunku",
  "šunky",
  "šuntu",
  "šunty",
  "šuntů",
  "šupin",
  "šupit",
  "šupou",
  "šustí",
  "šutru",
  "šutry",
  "šutrů",
  "šušká",
  "švech",
  "švihl",
  "švihu",
  "švihy",
  "švihá",
  "švihů",
  "švová",
  "švové",
  "švově",
  "švába",
  "švábe",
  "švábi",
  "švábu",
  "šváby",
  "švábů",
  "švéda",
  "švédi",
  "švédy",
  "švédů",
  "šáhův",
  "šálil",
  "šálku",
  "šálky",
  "šálků",
  "šálou",
  "šárce",
  "šárka",
  "šárko",
  "šárku",
  "šárky",
  "šátku",
  "šátky",
  "šátků",
  "šéfce",
  "šéfem",
  "šéfku",
  "šéfky",
  "šéfou",
  "šéfuj",
  "šéfík",
  "šéfům",
  "šéfův",
  "šídel",
  "šídla",
  "šídle",
  "šídlu",
  "šídly",
  "šíjou",
  "šíjím",
  "šílel",
  "šílet",
  "šílím",
  "šílíš",
  "šípem",
  "šípku",
  "šípky",
  "šípků",
  "šípům",
  "šířce",
  "šířek",
  "šířen",
  "šířil",
  "šířku",
  "šířky",
  "šířím",
  "šíříš",
  "šňupe",
  "šňůru",
  "šňůry",
  "šňůře",
  "šťáry",
  "šťávu",
  "šťávy",
  "šťávě",
  "šťáře",
  "ťafku",
  "ťapat",
  "ťatou",
  "ťatém",
  "ťatým",
  "ťoven",
  "ťovna",
  "ťuhýk",
  "ťukal",
  "ťukat",
  "ťukla",
  "ťukli",
  "ťuklo",
  "ťukly",
  "ťukne",
  "ťukni",
  "ťuknu",
  "ťukou",
  "ťukám",
  "ťukáš",
  "ťukém",
  "ťukým",
  "žabce",
  "žabek",
  "žabku",
  "žabky",
  "žabím",
  "žacím",
  "žalem",
  "žalmu",
  "žalmy",
  "žalmů",
  "žalná",
  "žalné",
  "žalob",
  "žaluj",
  "žasla",
  "žasli",
  "žaslo",
  "žasly",
  "žasne",
  "žasnu",
  "žatce",
  "žatci",
  "žatec",
  "žatou",
  "žatvy",
  "žatém",
  "žatým",
  "žačce",
  "žaček",
  "žačko",
  "žačku",
  "žačky",
  "ždímá",
  "žeber",
  "žebra",
  "žebru",
  "žebry",
  "žebrá",
  "žebré",
  "žebrý",
  "žehem",
  "žehli",
  "žehlí",
  "žehná",
  "žehné",
  "žehný",
  "žehuň",
  "želel",
  "želet",
  "želez",
  "želeč",
  "želiv",
  "želou",
  "želvu",
  "želvy",
  "želví",
  "želvě",
  "želím",
  "želíš",
  "žemli",
  "žemlí",
  "ženat",
  "ženeš",
  "ženil",
  "ženin",
  "ženou",
  "ženám",
  "ženém",
  "žením",
  "ženým",
  "žerdi",
  "žerdí",
  "žerdě",
  "žereš",
  "žerou",
  "žerte",
  "žertu",
  "žerty",
  "žertů",
  "žesťů",
  "žezel",
  "žezla",
  "žezlu",
  "žezly",
  "žeňme",
  "žeňte",
  "žhavá",
  "žhavé",
  "žhaví",
  "žhavě",
  "žhneš",
  "žhnou",
  "žhnul",
  "žháře",
  "žháři",
  "žhářů",
  "židem",
  "židli",
  "židlí",
  "židou",
  "židům",
  "žihle",
  "žiješ",
  "žijme",
  "žijou",
  "žijte",
  "žilek",
  "žilku",
  "žilky",
  "žilne",
  "žilná",
  "žilné",
  "žilně",
  "žiraf",
  "žitem",
  "žitná",
  "žitné",
  "žitou",
  "žitém",
  "žitím",
  "žitým",
  "žitům",
  "živce",
  "živci",
  "živen",
  "živil",
  "živin",
  "živlu",
  "živly",
  "živlů",
  "živná",
  "živné",
  "život",
  "živou",
  "živoř",
  "živém",
  "živím",
  "živým",
  "žižka",
  "žižky",
  "žižla",
  "žižlá",
  "žižlé",
  "žižlý",
  "žlabu",
  "žlaby",
  "žlabů",
  "žlebu",
  "žleby",
  "žlebů",
  "žlunu",
  "žluny",
  "žluně",
  "žluta",
  "žlutá",
  "žluté",
  "žlutí",
  "žlutě",
  "žluvu",
  "žluvy",
  "žluvě",
  "žluče",
  "žluči",
  "žlučí",
  "žláze",
  "žlázu",
  "žlázy",
  "žnutá",
  "žnuté",
  "žnutý",
  "žních",
  "žněmi",
  "žofie",
  "žofii",
  "žofií",
  "žofka",
  "žofín",
  "žokej",
  "žokem",
  "žokům",
  "žoldu",
  "žoldů",
  "žrací",
  "žrala",
  "žrali",
  "žralo",
  "žraly",
  "žraná",
  "žrané",
  "žravá",
  "žravé",
  "žraví",
  "žuchl",
  "žulou",
  "žumpu",
  "žumpy",
  "žumpě",
  "župní",
  "župou",
  "župám",
  "žvaní",
  "žvýká",
  "žábou",
  "žábro",
  "žábru",
  "žábám",
  "žábře",
  "žádal",
  "žádej",
  "žádni",
  "žádny",
  "žádná",
  "žádné",
  "žádní",
  "žádou",
  "žádám",
  "žádán",
  "žádáš",
  "žáhou",
  "žákem",
  "žákou",
  "žákyň",
  "žákům",
  "žákův",
  "žánru",
  "žánry",
  "žánrů",
  "žárem",
  "žárlí",
  "žárná",
  "žárné",
  "žáčci",
  "žáčka",
  "žáčky",
  "žáčků",
  "žíhal",
  "žíhám",
  "žíhán",
  "žíháš",
  "žílou",
  "žínce",
  "žínko",
  "žínku",
  "žínky",
  "žíním",
  "žírná",
  "žírné",
  "žívat",
  "žízni",
  "žízní",
  "žízně",
  "žížal",
  "žďáru",
  "žďáry",
  "žňová",
  "žňové",
  "žňový",
];
